import {
	createSelector,
	createSlice,
	type PayloadAction,
} from "@reduxjs/toolkit";
import type { OptionsType, OtherIncome } from "../../common/types";

import type {
	Asset,
	SharesEquitiesInvestmentsAsset,
	VehicleAsset,
	InvestmentPropertyAsset,
} from "../../components/forms/steps/your-finances/your-assets/YourAssetsSchema";
import type {
	CreditCardLiability,
	LoanLiability,
	HECsHELPLiability,
} from "../../components/forms/steps/your-finances/your-liabilities/YourLiabilitiesSchema";
import {
	type CreditCheckPreference,
	type ExitStrategy,
	type LoanFeature,
	type LoanOnProperty,
	LoanPurpose,
	type ReasonForRefinancing,
	type AddressParts,
	ResidencyStatus,
} from "../../services/apis/create-application.schema";
import type { AddressHistoryFormSlice } from "../../components/forms/steps/your-details/two-years-address-history/AddressHistorySchema";
import type { FullTimeEmployeeFormSlice } from "../../components/forms/steps/your-finances/your-employment/full-time-employee/FullTimeEmployee.Schema";
import type { RootState } from "..";

import {
	sumYearsMonths,
	sumYearsMonthsEmploymentHistory,
} from "../../components/utils/helpers";
import { getMaxPurchasePriceCapForState } from "../../lib/calculators";
import type { Place } from "../../components/forms/address-search/AddressSearch";
import type { SelfEmploymentType , ExistingMortgagedProperties } from "@sucasa-finance/origination-trpc";

interface AddressInputState {
	id: string;
	isManualAddressInput: boolean;
}

export type YesNoOrUndefined = "Yes" | "No" | undefined;

export type FormState = {
	isFormDirty: boolean;
	// Your home step
	loanPurpose: LoanPurpose;
	creditCheckPreference?: CreditCheckPreference;
	propertyAddress?: Place;
	propertyAddressParts?: AddressParts;
	propertyPurpose?: string;
	firstHomeBuyer?: string;
	isVacantLandOrHomeAndLand?: string;
	borrowerStage?: OptionsType;
	purchasePrice?: number;
	propertyValuation?: number;
	refinancingLoanForm?: string;
	didNotWantToPursueFHBScheme: YesNoOrUndefined;
	expectPRInThreeMonths?: string | null;
	// your Loan step

	loanPurchasePrice?: number;
	loanBorrowAmount?: number;
	loanSavings?: number;
	loanGiftedAmount?: number;
	loanGiftedBy?: string;
	loanPreference?: OptionsType;
	loanTerm: string;
	loanTermInYears?: 30 | 25 | 20;
	meetsNeedsAndObjectives?: string;
	requestedLoanBuyingFeatures: Array<LoanFeature>;
	requestedLoanRefinancingFeaturesOther?: string;
	requestedLoanBuyingFeaturesOther?: string; // Added requestedLoanBuyingFeaturesOther as an optional string
	// Current home
	currentLender: string;
	existingBorrowers?: "1" | "2";
	remainingTermInYears?: OptionsType;
	remainingTermInMonths?: OptionsType;
	loanType: "fixed" | "variable";
	loansOnProperty?: Array<LoanOnProperty>;
	// New Home
	additionalBorrowingAmount: number;
	additionalFundsReason: string;
	sameBorrowersAsExistingLoan: boolean;
	requestedLoanRefinancingFeatures: Array<ReasonForRefinancing>;
	// Your Details step - Tell us about you page
	detailsActiveForm: string;
	applicationId?: string;
	applicationType?: string;
	firstName: string;
	middleName: string;
	surname: string;
	title?: OptionsType;
	// Your Details step - You & Your Household page
	maritalStatus?: OptionsType;
	isRelationStatus?: string;
	residencyStatus?: {
		id: number;
		label: string;
		value: ResidencyStatus;
	};
	visaSubclass?: number | null;

	numberOfDependants: number;
	dependantAges: Array<number>;
	nonApplicantFinancialDetails?: string;
	// Your Details step - Create your account page
	email: string;
	mobile: string;
	password: string;
	confirmPassword: string;
	privacyConsent: boolean;
	marketingConsent: boolean;
	// Fine Details
	birthDate: string;
	exitStrategy?: Array<ExitStrategy>;
	exitStrategyOther?: string;
	exitStrategyDetails?: string;
	// Address History
	livingAddress?: Place;
	livingAddressParts?: AddressParts;
	currentLivingSituation: OptionsType;
	postSettlementPlan?: string;
	postSettlementEstimate?: number;
	postSettlementMonthlyCosts?: number;
	livingStartDate: string;
	livingEndDate?: string;

	addressHistory?: Array<AddressHistoryFormSlice>;
	previousAddressHistoryForm?: string;
	// Your Finances
	financesActiveForm: string;

	previousFinancesActiveForm: string;
	currentEmploymentType: OptionsType;
	currentEmployerName: string;
	currentJobTitle: string;
	currentRoleStartDate: string;
	previousEmployerName?: string;
	previousJobTitle?: string;
	previousRoleStartDate?: string;
	previousRoleEndDate?: string;
	probation?: string;
	industryMonths?: OptionsType;
	industryYears?: OptionsType;
	employmentHistory?: Array<FullTimeEmployeeFormSlice>;

	// employee income
	annualBaseSalaryAmount: number | undefined;
	overtimeOrShiftAllowance: string;
	allowanceAmount: number | undefined;
	allowanceForSixMonths: boolean;
	overtimeAmount: number | undefined;
	overtimeForSixMonths: boolean;
	commissionsOrBonuses: string;
	commissionsLastTwelveMonthsPercentage: number | undefined;
	commissionsPriorTwelveMonthsPercentage: number | undefined;
	bonusesLastTwelveMonthsPercentage: number | undefined;
	bonusesPriorTwelveMonthsPercentage: number | undefined;
	otherIncomeAllowed: string;
	otherIncomes?: Array<OtherIncome>;
	businessName: string;
	businessABN: number | undefined;
	businessIncorporationDate: string;
	businessStartDate: string;
	netProfitAfterTax: number | undefined;
	noNetProfitAfterTax?: boolean;
	jointBorrowerIncome?: number;
	selfEmploymentType?: OptionsType<SelfEmploymentType>;
	employmentGapExplanation?: string;
	// Household Expenses

	postSettlementRent?: number;
	internetTVPhoneExpenses?: number;
	propertyUtilitiesExpenses?: number;
	foodGroceriesExpenses?: number;
	medicalHealthExcludingInsuranceExpenses?: number;
	clothingPersonalCareExpenses?: number;
	recreationEntertainmentExpenses?: number;
	transportExpenses?: number;
	childcareExpenses?: number;
	privateSchoolFeesExpenses?: number;
	educationExpenses?: number;
	privateHealthInsuranceExpenses?: number;
	lifeOrAccidentExpenses?: number;
	generalInsuranceExpenses?: number;
	ailmonyMaintenanceExpenses?: number;
	counsellingServicesExpenses?: number;
	chronicHealthExpenses?: number;
	otherExpenses?: number;
	otherExpensesExplanation?: string;
	childcareExpensesExplanation?: string;
	educationExpensesExplanation?: string;
	// Assets
	cashOrSavingAssets?: Array<Asset>;
	superannuationAssets?: Array<Asset>;
	sharesEquitiesInvestmentsAssets?: Array<SharesEquitiesInvestmentsAsset>;
	carAssets?: Array<VehicleAsset>;
	boatAssets?: Array<VehicleAsset>;
	investmentPropertyAssets?: Array<InvestmentPropertyAsset>;
	otherAssets?: Array<Asset>;
	// Liabilities
	noLiabilities?: boolean;
	creditCardLiabilities?: Array<CreditCardLiability>;
	personalLoansLiabilities?: Array<LoanLiability>;
	vehicleLoansLiabilities?: Array<LoanLiability>;
	hECsHELPLiabilities?: Array<HECsHELPLiability>;
	// Circumstances
	hasOffshoreLiabilities: string;
	hasOffshoreLiabilitiesExplanation?: string;
	hasAdverseCreditHistory: string;
	hasAdverseCreditHistoryExplanation?: string;
	hasAnticipateChangesToCircumstances: string;
	hasAnticipateChangesToCircumstancesExplanation?: string;
	//your help
	idealPurchasePriceFrom?: number;
	idealPurchasePriceTo?: number;
	idealMonthlyRepaymentAmount?: number;
	idealDepositAmount?: number;
	idealSavingAmount?: number;
	termsAndConditionsAndPrivacyConsent: boolean;
	// New state for managing multiple address inputs
	addressInputs: Array<AddressInputState>;
	jointBorrowerFirstName?: string;
	jointBorrowerEmail?: string;
	jointBorrowerPhone?: string;
	rentalIncome?: number;
	existingMortgagedProperties?: ExistingMortgagedProperties;
	livingInProperty?: string;
	indicatedRentalIncomeForIP?: number;
	willLiveInExistingMortgagedProperty?: string;
};

const initialState: FormState = {
	isFormDirty: false,
	didNotWantToPursueFHBScheme: undefined,
	loanPurpose: LoanPurpose.Empty,
	creditCheckPreference: undefined,
	propertyAddress: undefined,
	propertyAddressParts: undefined,
	propertyPurpose: "",
	firstHomeBuyer: "",
	isVacantLandOrHomeAndLand: "",
	borrowerStage: {
		id: 0,
		label: "Select option",
		value: "select_option",
	},
	expectPRInThreeMonths: null,
	purchasePrice: undefined,
	propertyValuation: undefined,
	refinancingLoanForm: "current-home-loan",

	// your Loan step
	// loan from buying
	loanPurchasePrice: undefined,
	loanBorrowAmount: undefined,
	loanSavings: undefined,
	loanGiftedAmount: undefined,
	loanGiftedBy: undefined,
	loanPreference: {
		id: 0,
		value: "",
		label: "Select an option",
	},
	loanTerm: "",
	loanTermInYears: undefined,
	meetsNeedsAndObjectives: undefined,
	requestedLoanBuyingFeatures: [],
	requestedLoanBuyingFeaturesOther: "", // Initialized requestedLoanBuyingFeaturesOther
	// Loan from refinancing
	// Current Home
	currentLender: "",
	existingBorrowers: undefined,
	remainingTermInYears: undefined,
	remainingTermInMonths: undefined,
	loanType: "fixed",
	// New Home
	additionalBorrowingAmount: 0,
	additionalFundsReason: "",
	sameBorrowersAsExistingLoan: false,
	requestedLoanRefinancingFeatures: [],
	requestedLoanRefinancingFeaturesOther: "",
	// Your Details step - Tell us about you page
	detailsActiveForm: "tell-us-about-you",
	applicationId: "",
	applicationType: undefined,
	firstName: "",
	middleName: "",
	surname: "",
	title: undefined,

	// Your Details step - You & Your Household page
	maritalStatus: undefined,
	isRelationStatus: "",
	residencyStatus: undefined,
	visaSubclass: null,

	numberOfDependants: 0,
	dependantAges: [],
	// Your Details step - Create your account page
	email: "",
	mobile: "",
	password: "",
	confirmPassword: "",
	privacyConsent: false,
	marketingConsent: false,
	// Fine Details
	birthDate: "",
	exitStrategy: [],
	exitStrategyOther: "",
	exitStrategyDetails: "",
	// Address History
	livingAddress: undefined,
	livingAddressParts: undefined,
	previousAddressHistoryForm: "",
	currentLivingSituation: {
		id: 0,
		label: "Select option",
		value: "select_option",
	},
	postSettlementPlan: undefined,
	postSettlementEstimate: undefined,
	postSettlementMonthlyCosts: undefined,
	livingStartDate: "",
	livingEndDate: undefined,
	addressHistory: undefined,

	// Your Finances
	financesActiveForm: "Your Employment",
	previousFinancesActiveForm: "Your Employment",
	currentEmploymentType: {
		id: 0,
		value: "",
		label: "Select option",
	},
	currentEmployerName: "",
	currentJobTitle: "",
	currentRoleStartDate: "",
	employmentHistory: undefined,
	previousEmployerName: undefined,
	previousJobTitle: undefined,
	previousRoleStartDate: undefined,
	previousRoleEndDate: undefined,
	probation: undefined,
	industryMonths: {
		id: 0,
		value: "",
		label: "Months",
	},
	industryYears: {
		id: 0,
		value: "",
		label: "Years",
	},
	annualBaseSalaryAmount: undefined,
	overtimeOrShiftAllowance: "",
	allowanceAmount: undefined,
	allowanceForSixMonths: false,
	overtimeAmount: undefined,
	overtimeForSixMonths: false,
	commissionsOrBonuses: "",
	commissionsLastTwelveMonthsPercentage: undefined,
	commissionsPriorTwelveMonthsPercentage: undefined,
	bonusesLastTwelveMonthsPercentage: undefined,
	bonusesPriorTwelveMonthsPercentage: undefined,
	otherIncomeAllowed: "",
	otherIncomes: undefined,
	businessName: "",
	businessABN: undefined,
	businessIncorporationDate: "",
	businessStartDate: "",
	netProfitAfterTax: undefined,
	noNetProfitAfterTax: false,
	jointBorrowerIncome: undefined,
	selfEmploymentType: undefined,
	employmentGapExplanation: undefined,
	// Household Expense

	postSettlementRent: undefined,
	internetTVPhoneExpenses: undefined,
	propertyUtilitiesExpenses: undefined,
	foodGroceriesExpenses: undefined,
	medicalHealthExcludingInsuranceExpenses: undefined,
	clothingPersonalCareExpenses: undefined,
	recreationEntertainmentExpenses: undefined,
	transportExpenses: undefined,
	childcareExpenses: undefined,
	privateSchoolFeesExpenses: undefined,
	educationExpenses: undefined,
	privateHealthInsuranceExpenses: undefined,
	lifeOrAccidentExpenses: undefined,
	generalInsuranceExpenses: undefined,
	ailmonyMaintenanceExpenses: undefined,
	counsellingServicesExpenses: undefined,
	chronicHealthExpenses: undefined,
	otherExpenses: undefined,
	otherExpensesExplanation: undefined,
	childcareExpensesExplanation: undefined,
	educationExpensesExplanation: undefined,
	// Assets
	cashOrSavingAssets: undefined,
	superannuationAssets: undefined,
	sharesEquitiesInvestmentsAssets: undefined,
	carAssets: undefined,
	boatAssets: undefined,
	investmentPropertyAssets: undefined,
	otherAssets: undefined,
	//Liabilities
	noLiabilities: false,
	creditCardLiabilities: [],
	personalLoansLiabilities: [],
	vehicleLoansLiabilities: [],
	hECsHELPLiabilities: [],
	//Circumstances
	hasOffshoreLiabilities: "",
	hasOffshoreLiabilitiesExplanation: "",
	hasAdverseCreditHistory: "",
	hasAdverseCreditHistoryExplanation: "",
	hasAnticipateChangesToCircumstances: "",
	hasAnticipateChangesToCircumstancesExplanation: "",
	//your help
	idealPurchasePriceFrom: undefined,
	idealPurchasePriceTo: undefined,
	idealMonthlyRepaymentAmount: undefined,
	idealDepositAmount: undefined,
	idealSavingAmount: undefined,
	termsAndConditionsAndPrivacyConsent: false,
	// New state for managing multiple address inputs
	addressInputs: [],
	jointBorrowerFirstName: "",
	jointBorrowerEmail: undefined,
	jointBorrowerPhone: undefined,
	rentalIncome: undefined,
	existingMortgagedProperties: undefined,
	livingInProperty: undefined,
	indicatedRentalIncomeForIP: undefined,
	willLiveInExistingMortgagedProperty: undefined,
};

export const formSlice = createSlice({
	name: "form",
	initialState: initialState,
	reducers: {
		setJointBorrowerFirstName: (state, { payload }: PayloadAction<string>) => {
			state.jointBorrowerFirstName = payload;
		},
		setJointBorrowerEmail: (state, { payload }: PayloadAction<string>) => {
			state.jointBorrowerEmail = payload;
		},
		setJointBorrowerPhone: (state, { payload }: PayloadAction<string>) => {
			state.jointBorrowerPhone = payload;
		},
		setIsFormDirty: (state, { payload }: PayloadAction<boolean>) => {
			state.isFormDirty = payload;
		},
		setLoanPurpose: (state, { payload }: PayloadAction<LoanPurpose>) => {
			state.loanPurpose = payload;
		},
		setCreditCheckPreference: (
			state,
			{ payload }: PayloadAction<CreditCheckPreference>
		) => {
			state.creditCheckPreference = payload;
		},
		setPropertyAddress: (state, { payload }: PayloadAction<Place>) => {
			state.propertyAddress = payload;
		},
		setPropertyAddressParts: (
			state,
			{ payload }: PayloadAction<AddressParts>
		) => {
			state.propertyAddressParts = payload;
		},
		setPropertyPurpose: (state, { payload }: PayloadAction<string>) => {
			state.propertyPurpose = payload;
		},
		setFirstHomeBuyer: (state, { payload }: PayloadAction<string>) => {
			state.firstHomeBuyer = payload;
		},
		setIsVacantLandOrHomeAndLand: (
			state,
			{ payload }: PayloadAction<string>
		) => {
			state.isVacantLandOrHomeAndLand = payload;
		},
		setBorrowerStage: (state, { payload }: PayloadAction<OptionsType>) => {
			state.borrowerStage = payload;
		},
		setPurchasePrice: (state, { payload }: PayloadAction<number>) => {
			state.purchasePrice = payload;
		},
		setPropertyValuation: (state, { payload }: PayloadAction<number>) => {
			state.propertyValuation = payload;
		},
		setRefinancingLoanForm: (state, { payload }: PayloadAction<string>) => {
			state.refinancingLoanForm = payload;
		},
		setLoanPurchasePrice: (state, { payload }: PayloadAction<number>) => {
			state.loanPurchasePrice = payload;
		},
		setLoanBorrowAmount: (state, { payload }: PayloadAction<number>) => {
			state.loanBorrowAmount = payload;
		},
		setLoanSavings: (state, { payload }: PayloadAction<number>) => {
			state.loanSavings = payload;
		},
		setLoanGiftedAmount: (state, { payload }: PayloadAction<number>) => {
			state.loanGiftedAmount = payload;
		},
		setLoanGiftedBy: (state, { payload }: PayloadAction<string>) => {
			state.loanGiftedBy = payload;
		},
		setLoanPreference: (state, { payload }: PayloadAction<OptionsType>) => {
			state.loanPreference = payload;
		},
		setLoanTerm: (state, { payload }: PayloadAction<string>) => {
			state.loanTerm = payload;
		},
		setLoanTermInYears: (state, { payload }: PayloadAction<30 | 25 | 20>) => {
			state.loanTermInYears = payload;
		},
		setMeetsNeedsAndObjectives: (state, { payload }: PayloadAction<string>) => {
			state.meetsNeedsAndObjectives = payload;
		},
		setRequestedLoanBuyingFeatures: (
			state,
			{ payload }: PayloadAction<Array<LoanFeature>>
		) => {
			state.requestedLoanBuyingFeatures = payload;
		},
		setRequestedLoanBuyingFeaturesOther: (
			state,
			{ payload }: PayloadAction<string>
		) => {
			state.requestedLoanBuyingFeaturesOther = payload; // Added setter for requestedLoanBuyingFeaturesOther
		},
		setCurrentLender: (state, { payload }: PayloadAction<string>) => {
			state.currentLender = payload;
		},
		setExistingBorrowers: (state, { payload }: PayloadAction<"1" | "2">) => {
			state.existingBorrowers = payload;
		},
		setRemainingTermInYears: (
			state,
			{ payload }: PayloadAction<OptionsType>
		) => {
			state.remainingTermInYears = payload;
		},
		setRemainingTermInMonths: (
			state,
			{ payload }: PayloadAction<OptionsType>
		) => {
			state.remainingTermInMonths = payload;
		},
		setLoansOnProperty: (
			state,
			{ payload }: PayloadAction<Array<LoanOnProperty>>
		) => {
			state.loansOnProperty = payload;
		},

		setAdditionalBorrowingAmount: (
			state,
			{ payload }: PayloadAction<number>
		) => {
			state.additionalBorrowingAmount = payload;
		},
		setAdditionalFundsReason: (state, { payload }: PayloadAction<string>) => {
			state.additionalFundsReason = payload;
		},
		setSameBorrowersAsExistingLoan: (
			state,
			{ payload }: PayloadAction<boolean>
		) => {
			state.sameBorrowersAsExistingLoan = payload;
		},
		setRequestedLoanRefinancingFeatures: (
			state,
			{ payload }: PayloadAction<Array<ReasonForRefinancing>>
		) => {
			state.requestedLoanRefinancingFeatures = payload;
		},
		// Your Details step - Tell us about you page
		setDetailsActiveForm: (state, { payload }: PayloadAction<string>) => {
			state.detailsActiveForm = payload;
		},
		setApplicationId: (state, { payload }: PayloadAction<string>) => {
			state.applicationId = String(payload);
		},
		setApplicationType: (state, { payload }: PayloadAction<string>) => {
			state.applicationType = payload;
		},
		setFirstName: (state, { payload }: PayloadAction<string>) => {
			state.firstName = payload;
		},
		setMiddleName: (state, { payload }: PayloadAction<string>) => {
			state.middleName = payload;
		},
		setSurname: (state, { payload }: PayloadAction<string>) => {
			state.surname = payload;
		},
		setTitle: (state, { payload }: PayloadAction<OptionsType>) => {
			state.title = payload;
		},

		// Your Details step - You & Your Household page
		setMaritalStatus: (state, { payload }: PayloadAction<OptionsType>) => {
			state.maritalStatus = payload;
		},
		setisRelationStatus: (state, { payload }: PayloadAction<string>) => {
			state.isRelationStatus = payload;
		},
		setResidencyStatus: (
			state,
			{
				payload,
			}: PayloadAction<{ id: number; label: string; value: ResidencyStatus }>
		) => {
			state.residencyStatus = payload;
		},
		setVisaSubclass: (state, { payload }: PayloadAction<number | null>) => {
			state.visaSubclass = payload;
		},
		setNonApplicantFinancialDetails: (state, { payload }: PayloadAction<string>) => {
			state.nonApplicantFinancialDetails = payload;
		},

		setNumberOfDependants: (state, { payload }: PayloadAction<number>) => {
			state.numberOfDependants = payload;
		},
		setEmploymentGapExplanation: (state, { payload }: PayloadAction<string>) => {
			state.employmentGapExplanation = payload;
		},
		setRequestedLoanRefinancingFeaturesOther: (
			state,
			{ payload }: PayloadAction<string>
		) => {
			state.requestedLoanRefinancingFeaturesOther = payload;
		},
		setDependantAges: (state, { payload }: PayloadAction<Array<number>>) => {
			state.dependantAges = payload;
		},
		// Your Details step - Create your account page
		setEmail: (state, { payload }: PayloadAction<string>) => {
			state.email = payload;
		},
		setMobile: (state, { payload }: PayloadAction<string>) => {
			state.mobile = payload;
		},
		setPassword: (state, { payload }: PayloadAction<string>) => {
			state.password = payload;
		},
		setConfirmPassword: (state, { payload }: PayloadAction<string>) => {
			state.confirmPassword = payload;
		},
		setPrivacyConsent: (state, { payload }: PayloadAction<boolean>) => {
			state.privacyConsent = payload;
		},
		setMarketingConsent: (state, { payload }: PayloadAction<boolean>) => {
			state.marketingConsent = payload;
		},
		// Fine Details
		setBirthDate: (state, { payload }: PayloadAction<string>) => {
			state.birthDate = payload;
		},
		setExitStrategy: (
			state,
			{ payload }: PayloadAction<Array<ExitStrategy>>
		) => {
			state.exitStrategy = payload;
		},
		setExitStrategyOther: (state, { payload }: PayloadAction<string>) => {
			state.exitStrategyOther = payload;
		},
		setExitStrategyDetails: (state, { payload }: PayloadAction<string>) => {
			state.exitStrategyDetails = payload;
		},
		// Address History
		setLivingAddress: (state, { payload }: PayloadAction<Place>) => {
			state.livingAddress = payload;
		},
		setLivingAddressParts: (
			state,
			{ payload }: PayloadAction<AddressParts>
		) => {
			state.livingAddressParts = payload;
		},
		setCurrentLivingSituation: (
			state,
			{ payload }: PayloadAction<OptionsType>
		) => {
			state.currentLivingSituation = payload;
		},
		setLivingStartDate: (state, { payload }: PayloadAction<string>) => {
			state.livingStartDate = payload;
		},
		setLivingEndDate: (state, { payload }: PayloadAction<string>) => {
			state.livingEndDate = payload;
		},
		setAddressHistory: (
			state,
			{ payload }: PayloadAction<Array<AddressHistoryFormSlice>>
		) => {
			state.addressHistory = payload;
		},
		setTwoYearsAddressHistoryForm: (
			state,
			{ payload }: PayloadAction<string>
		) => {
			state.previousAddressHistoryForm = payload;
		},

		// Your Finances
		setFinancesActiveForm: (state, { payload }: PayloadAction<string>) => {
			state.financesActiveForm = payload;
		},
		setPreviousFinanceActiveForm: (
			state,
			{ payload }: PayloadAction<string>
		) => {
			state.previousFinancesActiveForm = payload;
		},
		setEmploymentHistory: (
			state,
			{ payload }: PayloadAction<Array<FullTimeEmployeeFormSlice>>
		) => {
			state.employmentHistory = payload;
		},

		setCurrentEmploymentType: (
			state,
			{ payload }: PayloadAction<OptionsType>
		) => {
			state.currentEmploymentType = payload;
		},
		setCurrentEmployerName: (state, { payload }: PayloadAction<string>) => {
			state.currentEmployerName = payload;
		},
		setCurrentJobTitle: (state, { payload }: PayloadAction<string>) => {
			state.currentJobTitle = payload;
		},
		setCurrentRoleStartDate: (state, { payload }: PayloadAction<string>) => {
			state.currentRoleStartDate = payload;
		},
		setPreviousEmployerName: (
			state,
			{ payload }: PayloadAction<string | undefined>
		) => {
			state.previousEmployerName = payload;
		},
		setPreviousJobTitle: (
			state,
			{ payload }: PayloadAction<string | undefined>
		) => {
			state.previousJobTitle = payload;
		},
		setPreviousRoleStartDate: (
			state,
			{ payload }: PayloadAction<string | undefined>
		) => {
			state.previousRoleStartDate = payload;
		},
		setPreviousRoleEndDate: (
			state,
			{ payload }: PayloadAction<string | undefined>
		) => {
			state.previousRoleEndDate = payload;
		},
		setProbation: (state, { payload }: PayloadAction<string>) => {
			state.probation = payload;
		},
		setIndustryMonths: (state, { payload }: PayloadAction<OptionsType>) => {
			state.industryMonths = payload;
		},
		setIndustryYears: (state, { payload }: PayloadAction<OptionsType>) => {
			state.industryYears = payload;
		},
		setAnnualBaseSalaryAmount: (state, { payload }: PayloadAction<number>) => {
			state.annualBaseSalaryAmount = payload;
		},
		setOvertimeOrShiftAllowance: (
			state,
			{ payload }: PayloadAction<string>
		) => {
			state.overtimeOrShiftAllowance = payload;
		},
		setAllowanceAmount: (state, { payload }: PayloadAction<number>) => {
			state.allowanceAmount = payload;
		},
		setAllowanceForSixMonths: (state, { payload }: PayloadAction<boolean>) => {
			state.allowanceForSixMonths = payload;
		},
		setOvertimeAmount: (state, { payload }: PayloadAction<number>) => {
			state.overtimeAmount = payload;
		},
		setOvertimeForSixMonths: (state, { payload }: PayloadAction<boolean>) => {
			state.overtimeForSixMonths = payload;
		},
		setCommissionsOrBonuses: (state, { payload }: PayloadAction<string>) => {
			state.commissionsOrBonuses = payload;
		},
		setCommissionsLastTwelveMonthsPercentage: (
			state,
			{ payload }: PayloadAction<number>
		) => {
			state.commissionsLastTwelveMonthsPercentage = payload;
		},
		setCommissionsPriorTwelveMonthsPercentage: (
			state,
			{ payload }: PayloadAction<number>
		) => {
			state.commissionsPriorTwelveMonthsPercentage = payload;
		},
		setBonusesLastTwelveMonthsPercentage: (
			state,
			{ payload }: PayloadAction<number>
		) => {
			state.bonusesLastTwelveMonthsPercentage = payload;
		},
		setBonusesPriorTwelveMonthsPercentage: (
			state,
			{ payload }: PayloadAction<number>
		) => {
			state.bonusesPriorTwelveMonthsPercentage = payload;
		},
		setOtherIncomeAllowed: (state, { payload }: PayloadAction<string>) => {
			state.otherIncomeAllowed = payload;
		},
		setOtherIncomes: (
			state,
			{ payload }: PayloadAction<Array<OtherIncome>>
		) => {
			state.otherIncomes = payload;
		},
		setBusinessName: (state, { payload }: PayloadAction<string>) => {
			state.businessName = payload;
		},
		setBusinessABN: (state, { payload }: PayloadAction<number | undefined>) => {
			state.businessABN = payload;
		},
		setSelfEmploymentType: (state, { payload }: PayloadAction<OptionsType<SelfEmploymentType>>) => {
			state.selfEmploymentType = payload;
		},
		setBusinessIncorporationDate: (
			state,
			{ payload }: PayloadAction<string>
		) => {
			state.businessIncorporationDate = payload;
		},
		setBusinessStartDate: (state, { payload }: PayloadAction<string>) => {
			state.businessStartDate = payload;
		},
		setNetProfitAfterTax: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.netProfitAfterTax = payload;
		},
		setNoNetProfitAfterTax: (state, { payload }: PayloadAction<boolean>) => {
			state.noNetProfitAfterTax = payload;
		},
		setJointBorrowerIncome: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.jointBorrowerIncome = payload;
		},
		//Household Expense

		setPostSettlementRent: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.postSettlementRent = payload;
		},
		setInternetTVPhoneExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.internetTVPhoneExpenses = payload;
		},
		setPropertyUtilitiesExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.propertyUtilitiesExpenses = payload;
		},
		setFoodGroceriesExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.foodGroceriesExpenses = payload;
		},
		setMedicalHealthExcludingInsuranceExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.medicalHealthExcludingInsuranceExpenses = payload;
		},
		setClothingPersonalCareExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.clothingPersonalCareExpenses = payload;
		},
		setRecreationEntertainmentExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.recreationEntertainmentExpenses = payload;
		},
		setTransportExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.transportExpenses = payload;
		},
		setChildcareExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.childcareExpenses = payload;
		},
		setPrivateSchoolFeesExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.privateSchoolFeesExpenses = payload;
		},
		setEducationExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.educationExpenses = payload;
		},
		setPrivateHealthInsuranceExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.privateHealthInsuranceExpenses = payload;
		},
		setlifeOrAccidentExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.lifeOrAccidentExpenses = payload;
		},
		setGeneralInsuranceExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.generalInsuranceExpenses = payload;
		},
		setAilmonyMaintenanceExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.ailmonyMaintenanceExpenses = payload;
		},
		setCounsellingServicesExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.counsellingServicesExpenses = payload;
		},
		setChronicHealthExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.chronicHealthExpenses = payload;
		},
		setOtherExpenses: (
			state,
			{ payload }: PayloadAction<number | undefined>
		) => {
			state.otherExpenses = payload;
		},
		setOtherExpensesExplanation: (
			state,
			{ payload }: PayloadAction<string | undefined>
		) => {
			state.otherExpensesExplanation = payload;
		},
		// Assets
		setCashOrSavingAssets: (
			state,
			{ payload }: PayloadAction<Array<Asset>>
		) => {
			state.cashOrSavingAssets = payload;
		},
		setSuperannuationAssets: (
			state,
			{ payload }: PayloadAction<Array<Asset>>
		) => {
			state.superannuationAssets = payload;
		},
		setSharesEquitiesInvestmentsAssets: (
			state,
			{ payload }: PayloadAction<Array<SharesEquitiesInvestmentsAsset>>
		) => {
			state.sharesEquitiesInvestmentsAssets = payload;
		},
		setCarAssets: (state, { payload }: PayloadAction<Array<VehicleAsset>>) => {
			state.carAssets = payload;
		},
		setBoatAssets: (state, { payload }: PayloadAction<Array<VehicleAsset>>) => {
			state.boatAssets = payload;
		},
		setInvestmentPropertyAssets: (
			state,
			{ payload }: PayloadAction<Array<InvestmentPropertyAsset>>
		) => {
			state.investmentPropertyAssets = payload;
		},
		setOtherAssets: (state, { payload }: PayloadAction<Array<Asset>>) => {
			state.otherAssets = payload;
		},
		//Liabilities
		setNoLiabilities: (state, { payload }: PayloadAction<boolean>) => {
			state.noLiabilities = payload;
		},
		setCreditCardLiabilities: (
			state,
			{ payload }: PayloadAction<Array<CreditCardLiability>>
		) => {
			state.creditCardLiabilities = payload;
		},
		setPersonalLoansLiabilities: (
			state,
			{ payload }: PayloadAction<Array<LoanLiability>>
		) => {
			state.personalLoansLiabilities = payload;
		},
		setVehicleLoansLiabilities: (
			state,
			{ payload }: PayloadAction<Array<LoanLiability>>
		) => {
			state.vehicleLoansLiabilities = payload;
		},
		setHECsHELPLiabilities: (
			state,
			{ payload }: PayloadAction<Array<HECsHELPLiability>>
		) => {
			state.hECsHELPLiabilities = payload;
		},
		// Circumstances
		setHasOffshoreLiabilities: (state, { payload }: PayloadAction<string>) => {
			state.hasOffshoreLiabilities = payload;
		},
		setHasOffshoreLiabilitiesExplanation: (
			state,
			{ payload }: PayloadAction<string | undefined>
		) => {
			state.hasOffshoreLiabilitiesExplanation = payload;
		},
		setHasAdverseCreditHistory: (state, { payload }: PayloadAction<string>) => {
			state.hasAdverseCreditHistory = payload;
		},
		setHasAdverseCreditHistoryExplanation: (
			state,
			{ payload }: PayloadAction<string | undefined>
		) => {
			state.hasAdverseCreditHistoryExplanation = payload;
		},
		setHasAnticipateChangesToCircumstances: (
			state,
			{ payload }: PayloadAction<string>
		) => {
			state.hasAnticipateChangesToCircumstances = payload;
		},
		setHasAnticipateChangesToCircumstancesExplanation: (
			state,
			{ payload }: PayloadAction<string | undefined>
		) => {
			state.hasAnticipateChangesToCircumstancesExplanation = payload;
		},
		// Your Help
		setIdealPurchasePriceFrom: (state, { payload }: PayloadAction<number>) => {
			state.idealPurchasePriceFrom = payload;
		},
		setIdealPurchasePriceTo: (state, { payload }: PayloadAction<number>) => {
			state.idealPurchasePriceTo = payload;
		},
		setIdealMonthlyRepaymentAmount: (
			state,
			{ payload }: PayloadAction<number>
		) => {
			state.idealMonthlyRepaymentAmount = payload;
		},
		setIdealDepositAmount: (state, { payload }: PayloadAction<number>) => {
			state.idealDepositAmount = payload;
		},
		setIdealSavingAmount: (state, { payload }: PayloadAction<number>) => {
			state.idealSavingAmount = payload;
		},
		setTermsAndConditionsAndPrivacyConsent: (
			state,
			{ payload }: PayloadAction<boolean>
		) => {
			state.termsAndConditionsAndPrivacyConsent = payload;
		},

		resetForm: () => {
			return initialState;
		},
		clearPropertyAddress: (state) => {
			state.propertyAddress = undefined;
		},
		clearPropertyAddressParts: (state) => {
			state.propertyAddressParts = undefined;
		},
		addAddressInput: (state, action: PayloadAction<AddressInputState>) => {
			state.addressInputs.push(action.payload);
		},
		setIsManualAddressInput: (
			state,
			action: PayloadAction<{ id: string; isManual: boolean }>
		) => {
			const index = state.addressInputs.findIndex(
				(input) => input.id === action.payload.id
			);
			if (index !== -1) {
				const value = action.payload.isManual ?? false;
				//@ts-expect-error - TS doesn't know that index is not -1
				state.addressInputs[index].isManualAddressInput = value;
			}
		},
		removeAddressInput: (state, action: PayloadAction<string>) => {
			state.addressInputs = state.addressInputs.filter(
				(input) => input.id !== action.payload
			);
		},
		resetFormState: () => initialState,
		setDidNotWantToPursueFHBScheme: (
			state,
			{ payload }: PayloadAction<YesNoOrUndefined>
		) => {
			state.didNotWantToPursueFHBScheme = payload;
		},
		setExpectPRInThreeMonths: (state, { payload }: PayloadAction<string | null>) => {
			state.expectPRInThreeMonths = payload;
		},
		setRentalIncome: (state, action: PayloadAction<number>) => {
			state.rentalIncome = action.payload;
		},
		setIndicatedRentalIncomeForIP: (state, action: PayloadAction<number>) => {
			state.indicatedRentalIncomeForIP = action.payload;
		},
		setExistingMortgagedProperties: (state, action: PayloadAction<ExistingMortgagedProperties>) => {
			state.existingMortgagedProperties = action.payload;
		},
		setWillLiveInExistingMortgagedProperty: (state, action: PayloadAction<string>) => {
			state.willLiveInExistingMortgagedProperty = action.payload;
		},
	},
});

export const {
	resetFormState,

	setTermsAndConditionsAndPrivacyConsent,
	setLoanPurpose,
	setCreditCheckPreference,
	setPropertyAddress,
	setPropertyAddressParts,
	clearPropertyAddress,
	clearPropertyAddressParts,
	setPropertyPurpose,
	setFirstHomeBuyer,
	setIsVacantLandOrHomeAndLand,
	setBorrowerStage,
	setPurchasePrice,
	setRefinancingLoanForm,
	setLoanPurchasePrice,
	setLoanBorrowAmount,
	setLoanSavings,
	setLoanGiftedAmount,
	setLoanGiftedBy,
	setLoanPreference,
	setLoanTerm,
	setLoanTermInYears,
	setMeetsNeedsAndObjectives,
	setRequestedLoanBuyingFeatures,
	setRequestedLoanBuyingFeaturesOther,
	setCurrentLender,
	setExistingBorrowers,
	setRemainingTermInYears,
	setRemainingTermInMonths,
	setLoansOnProperty,

	setAdditionalBorrowingAmount,
	setAdditionalFundsReason,
	setSameBorrowersAsExistingLoan,
	setRequestedLoanRefinancingFeatures,
	setDetailsActiveForm,
	setApplicationId,
	setApplicationType,
	setFirstName,
	setMiddleName,
	setSurname,
	setTitle,

	setMaritalStatus,
	setisRelationStatus,
	setResidencyStatus,
	setVisaSubclass,
	setNonApplicantFinancialDetails,
	setNumberOfDependants,
	setDependantAges,
	setEmail,
	setMobile,
	setPassword,
	setConfirmPassword,
	setPrivacyConsent,
	setMarketingConsent,
	setBirthDate,
	setExitStrategy,
	setExitStrategyOther,
	setExitStrategyDetails,
	// Address History
	setLivingAddress,
	setCurrentLivingSituation,
	setLivingStartDate,
	setLivingEndDate,
	setAddressHistory,
	setTwoYearsAddressHistoryForm,

	// Finances
	setFinancesActiveForm,
	setPreviousFinanceActiveForm,
	setCurrentEmploymentType,
	setCurrentEmployerName,
	setCurrentJobTitle,
	setCurrentRoleStartDate,
	setIndustryMonths,
	setIndustryYears,
	setPreviousEmployerName,
	setPreviousJobTitle,
	setPreviousRoleEndDate,
	setPreviousRoleStartDate,
	setProbation,
	setAnnualBaseSalaryAmount,
	setOvertimeOrShiftAllowance,
	setAllowanceAmount,
	setAllowanceForSixMonths,
	setOvertimeAmount,
	setOvertimeForSixMonths,
	setCommissionsOrBonuses,
	setCommissionsLastTwelveMonthsPercentage,
	setCommissionsPriorTwelveMonthsPercentage,
	setBonusesLastTwelveMonthsPercentage,
	setBonusesPriorTwelveMonthsPercentage,
	setOtherIncomeAllowed,
	setOtherIncomes,
	setBusinessName,
	setBusinessABN,
	setBusinessIncorporationDate,
	setBusinessStartDate,
	setNetProfitAfterTax,
	setNoNetProfitAfterTax,
	setJointBorrowerIncome,

	setPostSettlementRent,
	setInternetTVPhoneExpenses,
	setPropertyUtilitiesExpenses,
	setFoodGroceriesExpenses,
	setMedicalHealthExcludingInsuranceExpenses,
	setClothingPersonalCareExpenses,
	setRecreationEntertainmentExpenses,
	setTransportExpenses,
	setChildcareExpenses,
	setPrivateSchoolFeesExpenses,
	setEducationExpenses,
	setPrivateHealthInsuranceExpenses,
	setlifeOrAccidentExpenses,
	setGeneralInsuranceExpenses,
	setAilmonyMaintenanceExpenses,
	setCounsellingServicesExpenses,
	setChronicHealthExpenses,
	setOtherExpenses,
	setCashOrSavingAssets,
	setInvestmentPropertyAssets,
	setOtherAssets,
	setSharesEquitiesInvestmentsAssets,
	setSuperannuationAssets,
	setCarAssets,
	setBoatAssets,
	setCreditCardLiabilities,
	setHECsHELPLiabilities,
	setNoLiabilities,
	setPersonalLoansLiabilities,
	setVehicleLoansLiabilities,
	// Circumstance
	setHasAdverseCreditHistory,
	setHasAdverseCreditHistoryExplanation,
	setHasAnticipateChangesToCircumstances,
	setHasAnticipateChangesToCircumstancesExplanation,
	setHasOffshoreLiabilities,
	setHasOffshoreLiabilitiesExplanation,
	//Your help
	setIdealDepositAmount,
	setIdealMonthlyRepaymentAmount,
	setIdealPurchasePriceFrom,
	setIdealPurchasePriceTo,
	setIdealSavingAmount,
	setRequestedLoanRefinancingFeaturesOther,
	setPropertyValuation,
	setLivingAddressParts,
	resetForm,
	addAddressInput,
	setIsManualAddressInput,
	removeAddressInput,
	setIsFormDirty,
	setEmploymentHistory,
	setJointBorrowerFirstName,
	setJointBorrowerEmail,
	setJointBorrowerPhone,
	setDidNotWantToPursueFHBScheme,
	setSelfEmploymentType,
	setEmploymentGapExplanation,
	setExpectPRInThreeMonths,
	setRentalIncome,
	setIndicatedRentalIncomeForIP,
	setExistingMortgagedProperties,
	setWillLiveInExistingMortgagedProperty
} = formSlice.actions;

export const selectTotalEmploymentHistory = createSelector(
	(state: RootState) => state.form.employmentHistory,
	(employmentHistory: Array<FullTimeEmployeeFormSlice> | undefined) => {
		return employmentHistory && employmentHistory.length > 0
			? sumYearsMonthsEmploymentHistory(employmentHistory)
			: 0;
	}
);

export const selectCurrentEmploymentType = createSelector(
	(state: RootState) => state.form.currentEmploymentType,
	(currentEmploymentType) => currentEmploymentType
);

export const selectTotalAddressHistory = createSelector(
	(state: RootState) => state.form.addressHistory,
	(addressHistory: Array<AddressHistoryFormSlice> | undefined) => {
		return addressHistory && addressHistory.length > 0
			? sumYearsMonths(addressHistory)
			: 0;
	}
);

export const selectCouldBeEligibleForFHB = createSelector(
	(state: RootState) => state.form.firstHomeBuyer,
	(state: RootState, overrideResidencyStatus?: ResidencyStatus) =>
		overrideResidencyStatus || state.form.residencyStatus?.value,

	(state: RootState) => state.form.loanPurchasePrice,
	(state: RootState) => state.form.propertyAddressParts?.state,
	(state: RootState) => state.form.loanPurpose,
	(
		firstHomeBuyer,
		residencyStatus,
		purchasePrice,
		propertyState,
		loanPurpose
	) => {
		if (loanPurpose === LoanPurpose.Refinance) {
			return false;
		}
		if (!propertyState) {
			return false;
		}

		if (firstHomeBuyer !== "yes") {
			return false;
		}

		if (!residencyStatus) {
			return false;
		}

		console.log("residency status", residencyStatus);

		if (
			![ResidencyStatus.AU, ResidencyStatus.PermanentResident].includes(
				residencyStatus
			)
		) {
			return false;
		}

		const maxPriceCap = getMaxPurchasePriceCapForState(propertyState);

		return purchasePrice && maxPriceCap && purchasePrice <= maxPriceCap;
	}
);

export const selectDependantLimits = createSelector(
	(state: RootState) => state.form.dependantAges,
	(dependantAges) => {
		return {
			isBelowSix: dependantAges.length > 0 && Math.min(...dependantAges) < 6,
			isBetweenSixAndEighteen: dependantAges.some(itm => itm > 6 && itm < 18),
		};
	}
);

export const selectIsRefinance = createSelector(
	(state: RootState) => state.form.loanPurpose,
	(loanPurpose) => {
		return loanPurpose === LoanPurpose.Refinance;
	}
);

export default formSlice.reducer;
