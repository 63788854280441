/* eslint-disable camelcase */

import { z } from "zod";
import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../../../../../store";

export const HouseHoldExpensesSchema = z.object({
	postSettlementRent: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	internetTVPhoneExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	propertyUtilitiesExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	foodGroceriesExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	medicalHealthExcludingInsuranceExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	clothingPersonalCareExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	recreationEntertainmentExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	transportExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	childcareExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	privateSchoolFeesExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	educationExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	privateHealthInsuranceExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	lifeOrAccidentExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	generalInsuranceExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	ailmonyMaintenanceExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	counsellingServicesExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	chronicHealthExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	otherExpenses: z
		.number({
			invalid_type_error: "This field is required, please enter 0 if none",
			required_error: "This field is required, please enter 0 if none",
		})
		.min(0, "This field is required, please enter 0 if none"),
	lowExpensesExplanation: z.string().optional(),
	otherExpensesExplanation: z.string().optional(),
	childcareExpensesExplanation: z.string().optional(),
	educationExpensesExplanation: z.string().optional(),
});

export type HouseHoldExpensesFormType = z.infer<typeof HouseHoldExpensesSchema>;


export type ExpensesFormSlice = {
	postSettlementRent?: number;
	internetTVPhoneExpenses?: number;
	propertyUtilitiesExpenses?: number;
	foodGroceriesExpenses?: number;
	medicalHealthExcludingInsuranceExpenses?: number;
	clothingPersonalCareExpenses?: number;
	recreationEntertainmentExpenses?: number;
	transportExpenses?: number;
	childcareExpenses?: number;
	privateSchoolFeesExpenses?: number;
	educationExpenses?: number;
	privateHealthInsuranceExpenses?: number;
	lifeOrAccidentExpenses?: number;
	generalInsuranceExpenses?: number;
	ailmonyMaintenanceExpenses?: number;
	counsellingServicesExpenses?: number;
	chronicHealthExpenses?: number;
	otherExpenses?: number;
	previousFinancesActiveForm?: string;
	lowExpensesExplanation?: string;
	otherExpensesExplanation?: string;
	childcareExpensesExplanation?: string;
	educationExpensesExplanation?: string;
};

export const selectHouseHoldExpenses = createSelector(
	(state: RootState) => state.form,
	(form): ExpensesFormSlice => ({
		postSettlementRent: form.postSettlementRent,
		internetTVPhoneExpenses: form.internetTVPhoneExpenses,
		propertyUtilitiesExpenses: form.propertyUtilitiesExpenses,
		foodGroceriesExpenses: form.foodGroceriesExpenses,
		medicalHealthExcludingInsuranceExpenses:
			form.medicalHealthExcludingInsuranceExpenses,
		clothingPersonalCareExpenses: form.clothingPersonalCareExpenses,
		recreationEntertainmentExpenses: form.recreationEntertainmentExpenses,
		transportExpenses: form.transportExpenses,
		childcareExpenses: form.childcareExpenses,
		privateSchoolFeesExpenses: form.privateSchoolFeesExpenses,
		educationExpenses: form.educationExpenses,
		privateHealthInsuranceExpenses: form.privateHealthInsuranceExpenses,
		lifeOrAccidentExpenses: form.lifeOrAccidentExpenses,
		generalInsuranceExpenses: form.generalInsuranceExpenses,
		ailmonyMaintenanceExpenses: form.ailmonyMaintenanceExpenses,
		counsellingServicesExpenses: form.counsellingServicesExpenses,
		chronicHealthExpenses: form.chronicHealthExpenses,
		otherExpenses: form.otherExpenses,
		otherExpensesExplanation: form.otherExpensesExplanation,
		childcareExpensesExplanation: form.childcareExpensesExplanation,
		educationExpensesExplanation: form.educationExpensesExplanation,
		previousFinancesActiveForm: form.previousFinancesActiveForm
	})
);
