/* eslint-disable react-refresh/only-export-components */
import { LivingSituation, postSettlementPlanName, PostSettlementPlan } from "@sucasa-finance/origination-trpc";
import type { ChangeHandler } from "react-hook-form";
import type { OptionsType } from "../../common/types";
import type { RadioInputTypeWithRegister } from "../forms/radio-input/RadioInput";
import {
	CreditCheckPreference, LoanOnPropertyType,
	LoanPurpose,
	ResidencyStatus,
} from "../../services/apis/create-application.schema";
import type { RadioInputSecondaryType } from "../forms/radio-input-secondary/RadioInputSecondary";
import {
	FrequencyType,
	IncomeType,
} from "../../services/apis/update-income.schema";

const defaultRegister = <T extends string>(): {
	onChange: ChangeHandler;
	onBlur: ChangeHandler;
	name: T;
	ref: (instance: HTMLInputElement | null) => void;
} => ({
	onChange: () => Promise.resolve(true),
	onBlur: () => Promise.resolve(true),
	name: "" as T,
	ref: () => {},
});

export const creditCheckOptions: Array<RadioInputTypeWithRegister> = [
	{
		label: "At submission",
		name: "creditCheckPreference",
		value: CreditCheckPreference.AtSubmission,
		checked: false,
		register: defaultRegister,
	},
	{
		label: "Call me first",
		name: "creditCheckPreference",
		value: CreditCheckPreference.CallMeFirst,
		checked: false,
		register: defaultRegister,
	},
];

export const buyingOptions: Array<RadioInputTypeWithRegister> = [
	{
		label: "I’m buying",
		name: "loanPurpose",
		value: LoanPurpose.Purchase,
		checked: false,
		register: defaultRegister,
	},
	{
		label: "I’m refinancing",
		name: "loanPurpose",
		value: LoanPurpose.Refinance,
		checked: false,
		register: defaultRegister,
	},
];

export const liveInOptions: Array<RadioInputTypeWithRegister> = [
	{
		label: "To live in",
		name: "propertyPurpose",
		value: "Live In",
		checked: false,
		register: defaultRegister,
	},
	{
		label: "Investment",
		name: "propertyPurpose",
		value: "Investment",
		checked: false,
		register: defaultRegister,
	},
];

export const loanTermOptions: Array<RadioInputTypeWithRegister> = [
	{
		label: "Yes",
		name: "loanTerm",
		value: "yes",
		checked: false,
		register: defaultRegister,
	},
	{
		label: "No",
		name: "loanTerm",
		value: "no",
		checked: false,
		register: defaultRegister,
	},
];

export const loanTermInYearsOptions: Array<RadioInputTypeWithRegister> = [
	{
		label: "20 years",
		name: "loanTermInYears",
		value: 20,
		checked: false,
		register: defaultRegister,
	},
	{
		label: "25 years",
		name: "loanTermInYears",
		value: 25,
		checked: false,
		register: defaultRegister,
	},
];

export const loanMeetsNeedsAndObjectivesOptions: Array<RadioInputTypeWithRegister> =
	[
		{
			label: "Yes",
			name: "meetsNeedsAndObjectives",
			value: "true",
			checked: false,
			register: defaultRegister,
		},
		{
			label: "No",
			name: "meetsNeedsAndObjectives",
			value: "false",
			checked: false,
			register: defaultRegister,
		},
	];

export const existingBorrowersOptions: Array<RadioInputTypeWithRegister> = [
	{
		label: "Just me",
		name: "existingBorrowers",
		value: "1",
		checked: false,
		register: defaultRegister,
	},
	{
		label: "Me, plus one",
		name: "existingBorrowers",
		value: "2",
		checked: false,
		register: defaultRegister,
	},
];

export const sameBorrowersAsExistingLoanOptions: Array<RadioInputTypeWithRegister> =
	[
		{
			label: "Yes",
			name: "sameBorrowersAsExistingLoan",
			value: "true",
			checked: false,
			register: defaultRegister,
		},
		{
			label: "No",
			name: "sameBorrowersAsExistingLoan",
			value: "false",
			checked: false,
			register: defaultRegister,
		},
	];

export const LoanTypeOptions: Array<RadioInputTypeWithRegister> = [
	{
		label: "Fixed",
		name: "loanType",
		value: LoanOnPropertyType.Fixed,
		checked: false,
		register: defaultRegister,
	},
	{
		label: "Variable",
		name: "loanType",
		value: LoanOnPropertyType.Variable,
		checked: false,
		register: defaultRegister,
	},
];
export const LoanTypeSecondaryOptions: Array<RadioInputSecondaryType> = [
	{
		label: "Fixed",
		value: LoanOnPropertyType.Fixed,
		checked: false,
	},
	{
		label: "Variable",
		value: LoanOnPropertyType.Variable,
		checked: false,
	},
];
export const firstBuyerOptions: Array<RadioInputTypeWithRegister> = [
	{
		label: "Yes",
		name: "firstHomeBuyer",
		value: "yes",
		checked: false,
		register: defaultRegister,
	},
	{
		label: "No",
		name: "firstHomeBuyer",
		value: "no",
		checked: false,
		register: defaultRegister,
	},
];

export const vacantLandOptions: Array<RadioInputTypeWithRegister> = [
	{
		label: "Yes",
		name: "isVacantLandOrHomeAndLand",
		value: "yes",
		checked: false,
		register: defaultRegister,
	},
	{
		label: "No",
		name: "isVacantLandOrHomeAndLand",
		value: "no",
		checked: false,
		register: defaultRegister,
	},
];

export const applicationTypeOptions: Array<RadioInputTypeWithRegister> = [
	{
		label: "Just me",
		name: "applicationType",
		value: "Single",
		checked: false,
		register: defaultRegister,
	},
	{
		label: "Me, plus one",
		name: "applicationType",
		value: "Joint",
		checked: false,
		register: defaultRegister,
	},
];

export const isRelationStatusToSecondaryBorrower: Array<RadioInputTypeWithRegister> =
	[
		{
			label: "Yes",
			name: "isRelationStatus",
			value: "true",
			checked: false,
			register: defaultRegister,
		},
		{
			label: "No",
			name: "isRelationStatus",
			value: "false",
			checked: false,
			register: defaultRegister,
		},
	];

export const selectOptions: Array<OptionsType> = [
	{
		id: 2,
		label: "3 Months +",
		value: "3 Months +",
	},
	{
		id: 1,
		label: "Under 3 Months",
		value: "Under 3 Months",
	},
	{
		id: 3,
		label: "Any day now, I have a property in mind",
		value: "Any day now, I have a property in mind",
	},
	{
		id: 4,
		label: `I have a signed purchase contract`,
		value: `I have a signed purchase contract`,
	},
];

export const ReasonOptions: Array<OptionsType> = [
	{
		id: 1,
		label: "Own my own home with a mortgage",
		value: LivingSituation.OwnWithMortgage,
	},
	{
		id: 2,
		label: "Own my own home outright",
		value: LivingSituation.OwnOutright,
	},
	{
		id: 3,
		label: "Renting",
		value: LivingSituation.Renting,
	},
	{
		id: 4,
		label: "Boarding / living with family or friends",
		value: LivingSituation.Boarding,
	},
];

export const PostSettlementPlanOptions: Array<OptionsType> = Object.values(PostSettlementPlan)
	.map((itm, index) => ({
		id: index+1,
		label: postSettlementPlanName[itm],
		value: itm,
	}));

export const ProbationOptions: Array<RadioInputTypeWithRegister> = [
	{
		label: "Yes",
		name: "probation",
		value: "true",
		checked: false,
		register: defaultRegister,
	},
	{
		label: "No",
		name: "probation",
		value: "false",
		checked: false,
		register: defaultRegister,
	},
];

export const YearsOptions: Array<OptionsType> = Array.from(
	{ length: 31 },
	(_, index) => ({
		id: index + 1,
		label: `${index} Year${index === 1 ? "" : "s"}`,
		value: `${index}`,
	})
);

export const MonthsOptions: Array<OptionsType> = Array.from(
	{ length: 12 },
	(_, index) => ({
		id: index + 1,
		label: `${index} Month${index === 1 ? "" : "s"}`,
		value: `${index}`,
	})
);

export const OvertimeOrShiftAllowanceOptions: Array<RadioInputTypeWithRegister> =
	[
		{
			label: "Yes",
			name: "overtimeOrShiftAllowance",
			value: "true",
			checked: false,
			register: defaultRegister,
		},
		{
			label: "No",
			name: "overtimeOrShiftAllowance",
			value: "false",
			checked: false,
			register: defaultRegister,
		},
	];

export const OtherIncomeAllowedOptions: Array<RadioInputTypeWithRegister> = [
	{
		label: "Yes",
		name: "otherIncomeAllowed",
		value: "true",
		checked: false,
		register: defaultRegister,
	},
	{
		label: "No",
		name: "otherIncomeAllowed",
		value: "false",
		checked: false,
		register: defaultRegister,
	},
];

export const IncomeTypeOptions: Array<OptionsType> = [
	{
		id: 1,
		label: "Superannuation / Annuities",
		value: IncomeType.SuperannuationAnnuities,
	},
	// {
	// 	id: 2,
	// 	label: "Rental income (residential)",
	// 	value: IncomeType.RentalIncomeResidential,
	// },
	// {
	// 	id: 3,
	// 	label: "Overtime / Shift allowance",
	// 	value: IncomeType.Overtime,
	// },
	{
		id: 4,
		label: "Investment income",
		value: IncomeType.InvestmentIncome,
	},
	{
		id: 5,
		label: "Interest income",
		value: IncomeType.InterestIncome,
	},
	{
		id: 6,
		label: "Government pension",
		value: IncomeType.GovernmentPension,
	},
	{
		id: 7,
		label: "Contract Income (fixed)",
		value: IncomeType.ContractIncomeFixed,
	},
	{
		id: 8,
		label: "Contract (variable)",
		value: IncomeType.ContractIncomeVariable,
	},
	// {
	// 	id: 9,
	// 	label: "Commission",
	// 	value: IncomeType.Commission,
	// },
	{
		id: 10,
		label: "Child Support",
		value: IncomeType.ChildSupport,
	},
	{
		id: 11,
		label: "Casual income",
		value: IncomeType.CasualIncome,
	},
	// {
	// 	id: 13,
	// 	label: "Bonus",
	// 	value: IncomeType.Bonus,
	// },
	{
		id: 14,
		label: "Maternity Leave",
		value: IncomeType.MaternityLeave,
	},
	{
		id: 15,
		label: "Other Income (Rental, Benefits etc)",
		value: IncomeType.OtherIncome,
	},
];

export const FrequencyTypeOptions: Array<OptionsType> = [
	{
		id: 1,
		label: "Annually",
		value: FrequencyType.Annually,
	},
	{
		id: 2,
		label: "Monthly",
		value: FrequencyType.Monthly,
	},
];

export const CommissionsOrBonusesOptions: Array<RadioInputTypeWithRegister> = [
	{
		label: "Yes",
		name: "commissionsOrBonuses",
		value: "true",
		checked: false,
		register: defaultRegister,
	},
	{
		label: "No",
		name: "commissionsOrBonuses",
		value: "false",
		checked: false,
		register: defaultRegister,
	},
];

export const HasOffshoreLiabilitiesOptions: Array<RadioInputTypeWithRegister> =
	[
		{
			label: "Yes",
			name: "hasOffshoreLiabilities",
			value: "true",
			checked: false,
			register: defaultRegister,
		},
		{
			label: "No",
			name: "hasOffshoreLiabilities",
			value: "false",
			checked: false,
			register: defaultRegister,
		},
	];

export const HasAnticipateChangesToCircumstancesOptions: Array<RadioInputTypeWithRegister> =
	[
		{
			label: "Yes",
			name: "hasAnticipateChangesToCircumstances",
			value: "true",
			checked: false,
			register: defaultRegister,
		},
		{
			label: "No",
			name: "hasAnticipateChangesToCircumstances",
			value: "false",
			checked: false,
			register: defaultRegister,
		},
	];

export const HasAdverseCreditHistoryOptions: Array<RadioInputTypeWithRegister> =
	[
		{
			label: "Yes",
			name: "hasAdverseCreditHistory",
			value: "true",
			checked: false,
			register: defaultRegister,
		},
		{
			label: "No",
			name: "hasAdverseCreditHistory",
			value: "false",
			checked: false,
			register: defaultRegister,
		},
	];

export const ResidencyStatusOptions: Array<{
	id: number;
	label: string;
	value: ResidencyStatus;
}> = [
	{
		id: 1,
		label: "Australian Citizen",
		value: ResidencyStatus.AU,
	},
	{
		id: 2,
		label: "NZ Citizen",
		value: ResidencyStatus.NZ,
	},
	{
		id: 3,
		label: "Permanent Resident",
		value: ResidencyStatus.PermanentResident,
	},
	{
		id: 4,
		label: "Other Visa",
		value: ResidencyStatus.OtherVisa,
	},
];

export const LoanPreferenceOptions: Array<OptionsType> = [
	{
		id: 1,
		label: "Lower Upfront Fees",
		value: "Lower Upfront Fees",
	},
	{
		id: 2,
		label: "Lower Interest Rate",
		value: "Lower Interest Rate",
	},
	{
		id: 3,
		label: "No Preference",
		value: "No Preference",
	},
];

export const AustraliaStateOptions: Array<OptionsType> = [
	{
		id: 1,
		label: "New South Wales",
		value: "NSW",
	},
	{
		id: 2,
		label: "Victoria",
		value: "VIC",
	},
	{
		id: 3,
		label: "Queensland",
		value: "QLD",
	},
	{
		id: 4,
		label: "Western Australia",
		value: "WA",
	},
	{
		id: 5,
		label: "South Australia",
		value: "SA",
	},
	{
		id: 6,
		label: "Tasmania",
		value: "TAS",
	},
	{
		id: 7,
		label: "Australian Capital Territory",
		value: "ACT",
	},
	{
		id: 8,
		label: "Northern Territory",
		value: "NT",
	},
];
