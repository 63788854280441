import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import type { FunctionComponent } from "../../../common/types";
import type { HeaderMenuProps } from "./HeaderMenu";

const HeaderMenuDesktop = ({
	currentUser,
	canReInviteJointApplication,
	handleInvite,
	handleLogout
}: HeaderMenuProps): FunctionComponent => (<>
	<Menu as="div" className="relative inline-block text-left">
		<div>
			<Menu.Button className="flex items-center gap-2 leading-6 transition-all ext-base font-normal w-fit">
				<span className="flex-grow text-start">Menu</span> <i className="icon-hamburger" />
			</Menu.Button>
		</div>
		<Transition
			as={Fragment}
			enter="transition ease-out duration-100"
			enterFrom="transform opacity-0 scale-95"
			enterTo="transform opacity-100 scale-100"
			leave="transition ease-in duration-75"
			leaveFrom="transform opacity-100 scale-100"
			leaveTo="transform opacity-0 scale-95"
		>
			<Menu.Items className="absolute z-10 right-0 mt-6 px-5 w-80 origin-top-right divide-y divide-off-black-600 bg-black text-white focus:outline-none">
				<div className="pt-5 pb-5">
					<Menu.Item>
						<>
							<div className="leading-4">{currentUser?.fullName}</div>
							<div className="overflow-hidden"><small className="text-off-black-400 text-sm leading-1 block truncate">{currentUser?.email}</small></div>
						</>
					</Menu.Item>
				</div>
				{canReInviteJointApplication && <div className="py-3">
					<Menu.Item>
						<button
							className="flex items-center gap-2 leading-6 transition-all ext-base font-normal w-fit"
							onClick={handleInvite}
						>
							<i className="icon-asterisk" />
							<span className="flex-grow text-start">Resend Joint Borrower Invitation</span>
						</button>
					</Menu.Item>
				</div>}
				<div className="pt-3 pb-5">
					<Menu.Item>
						<button
							className="flex items-center gap-2 leading-6 transition-all ext-base font-normal w-fit"
							onClick={handleLogout}
						>
							<i className="icon-log-out" />
							<span className="flex-grow text-start">Logout</span>
						</button>
					</Menu.Item>
				</div>
			</Menu.Items>
		</Transition>
	</Menu>
</>);

export default HeaderMenuDesktop;
