import { useQueryPostcodeVerificationQuery } from "../services/api";

const INVESTMENT_RESTRICTED_POSTCODES = new Set([
    "2000", "2017", "2018", "2020", "2077", 
    "2113", "2114", "2121", "2127", "2141", 
    "2142", "2144", "2145", "2150", "2155", 
    "2160", "2170", "2205", "2220", "2750"
]);

interface UsePostcodeVerificationProps {
    postcode: string | null;
    isInvestmentProperty: boolean;
}

export const usePostcodeVerification = ({ postcode, isInvestmentProperty }: UsePostcodeVerificationProps) => {
    // First check if it's an investment property in a restricted postcode
    const isInvestmentPostcodeRestricted = isInvestmentProperty && postcode 
        ? INVESTMENT_RESTRICTED_POSTCODES.has(postcode)
        : false;

    // Only check API if:
    // 1. Not an investment property, OR
    // 2. Investment property but not in restricted list
    const {
        data: postcodeVerificationData,
        isSuccess,
        isError,
        error,
    } = useQueryPostcodeVerificationQuery(postcode ?? "", {
        skip: !postcode || 
              postcode.length !== 4 || 
              (isInvestmentProperty && isInvestmentPostcodeRestricted),
    });

    // If it's a restricted investment postcode, return error without checking API
    if (isInvestmentProperty && isInvestmentPostcodeRestricted) {
        return {
            isPostcodeValid: false,
            isError: true,
            error: {
                data: {
                    message: "We currently don't support investment loans in this postcode"
                }
            },
            postcodeVerificationData: null,
        };
    }

    // Otherwise return API validation results
    return {
        isPostcodeValid: isSuccess,
        isError,
        error,
        postcodeVerificationData,
    };
}; 