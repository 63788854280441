import type { OptionsType } from "../../../../../common/types";

import { EmploymentType } from "../../../../../services/apis/update-income.schema";

export const EmploymentOptions: Array<OptionsType> = [
    {
        id: 1,
        label: "Full Time Employee",
        value: EmploymentType.FullTimeEmploymentPAYG,
    },
    {
        id: 2,
        label: "Part Time Employee",
        value: EmploymentType.PartTimeEmploymentPAYG,
    },
    {
        id: 3,
        label: "Contractor",
        value: EmploymentType.Contractor,
    },
    {
        id: 4,
        label: "Casual",
        value: EmploymentType.Casual,
    },
    {
        id: 5,
        label: "Self-Employed",
        value: EmploymentType.SelfEmployedBusinessOwner,
    },
    {
        id: 6,
        label: "I’m not employed at the moment",
        value: EmploymentType.NotEmployed,
    },
];