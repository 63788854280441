import { useCallback, useState } from "react";

type OkModal = "invalidPostcode" | "vacantLand" | "multipleInvestment";

export const useLocalModal = () => {
	const [showModalList, setShowModalList] = useState<OkModal[]>([]);

	const modalOpen = useCallback((modal: OkModal) => {
		setShowModalList(oldList => oldList.includes(modal) ? oldList : [...oldList, modal]);
	}, []);

	const modalClose = useCallback((modal: OkModal) => {
		setShowModalList(oldList => oldList.filter((item) => item !== modal));
	}, []);

	return {
		modalOpen,
		modalClose,
		modalShow: showModalList?.[0]
	}
};
