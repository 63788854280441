import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../../hooks/redux-hooks";
import {
	setEmploymentHistory,
	setCurrentEmploymentType,
	setPreviousFinanceActiveForm,
	setEmploymentGapExplanation,
} from "../../../../../../store/slices/form-slice";
import {
	addDynamicHint,
	removeDynamicHint,
	setDynamicHints,
} from "../../../../../../store/slices/ui-slice";
import Button from "../../../../../ui/button/Button";
import {
	type FullTimeEmployeeFormSlice,
	type TwoYearsFullTimeEmployeeHistoryFormSlice,
	SelectTwoYearsFullTimeEmployeeHistoryForm,
} from "./FullTimeEmployee.Schema";
import FullTimeEmployeeHistoryDisplay from "./full-time-employee-history-display/FullTimeEmployeeHistoryDisplay";
import { sumYearsMonthsEmploymentHistory } from "../../../../../utils/helpers";
import { navigateToJointStep } from "../../../../../../store/slices/joint-stepper-slice";
import { navigateToStep } from "../../../../../../store/slices/stepper-slice";
import { SubStep } from "../../../../../../services/apis/create-application.schema";
import useIsMobile from "../../../../../../hooks/use-is-mobile";
import { EmploymentHistoryHint } from "../../../../../ui/hint/EmploymentHistoryHint";
import FormLayout from "../../../../../ui/form/Form";
import type { OptionsType } from "../../../../../../common/types";
import TextArea from "../../../../text-area/TextArea";
import { calculateEmploymentGaps } from "../../../../../../helpers/calculate-employment-gaps";

const emptyEmploymentHistory = {} as FullTimeEmployeeFormSlice;

function FullTimeEmployee({
	isJoint,
	employemntOption,
}: {
	isJoint?: boolean;
	employemntOption: OptionsType;
}) {
	const dispatch = useAppDispatch();
	const isMobile = useIsMobile();
	const formState = useAppSelector(SelectTwoYearsFullTimeEmployeeHistoryForm);
	const {
		setValue,
		handleSubmit,
		watch,
		formState: { errors },
		register,
	} = useForm({
		defaultValues: formState,
		mode: "all",
	});

	const employmentHistories = watch("employmentHistory") ?? [];
	const [hasGap, setHasGap] = useState(false);
	const [gapError, setGapError] = useState<string | null>(null);
	const totalMonths = sumYearsMonthsEmploymentHistory(employmentHistories);
	const percentage = (totalMonths / 24) * 100;

	useEffect(() => {
		setHasGap(calculateEmploymentGaps(employmentHistories));
	}, [employmentHistories]);

	const saveData = (rawData: TwoYearsFullTimeEmployeeHistoryFormSlice) => {
		const data = {
			...rawData,
			employmentHistory: employmentHistories,
			employmentGapExplanation: rawData.employmentGapExplanation,
		};

		// Validate employment gap explanation if there is a gap
		if (
			hasGap &&
			(!data.employmentGapExplanation || data.employmentGapExplanation.trim() === "")
		) {
			setGapError("Please explain any gaps in your employment history greater than 1 month.");
			return;
		}

		setGapError(null);

		// Dispatch actions to save data
		dispatch(setCurrentEmploymentType(employemntOption));
		dispatch(setPreviousFinanceActiveForm(SubStep.YourEmployment));
		dispatch(setEmploymentHistory(data.employmentHistory));
		if (hasGap && data.employmentGapExplanation) {
			dispatch(setEmploymentGapExplanation(data.employmentGapExplanation));
		}

		// Navigate to next step if total months are sufficient
		if (totalMonths >= 24) {
			if (isJoint) {
				dispatch(
					navigateToJointStep({
						step: "financeStep",
						subStep: SubStep.YourEmployeeIncome,
					})
				);
			} else {
				dispatch(
					navigateToStep({
						step: "financeStep",
						subStep: SubStep.YourEmployeeIncome,
					})
				);
			}
		}
	};

	const onChangeHandler = (
		employmentHistory: FullTimeEmployeeFormSlice,
		index: number
	) => {
		const updatedEmploymentHistory = [...employmentHistories];
		updatedEmploymentHistory[index] = {
			...updatedEmploymentHistory[index],
			...employmentHistory,
		};
		dispatch(setEmploymentHistory(updatedEmploymentHistory));
		setValue("employmentHistory", updatedEmploymentHistory);
	};

	const removeHandler = (index: number) => {
		const updatedEmploymentHistory = [...employmentHistories];
		updatedEmploymentHistory.splice(index, 1);
		setValue("employmentHistory", updatedEmploymentHistory);
	};

	useEffect(() => {
		if (employmentHistories.length === 0) {
			setValue("employmentHistory", [{ ...emptyEmploymentHistory }]);
		}
	}, [setValue, employmentHistories]);

	useEffect(() => {
		dispatch(setDynamicHints([]));
		dispatch(addDynamicHint("EmploymentHistoryHint"));
		return () => {
			dispatch(removeDynamicHint("EmploymentHistoryHint"));
		};
	}, [dispatch]);

	const header = <></>;

	const content = (
		<div className="flex flex-col gap-9">
			{isMobile && (
				<EmploymentHistoryHint
					percentage={percentage}
					totalMonths={totalMonths}
				/>
			)}
			{employmentHistories.map((employment, index) => (
				<FullTimeEmployeeHistoryDisplay
					key={index}
					employmentHistory={employment}
					employmentType={employemntOption.id}
					index={index}
					errors={errors}
					onChangeHandler={onChangeHandler}
					removeHandler={removeHandler}
				/>
			))}
			{totalMonths < 24 && (
				<Button
					text={
						<span>
							<i className="icon-plus" />
							Add a previous employment
						</span>
					}
					type="button"
					size="full"
					variant="accent"
					textAlign="center"
					handleClick={() => {
						const updatedEmploymentHistory = [
							...employmentHistories,
							{ ...emptyEmploymentHistory },
						];
						setValue("employmentHistory", updatedEmploymentHistory);
					}}
				/>
			)}
			{/* Conditionally render the gap explanation text box */}
			{hasGap && (
				<TextArea
					label="Please explain any gaps in your employment history greater than 1 month"
					name="employmentGapExplanation"
					register={register}
					error={gapError || errors.employmentGapExplanation?.message}
				/>
			)}
		</div>
	);

	const footer = (
		<div
			className="flex items-center justify-end gap-4"
			aria-labelledby="Actions wrapper"
		>
			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				type="submit"
				isDisabled={totalMonths < 24}
			/>
		</div>
	);

	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData)}
		/>
	);
}

export default FullTimeEmployee;
