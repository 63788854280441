import { z } from "zod";

import type { RootState } from "../../../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import { addMonths, parse } from "date-fns";

const requiredString = z
  .string({
    // eslint-disable-next-line camelcase
    required_error: "This field is required",
    // eslint-disable-next-line camelcase
    invalid_type_error: "This field is required",
  })
  .min(1, {
    message: "This field is required",
  });

export type ContractEmployeeFormSlice = {
  isCurrent: boolean;
  employerName: string;
  jobTitle: string;
  jobStartDate: string;
  jobEndDate?: string;
  expectingExtension: boolean;
  extensionDuration?: {
    years: number;
    months: number;
  };
  industryExperience?: {
    years: number;
    months: number;
  };
};

// Define the schema for an employment history item
const employmentHistoryItemSchema = z.object({
  isCurrent: z.boolean(),
  employerName: requiredString,
  jobTitle: requiredString,
  jobStartDate: requiredString,
  jobEndDate: z.string().optional(),
  expectingExtension: z.boolean(),
  extensionDuration: z
    .object({
      years: z.number().min(0),
      months: z.number().min(0).max(11),
    })
    .optional(),
  industryExperience: z
    .object({
      years: z.coerce.number().min(0),
      months: z.coerce.number().min(0).max(11),
    })
    .optional(),
});

// Refine the schema to conditionally require fields
const refinedEmploymentHistoryItemSchema = employmentHistoryItemSchema.refine(
  (data) => {
    if (data.isCurrent && !data.jobEndDate) {
      return false;
    }
    return true;
  },
  {
    message: "Contract end date is required for current employment",
    path: ["jobEndDate"],
  }
);

// Update the main schema
export const ContractEmployeeHistorySchema = z
  .object({
    employmentHistory: z.array(refinedEmploymentHistoryItemSchema),
    employmentGapExplanation: z.string().optional(),
  })
  .superRefine((data, context) => {
    const currentDate = new Date();
    const threeMonthsFromNow = addMonths(currentDate, 3);

    data.employmentHistory.forEach((history, index) => {
      // For current employment
      console.log('Checking current employment for index:', index);
      console.log('History:', history);
      if (history.isCurrent) {
        console.log('Contract end date:', history.jobEndDate);
        const contractExpiry = history.jobEndDate
          ? parse(history.jobEndDate, "dd/MM/yyyy", new Date())
          : null;
        console.log('Contract end date:', contractExpiry);
        console.log('Three months from now:', threeMonthsFromNow);

        if (contractExpiry && contractExpiry < threeMonthsFromNow) {
          console.log('Contract expires within 3 months');
          // If contract expires in less than 3 months, extension details are required
          if (Number.isNaN(history.expectingExtension)) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message:
                "Contract extension information is required for contracts expiring within 3 months",
              path: ["employmentHistory", index, "expectingExtension"],
            });
          }

          if (history.expectingExtension && !history.extensionDuration) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Extension duration is required",
              path: ["employmentHistory", index, "extensionDuration"],
            });
          }

          // Only require industry experience for first employment and if contract expires in less than 3 months
          console.log('Checking industry experience for index:', index);
          if (
            index === 0 &&
            (Number.isNaN(history.industryExperience?.months) ||
              Number.isNaN(history.industryExperience?.years))
          ) {
            console.log('Industry experience validation failed');
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Industry experience is required",
              path: ["employmentHistory", index, "industryExperience"],
            });
          }
        }
      } else {
        // For previous employments, jobEndDate is required
        if (!history.jobEndDate || history.jobEndDate.length === 0) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Job end date is required for previous employments",
            path: ["employmentHistory", index, "jobEndDate"],
          });
        }
      }
    });
  });

export type ContractEmployeeHistoryFormType = z.infer<
  typeof ContractEmployeeHistorySchema
>;

export type ContractEmployeeHistoryFormSlice = {
  employmentHistory?: Array<ContractEmployeeFormSlice>;
  employmentGapExplanation?: string;
};

export const SelectContractEmployeeHistoryForm = createSelector(
  (state: RootState) => state.form,
  (form): ContractEmployeeHistoryFormSlice => ({
    employmentHistory: form.employmentHistory?.map(history => ({
      ...history,
      expectingExtension: history.expectingExtension ?? false,
      isCurrent: history.isCurrent ?? false,
    })) as ContractEmployeeFormSlice[],
    employmentGapExplanation: form.employmentGapExplanation,
  })
);
