import type {
	SharesEquitiesInvestmentsAsset,
	InvestmentPropertyAsset, VehicleAsset,
} from "../../components/forms/steps/your-finances/your-assets/YourAssetsSchema";
import type {
	BasicSharesSchema,
	BasicInvestmentsSchema,
	BasicLoansSchema,
	UpdateAssetsRequest,
} from "../../services/apis/update-assets.schema";
import type { RootState } from "../index";
import { createSelector } from "@reduxjs/toolkit";
import {
	type LoanOnProperty,
	SubStep,
	Step,
} from "../../services/apis/create-application.schema";

export const setShares = (
	formShares: Array<SharesEquitiesInvestmentsAsset>
) => {
	const shares: BasicSharesSchema = [];
	if (!formShares) return shares;
	formShares.forEach((formShare) => {
		shares.push({
			name: formShare.investmentName,
			value: formShare.currentValue,
			timeOwnedInMonths:
				Number(formShare.timeOwnedMonths.value) +
				Number(formShare.timeOwnedYears.value) * 12,
		});
	});
	return shares;
};
const setLoans = (loansOnProperty: Array<LoanOnProperty>) => {
	const loans: BasicLoansSchema = [];
	if (!loansOnProperty) return loans;
	loansOnProperty.forEach((loan) => {
		loans.push({
			amount: loan.amount!,
			interestRate: loan.interestRate!,
			monthlyRepayment: loan.monthlyRepayment!,
			interestType: loan.type!,
			fixedRateExpiryDate: loan.fixedRateExpiryDate!,
			remainingTermInMonths:
				Number(loan.remainingTermInMonths?.value) +
				Number(loan.remainingTermInYears?.value) * 12,
			remainingTermInYears: Number(loan.remainingTermInYears?.value),
		});
	});
	return loans;
};
export const setInvestments = (
	formInvestments: Array<InvestmentPropertyAsset>
) => {
	const investments: BasicInvestmentsSchema = [];
	if (!formInvestments) return investments;
	formInvestments.forEach((investment) => {
		investments.push({
			address: investment.propertyAddress,
			jointApplicantOwnership: investment.jointApplicantOwnership,
			primaryApplicantOwnership: investment.primaryApplicantOwnership,
			propertyValue: investment.propertyValue,
			monthlyRentalIncome: investment.monthlyRentalIncome,
			monthlyRentalExpenses: investment.monthlyExpenses,
			loans: setLoans(investment.loansOnProperty!),
		});
	});
	return investments;
};
export const setVehicleAssets = (
	assets: Array<VehicleAsset> | undefined
) => {
	if (!assets) return [];
	return assets.map(itm => ({
		...itm,
		liabilities: itm.liabilities?.map(loan => ({
			remainingTermInMonths: Number(loan.remainingTermsInYears?.value ?? 0) * 12,
			interestRate: loan.interestRate,
			accountName: loan.accountName,
			loanAmount: loan.loanAmount,
			monthlyRepayments: loan.monthlyRepayments,
			openToClosing: loan.openToClosing,
			debtIsBeingConsolidated: loan.debtIsBeingConsolidated,

		}))
	}));
};

export const selectUpdateAssetsPayload = createSelector(
	[(state: RootState) => state.form],
	(form) => {
		const payload: Partial<UpdateAssetsRequest> = {
			applicationStep: Step.YourFinances,
			applicationSubStep: SubStep.YourLiabilities,
			car: setVehicleAssets(form.carAssets),
			savings: form.cashOrSavingAssets,
			superannuation: form.superannuationAssets,
			shares: setShares(form.sharesEquitiesInvestmentsAssets!),
			otherAssets: form.otherAssets,
			investmentProperty: setInvestments(form.investmentPropertyAssets!),
		};
		// Filter out undefined, null, or "" values
		Object.keys(payload).forEach((key) => {
			const payloadKey = key as keyof typeof payload;
			if (
				payload[payloadKey] === undefined ||
				payload[payloadKey] === null ||
				(Array.isArray(payload[payloadKey]) &&
					payload[payloadKey]?.length === 0)
			) {
				delete payload[payloadKey];
			}
		});

		return payload as UpdateAssetsRequest;
	}
);
