/* eslint-disable camelcase */
import { z } from "zod";
import type { OptionsType } from "../../../../../../common/types";
import type { RootState } from "../../../../../../store";
import { createSelector } from "@reduxjs/toolkit";

const requiredString = z
	.string({
		required_error: "This field is required",
		invalid_type_error: "This field is required",
	})
	.min(1, {
		message: "This field is required",
	});
 
export type FullTimeEmployeeFormSlice = {
	isCurrent: boolean;
	employerName: string;
	jobTitle: string;
	jobStartDate: string;
	jobEndDate?: string;
	inProbation?: string;
	industryMonths?: OptionsType;
	industryYears?: OptionsType;
	expectingExtension?: boolean;
	contractExtensionInMonths?: number;
	industryExperience?: {
		years: number;
		months: number;
	};
	extensionDuration?: {
		years: number;
		months: number;
	};
};
export const TwoYearsFullTimeEmployeeHistorySchema = z
	.object({
		employmentGapExplanation: z.string().optional(),
		employmentHistory: z
			.object({
				isCurrent: z.boolean(),
				employerName: requiredString,
				jobTitle: requiredString,
				jobStartDate: requiredString,
				jobEndDate: z.string().optional(),
				inProbation: z.string().optional(),
				industryMonths: z
					.object({
						id: z.number(),
						label: z.string(),
						value: z.string(),
					})
					.optional(),
				industryYears: z
					.object({
						id: z.number(),
						label: z.string(),
						value: z.string(),
					})
					.optional(),
			})
			.array(),
	})
	.superRefine(({ employmentHistory }, context) => {
		if (employmentHistory.length > 1) {
			employmentHistory.forEach(({ jobEndDate }, index) => {
				if (index !== 0 && (!jobEndDate || jobEndDate === "")) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please enter the end date of your previous employer`,
						path: ["employmentHistory", index, "jobEndDate"],
					});
				}
			});
		}
	});

export type TwoYearsFullTimeEmployeeHistoryFormType = z.infer<
	typeof TwoYearsFullTimeEmployeeHistorySchema
>;
export type TwoYearsFullTimeEmployeeHistoryFormSlice = {
	employmentHistory?: Array<FullTimeEmployeeFormSlice>;
	employmentGapExplanation?: string;
};
export const SelectTwoYearsFullTimeEmployeeHistoryForm = createSelector(
	(state: RootState) => state.form,
	(form): TwoYearsFullTimeEmployeeHistoryFormSlice => ({
		employmentHistory: form.employmentHistory,
		employmentGapExplanation: form.employmentGapExplanation,
	})
);
