import { useEffect } from "react";
import type { FunctionComponent } from "../../../../../common/types";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import Button from "../../../../ui/button/Button";
import {
	selectIsRefinance,
	setCreditCardLiabilities,
	setHECsHELPLiabilities,
	setNoLiabilities,
	setPersonalLoansLiabilities,
	setVehicleLoansLiabilities,
} from "../../../../../store/slices/form-slice";
import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import {
	type LiabilitiesFormSlice,
	LiabilitiesSchema,
	selectLiabilities,
	type LiabilitiesSchemaFormType,
} from "./YourLiabilitiesSchema";
import CheckboxInput from "../../../checkbox-input/CheckboxInput";
import CreditCardLiabilitiesForm from "./credit-card-liabilties-form/CreditCardLiabilitiesForm";
import LoanLiabilitiesForm from "./loan-liabilities-form/LoanLiabilitiesForm";
import HECsHELPLiabilitiesForm from "./hecs-help-liabilities-form/HeCsHelpLiabilitiesFrom";
import {
	SubStep,
} from "../../../../../services/apis/create-application.schema";
import {
	selectUpdateLiabilitiesPayload,
	setLoanLiabilitiesForAPI,
} from "../../../../../store/selectors/update-liabilities-payload";
import { navigateToJointStep } from "../../../../../store/slices/joint-stepper-slice";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import { setFormLoading } from "../../../../../store/slices/loader-slice";
import { setToast } from "../../../../../store/slices/toast-slice";
import FormLayout from "../../../../ui/form/Form";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import { useUpdateLiabilities } from "../../../../../services/apis/origination/liabilities";
import { captureException } from "../../../../../services/sentry";

const YourLiabilities = ({
	isJoint,
}: {
	isJoint?: boolean;
}): FunctionComponent => {
	const dispatch = useAppDispatch();
	const formState = useAppSelector(selectLiabilities);
	const isRefinance = useAppSelector(selectIsRefinance);
	const [updateLiabilities, { isLoading }] = useUpdateLiabilities();
	const updateLiabilitiesPayload = useAppSelector(
		selectUpdateLiabilitiesPayload
	);

	const {
		handleSubmit,
		control,
		register,
		formState: { errors },
		setValue,
		watch,
	} = useForm({
		resolver: zodResolver(LiabilitiesSchema),
		defaultValues: formState,
	});

	useTrackPageViewOnMount({
		page: "Your Finances",
		subPage: "Your Liabilities",
	});
	const noLiabilities = watch("noLiabilities");

	useEffect(() => {
		if (noLiabilities) {
			// List all form fields that should be cleared
			const fieldsToClear: Array<keyof LiabilitiesSchemaFormType> = [
				"creditCardLiabilities",
				"personalLoansLiabilities",
				"vehicleLoansLiabilities",
				"hECsHELPLiabilities",
			];
			fieldsToClear.forEach((field) => {
				setValue(field, []);
			});
		}
	}, [noLiabilities, setValue]);
	const saveData = async (rawData: LiabilitiesFormSlice) => {
		// Assuming validation ensures all values are defined, we can assert the type here - this is a hack because the zod inference with the form schema is not working
		const data: LiabilitiesSchemaFormType =
			rawData as LiabilitiesSchemaFormType;
		dispatch(setNoLiabilities(data.noLiabilities!));
		if (data.noLiabilities) {
			dispatch(setCreditCardLiabilities([]));
			dispatch(setPersonalLoansLiabilities([]));
			dispatch(setVehicleLoansLiabilities([]));
			dispatch(setHECsHELPLiabilities([]));
		} else {
			dispatch(setCreditCardLiabilities(data.creditCardLiabilities!));
			dispatch(setPersonalLoansLiabilities(data.personalLoansLiabilities!));
			dispatch(setVehicleLoansLiabilities(data.vehicleLoansLiabilities!));
			dispatch(setHECsHELPLiabilities(data.hECsHELPLiabilities!));
		}
		try {
			const payload = {
				...updateLiabilitiesPayload,
				creditCards: noLiabilities ? [] : data.creditCardLiabilities!,
				carLoans: noLiabilities
					? []
					: setLoanLiabilitiesForAPI(data.vehicleLoansLiabilities!),
				personalLoans: noLiabilities
					? []
					: setLoanLiabilitiesForAPI(data.personalLoansLiabilities!),
				hecs: noLiabilities ? [] : data.hECsHELPLiabilities!,
			};
			await updateLiabilities({
				...payload,
			}).unwrap();

			if (isJoint) {
				dispatch(
					navigateToJointStep({
						step: "financeStep",
						subStep: SubStep.YourCircumstances,
					})
				);
			} else {
				dispatch(
					navigateToStep({
						step: "financeStep",
						subStep: SubStep.YourCircumstances,
					})
				);
			}
		} catch (error) {
			captureException(new Error("Error saving liabilities"), {data: {error}});
			dispatch(
				setToast({
					open: true,
					type: "error",
					title: "Error",
					description: "An error occurred while saving your employment income.",
				})
			);
		}
	};

	useEffect(() => {
		dispatch(setFormLoading(isLoading));
		return () => {
			dispatch(setFormLoading(false));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);
	const header = (
		<h1
			className="text-primary text-[37.9px] font-normal"
			aria-labelledby="Your Liabilities"
		>
			Your Liabilities
		</h1>
	);
	const content = (
		<div className="flex flex-col gap-9">
			<CheckboxInput
				label="I don’t have any of these liabilities"
				name="noLiabilities"
				checked={false}
				register={register}
				size="small"
				checkPosition="start"
			/>
			{!watch("noLiabilities") && (
				<>
					<div className="flex flex-col gap-4">
						<h1 className="text-primary text-[21.33px] font-normal mt-4">
							<i className="icon-credit-card" />
							Credit Cards
						</h1>
						<CreditCardLiabilitiesForm
							initialCreditCardLiabilities={formState.creditCardLiabilities}
							control={control}
							errors={errors.creditCardLiabilities}
							onChange={(liabilities) => {
								setValue("creditCardLiabilities", liabilities);
							}}
							showDebtConsolidation={isRefinance}
						/>
					</div>
					<div className="flex flex-col gap-4">
						<h1 className="text-primary text-[21.33px] font-normal mt-4">
							<i className="icon-document-success" />
							Personal Loans
						</h1>
						<LoanLiabilitiesForm
							accountNamePlaceHolder="Financial Institution"
							loanAmountPlaceHolder="Loan Amount (incl. Redraw)"
							addButtonLabel="Add personal loan"
							initialLoanLiabilities={formState.personalLoansLiabilities}
							subFormName="personalLoansLiabilities"
							control={control}
							errors={errors.personalLoansLiabilities}
							onChange={(liabilities) => {
								setValue("personalLoansLiabilities", liabilities);
							}}
							showDebtConsolidation={isRefinance}
						/>
					</div>
					{/*<div className="flex flex-col gap-4">*/}
					{/*	<h1 className="text-primary text-[21.33px] font-normal mt-4">*/}
					{/*		<i className="icon-car" />*/}
					{/*		Car Loans*/}
					{/*	</h1>*/}
					{/*	<LoanLiabilitiesForm*/}
					{/*		accountNamePlaceHolder="Car Loan Name"*/}
					{/*		loanAmountPlaceHolder="Loan Amount"*/}
					{/*		addButtonLabel="Add car loan"*/}
					{/*		initialLoanLiabilities={formState.vehicleLoansLiabilities}*/}
					{/*		subFormName="vehicleLoansLiabilities"*/}
					{/*		control={control}*/}
					{/*		errors={errors.vehicleLoansLiabilities}*/}
					{/*		onChange={(liabilities) => {*/}
					{/*			setValue("vehicleLoansLiabilities", liabilities);*/}
					{/*		}}*/}
					{/*		showDebtConsolidation={isRefinance}*/}
					{/*	/>*/}
					{/*</div>*/}
					<div className="flex flex-col gap-4">
						<h1 className="text-primary text-[21.33px] font-normal mt-4">
							<i className="icon-student" />
							HECs/HELP
						</h1>
						<HECsHELPLiabilitiesForm
							initialHECsHELPLiabilities={formState.hECsHELPLiabilities}
							control={control}
							errors={errors.hECsHELPLiabilities}
							onChange={(liabilities) => {
								setValue("hECsHELPLiabilities", liabilities);
							}}
							showDebtConsolidation={isRefinance}
						/>
					</div>
				</>
			)}
		</div>
	);
	const footer = (
		<div
			className="flex items-center justify-end gap-4"
			aria-labelledby="Actions wrapper"
		>
			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				isDisabled={isLoading}
			/>
		</div>
	);
	return (
		<>
			<FormLayout
				header={header}
				content={content}
				footer={footer}
				onSubmit={handleSubmit(saveData)}
			/>
		</>
	);
};

export default YourLiabilities;
