import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AuthLayout from "../common/layout/AuthLayout";
import type { FunctionComponent } from "../common/types";
import AcceptInviteForm from "../components/forms/accept-invite/AcceptInviteForm";
import Modal from "../components/forms/modal/Modal";
import type { ButtonType } from "../components/ui/button/Button";
import { useLogOut } from "../hooks/use-logout";
import useTrackPageViewOnMount from "../hooks/use-track-on-mount";
import { AcceptInviteFormV2 } from "../components/forms/accept-invite/AcceptInviteFormV2";
import { getDecodedToken } from "../lib/utils";
import { useLDFlags } from "../hooks/use-ld-flags";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "../hooks/redux-hooks";
import { setUseOrigination } from "../store/slices/origination-slice";


const AcceptInvite = (): FunctionComponent => {
	const dispatch = useAppDispatch()
	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const { loginWithRedirect } = useAuth0();
	const [modalAction, setModalAction] = useState<Array<ButtonType>>([]);
	const [searchParameters] = useSearchParams();
	const { clearSession } = useLogOut();
	const { auth0Login } = useLDFlags();
	const inviteToken = searchParameters.get("invite_token");
	const decodedToken = getDecodedToken(inviteToken);
	const tokenExpiry = (decodedToken?.exp ?? 0) * 1000;
	useTrackPageViewOnMount({
		page: "Accept Invite",
	});

	useEffect(() => {
		clearSession();
		dispatch(setUseOrigination(false));
	}, [clearSession, dispatch]);

	useEffect(() => {
		const interval = setInterval(() => {
			const currentTime = Date.now();
			if (currentTime > tokenExpiry) {
				setShowLogoutModal(true);
				setModalAction([
					{
						text: "Complete your application",
						variant: "primary",
						handleClick: () => {
							void loginWithRedirect();
						},
						type: "button",
						textAlign: "center",
						size: "fit",
					},
				]);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenExpiry]);

	return (
		<AuthLayout>
			{(!inviteToken || !decodedToken) && (
				<>
					<section className="lg:max-w-96 flex flex-col justify-center h-full p-4">
						<h1 className="text-primary text-6xl leading-snug font-normal">
							Oh no!
						</h1>
						<p className="font-normal leading-8 mb-10 lg:mb-16">
							This link is invalid. Please email support@sucasa.com for
							assistance
						</p>
					</section>
				</>
			)}
			{inviteToken && decodedToken && (
				<>
					<section className="lg:max-w-96 flex flex-col justify-center h-full p-4">
						{!auth0Login && <AcceptInviteForm inviteToken={inviteToken} />}
						{auth0Login && <AcceptInviteFormV2 inviteToken={inviteToken} />}
					</section>
					<Modal
						isOpen={showLogoutModal}
						actions={modalAction}
						title={
							<div
								className="flex flex-col gap-4 md:gap-8 items-center text-[28.43px] font-medium leading-10 text-primary">
								<i className="icon-warning text-2xl md:text-[80px]" />
							</div>
						}
						hideCloseButton
						onClose={() => {}}
						content={
							<p className="text-sm text-primary text-center mt-10">
								This invite link has expired, please sign in to access your application
							</p>
						}
					/>
				</>
			)}
		</AuthLayout>
	);
};

export default AcceptInvite;
