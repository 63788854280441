import type { FC } from "react";
import HintCard from "../hint-card/HintCard";

export const HouseHoldExpensesHint: FC = () => (
	<HintCard
		icon={<i className="icon-asterisk" />}
		text={
			<>
				<span className="font-medium">
					Household Expenses should include all loan applicants and their
					dependents, as well as your expected costs for your new property
				</span>
			</>
		}
	/>
);
