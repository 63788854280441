import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { differenceInMonths, parse } from "date-fns";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../../hooks/redux-hooks";
import {
	setEmploymentHistory,
	setCurrentEmploymentType,
	setPreviousFinanceActiveForm,
	setEmploymentGapExplanation,
} from "../../../../../../store/slices/form-slice";
import Button from "../../../../../ui/button/Button";
import { zodResolver } from "@hookform/resolvers/zod";
import { navigateToJointStep } from "../../../../../../store/slices/joint-stepper-slice";
import { navigateToStep } from "../../../../../../store/slices/stepper-slice";
import { SubStep } from "../../../../../../services/apis/create-application.schema";
import FormLayout from "../../../../../ui/form/Form";
import type { OptionsType } from "../../../../../../common/types";


// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import {
	ContractEmployeeFormSlice,
	ContractEmployeeHistoryFormSlice,
	ContractEmployeeHistorySchema,
	SelectContractEmployeeHistoryForm,
} from "./ContractEmployee.Schema";
import { sumYearsMonthsEmploymentHistory } from "../../../../../utils/helpers";
import ContractEmployeeHistoryDisplay from "./contract-employee-history-display/ContractEmployeeHistoryDisplay";
import { EmploymentHistoryHint } from "../../../../../ui/hint/EmploymentHistoryHint";
import useIsMobile from "../../../../../../hooks/use-is-mobile";
import { addDynamicHint, removeDynamicHint, setDynamicHints } from "../../../../../../store/slices/ui-slice";
import { calculateEmploymentGaps } from "../../../../../../helpers/calculate-employment-gaps";
import TextArea from "../../../../text-area/TextArea";

const emptyEmploymentHistory = {
	isCurrent: true,
	employerName: "",
	jobTitle: "",
	jobStartDate: "",
	jobEndDate: "",
	expectingExtension: false,
	extensionDuration: {
			years: 0,
			months: 0
	},
	industryExperience: {
		years: 0,
		months: 0
	},
} as ContractEmployeeFormSlice;

const ContractEmployee = ({
	isJoint,
	employemntOption,
}: {
	isJoint?: boolean;
	employemntOption: OptionsType;
}) => {
	const dispatch = useAppDispatch();
	const formState = useAppSelector(SelectContractEmployeeHistoryForm);
	const [hasGap, setHasGap] = useState(false);
	const [gapError, setGapError] = useState<string | null>(null);

	const {
		setValue,
		handleSubmit,
		watch,
		formState: { errors },
		register,
	} = useForm<ContractEmployeeHistoryFormSlice>({
		resolver: zodResolver(ContractEmployeeHistorySchema),
		defaultValues: formState || {
			employmentHistory: [{ ...emptyEmploymentHistory }],
		},
		mode: "all",
	});

	const currentEmployment = watch("employmentHistory")?.[0];

	const jobEndDate = currentEmployment?.jobEndDate
		? parse(currentEmployment.jobEndDate, "dd/MM/yyyy", new Date())
		: null;

	const monthsUntilExpiry =
		jobEndDate && differenceInMonths(jobEndDate, new Date());
	const isContractMoreThanThreeMonths =
		monthsUntilExpiry !== null && monthsUntilExpiry > 3;

	useEffect(() => {
		// If the contract is more than 3 months, we don't collect these values
		if (isContractMoreThanThreeMonths) {
			setValue(`employmentHistory.${0}.expectingExtension` as const, false);
			setValue(`employmentHistory.${0}.extensionDuration` as const, {
				years: 0,
				months: 0,
			});
		}
	}, [isContractMoreThanThreeMonths, setValue]);
		
	console.log(jobEndDate, monthsUntilExpiry, isContractMoreThanThreeMonths);

	const totalMonths = sumYearsMonthsEmploymentHistory(
		watch("employmentHistory") ?? []
	);
	const percentage = (totalMonths / 24) * 100;
    // dispatch(setEmploymentHistory())
	const saveData = (data: ContractEmployeeHistoryFormSlice) => {
		// Validate employment gap explanation if there is a gap
		if (
			hasGap &&
			(!data.employmentGapExplanation || data.employmentGapExplanation.trim() === "")
		) {
			setGapError("Please explain any gaps in your employment history greater than 1 month.");
			return;
		}

		setGapError(null);

		if (totalMonths >= 24) {
			dispatch(setCurrentEmploymentType(employemntOption));
			dispatch(setPreviousFinanceActiveForm(SubStep.YourEmployment));
			dispatch(setEmploymentHistory(data.employmentHistory as ContractEmployeeFormSlice[]));
			if (hasGap && data.employmentGapExplanation) {
				dispatch(setEmploymentGapExplanation(data.employmentGapExplanation));
			}

			if (isJoint) {
				dispatch(
					navigateToJointStep({
						step: "financeStep",
						subStep: SubStep.YourEmployeeIncome,
					})
				);
			} else {
				dispatch(
					navigateToStep({
						step: "financeStep",
						subStep: SubStep.YourEmployeeIncome,
					})
				);
			}
		}
	};

	

	useEffect(() => {
		if (!watch("employmentHistory") || watch("employmentHistory")?.length === 0) {
			setValue("employmentHistory", [{ ...emptyEmploymentHistory }]);
		}
	}, [setValue, watch]);

	useEffect(() => {
		dispatch(setDynamicHints([]));
		dispatch(addDynamicHint("EmploymentHistoryHint"));
		return () => {
			dispatch(removeDynamicHint("EmploymentHistoryHint"));
		};
	}, [dispatch]);

	useEffect(() => {
		const employmentHistories = watch("employmentHistory") ?? [];
		setHasGap(calculateEmploymentGaps(employmentHistories));
	}, [watch("employmentHistory")]);

	const isMobile = useIsMobile();

	const content = (
		<div className="flex flex-col gap-9">
			{isMobile && (
				<EmploymentHistoryHint percentage={percentage} totalMonths={totalMonths} />
			)}
			
			{watch("employmentHistory")?.map((employment, index) => (
				<ContractEmployeeHistoryDisplay
					key={index}
					employmentHistory={employment}
					index={index}
					isContractMoreThanThreeMonths={isContractMoreThanThreeMonths}
					errors={errors}
					onChangeHandler={(updatedHistory) => {
						const employmentHistories = watch("employmentHistory") ?? [];
						const updatedEmploymentHistory = [...employmentHistories];

						updatedEmploymentHistory[index] = {
							...updatedEmploymentHistory[index],
							...updatedHistory,
							extensionDuration: updatedHistory.expectingExtension
								? updatedHistory.extensionDuration
								: undefined,
						};

						dispatch(setEmploymentHistory(updatedEmploymentHistory));
						setValue("employmentHistory", updatedEmploymentHistory);
					}}
					removeHandler={(indexToRemove) => {
						const employmentHistories = watch("employmentHistory") ?? [];
						if (employmentHistories.length > 1) { // Prevent removing last item
							const updatedEmploymentHistory = employmentHistories.filter(
								(_, index_) => index_ !== indexToRemove
							);
							setValue("employmentHistory", updatedEmploymentHistory);
						}
					}}
				/>
			))}

			{totalMonths < 24 && (
				<Button
					text={
						<span>
							<i className="icon-plus" />
							Add a previous employment
						</span>
					}
					type="button"
					size="full"
					variant="accent"
					textAlign="center"
					handleClick={() => {
						const employmentHistories = watch("employmentHistory") ?? [];
						const updatedEmploymentHistory = [
							...employmentHistories,
							{ ...emptyEmploymentHistory, isCurrent: false },
						];
						setValue("employmentHistory", updatedEmploymentHistory);
					}}
				/>
			)}

			{hasGap && (
				<TextArea
					label="Please explain any gaps in your employment history greater than 1 month"
					name="employmentGapExplanation"
					register={register}
					error={gapError || errors.employmentGapExplanation?.message}
				/>
			)}
		</div>
	);

	const footer = (
		<div className="flex items-center justify-end gap-4">
			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				type="submit"
				isDisabled={totalMonths < 24}
			/>
		</div>
	);

	return (
		<>
			<FormLayout
				header={null}
				content={content}
				footer={footer}
				onSubmit={handleSubmit(saveData)}
			/>
		</>
	);
};

export default ContractEmployee;
