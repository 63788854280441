import { z } from "zod";
import type { RootState } from "../../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import { ResidencyStatus } from "../../../../../services/apis/create-application.schema";
import { isApprovedTemporaryVisa, isBridgingVisa } from "../../../../../lib/visa-helpers";

// Define the exact shape of OptionsType for the schema
const optionsSchema = z.object({
	id: z.number(),
	value: z.string(),
	label: z.string(),
});

const baseSchema = z.object({
	maritalStatus: optionsSchema.optional(),
	isRelationStatus: z.string().optional(),
	residencyStatus: optionsSchema.optional(),
	visaSubclass: z.number().optional().nullable(),
	expectPRInThreeMonths: z.string().optional().nullable(),
	numberOfDependants: z
		.number()
		.min(0, "Please enter a valid number of dependants")
		.max(10, "Please enter a valid number of dependants")
		.optional(),
	dependantAges: z
		.array(
			z
				.number()
				.min(0, "Please enter a valid dependant age")
				.max(100, "Please enter a valid dependant age")
		)
		.optional(),
	nonApplicantFinancialDetails: z.string().optional(),
});

export const getYouAndYourHouseholdSchema = (isJoint: boolean) => 
	baseSchema.superRefine(
		(
			{ maritalStatus, isRelationStatus, residencyStatus, visaSubclass, expectPRInThreeMonths },
			context
		) => {
			if (!residencyStatus || residencyStatus.id === 0) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Please select residency status`,
					path: ["residencyStatus"],
				});
			}
			if (
				residencyStatus &&
				[ResidencyStatus.OtherVisa, ResidencyStatus.PermanentResident].includes(
					residencyStatus.value as ResidencyStatus
				) &&
				!visaSubclass
			) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Please enter a valid visa subclass`,
					path: ["visaSubclass"],
				});
			}
			if (!maritalStatus || maritalStatus.id === 0) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Please select marital status`,
					path: ["maritalStatus"],
				});
			}
			if (
				isJoint &&
				maritalStatus &&
				(maritalStatus.id === 2 || maritalStatus.id === 3) &&
				(!isRelationStatus || isRelationStatus?.length === 0)
			) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Please select relation status`,
					path: ["isRelationStatus"],
				});
			}
			if (visaSubclass && (isBridgingVisa(visaSubclass) || isApprovedTemporaryVisa(visaSubclass)) && !expectPRInThreeMonths) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Please indicate if you expect to receive PR within 3 months`,
					path: ["expectPRInThreeMonths"],
				});
			}
		}
	);

export type YouAndYourHouseholdFormType = z.infer<typeof baseSchema>;

// Make sure the form slice type matches the schema type exactly
export type YouAndYourHouseholdFormSlice = YouAndYourHouseholdFormType;

export const SelectYouAndYourHouseholdForm = createSelector(
	(state: RootState) => state.form,
	(form) => ({
		maritalStatus: form.maritalStatus,
		isRelationStatus: form.isRelationStatus,
		residencyStatus: form.residencyStatus,
		visaSubclass: form.visaSubclass,
		expectPRInThreeMonths: form.expectPRInThreeMonths,
		numberOfDependants: form.numberOfDependants,
		dependantAges: form.dependantAges,
		nonApplicantFinancialDetails: form.nonApplicantFinancialDetails,
	})
);
