import Button from "../../../../../../ui/button/Button";
import type { FieldErrors } from "react-hook-form";
import type {
	FullTimeEmployeeFormSlice,
	TwoYearsFullTimeEmployeeHistoryFormSlice,
} from "../FullTimeEmployee.Schema";
import { DatePickerInput } from "../../../../../date-picker-input/DatePickerInput";
import GenericInputTeriary from "../../../../../generic-input-teriary/GenericInputTeriary";
import {
	calculateTotalYears,
	convertDate,
} from "../../../../../../utils/helpers";
import RadioGroupSecondary from "../../../../../radio-group-secondary/RadioGroupSecondary";
import {
	MonthsOptions,
	ProbationOptions,
	YearsOptions,
} from "../../../../../../utils/BootList";
import SelectInputSecondary from "../../../../../select-input-secondary/SelectInputSecondary";

type EmploymentHistoryErrorType =
	FieldErrors<TwoYearsFullTimeEmployeeHistoryFormSlice>;

const FullTimeEmployeeHistoryDisplay = ({
	index,
	employmentHistory,
	employmentType,
	onChangeHandler,
	removeHandler,
	errors,
}: {
	index: number;
	employmentHistory: FullTimeEmployeeFormSlice;
	employmentType: number;
	onChangeHandler: (value: FullTimeEmployeeFormSlice, index: number) => void;
	removeHandler: (value: number) => void;
	errors?: EmploymentHistoryErrorType;
}) => {
	return (
		<div className="flex flex-col gap-9">
			{index === 0 && (
				<>
					<GenericInputTeriary
						type="text"
						label="Employer Name"
						placeholder="Employer Name"
						value={employmentHistory.employerName}
						onChangeHandler={(value) => {
							const updateEmploymentHistory = {
								...employmentHistory,
							};
							updateEmploymentHistory.employerName = value.toString();
							onChangeHandler(updateEmploymentHistory, index);
						}}
						error={
							errors?.employmentHistory &&
							errors?.employmentHistory[index]?.employerName &&
							errors?.employmentHistory[index]?.employerName?.message
						}
					/>
					<GenericInputTeriary
						type="text"
						label="Job Title"
						placeholder="Job Title"
						value={employmentHistory.jobTitle}
						onChangeHandler={(value) => {
							const updateEmploymentHistory = {
								...employmentHistory,
							};
							updateEmploymentHistory.jobTitle = value.toString();
							onChangeHandler(updateEmploymentHistory, index);
						}}
						error={
							errors?.employmentHistory &&
							errors?.employmentHistory[index]?.jobTitle &&
							errors?.employmentHistory[index]?.jobTitle?.message
						}
					/>
					<DatePickerInput
						name="jobStartDate"
						onChange={(value) => {
							const updateEmploymentHistory = {
								...employmentHistory,
							};
							updateEmploymentHistory.jobStartDate = value!;
							onChangeHandler(updateEmploymentHistory, index);
						}}
						value={employmentHistory.jobStartDate}
						label="When did you start this role?"
						error={
							errors?.employmentHistory &&
							errors?.employmentHistory[index]?.jobStartDate &&
							errors?.employmentHistory[index]?.jobStartDate?.message
						}
						maxDate={new Date()}
					/>
					{!!employmentHistory.jobStartDate &&
						employmentHistory.jobStartDate !== "" &&
						calculateTotalYears(
							new Date(convertDate(employmentHistory.jobStartDate))
						) < 1 &&
						employmentType !== 3 && (
							<>
								<RadioGroupSecondary
									radioOptions={ProbationOptions}
										legend="Are you on probation?"
										selectedValue={employmentHistory.inProbation}
										onChange={(value) => {
											const updateEmploymentHistory = {
												...employmentHistory,
											};
											updateEmploymentHistory.inProbation = value.toString();
											onChangeHandler(updateEmploymentHistory, index);
										}}
										error={
											errors?.employmentHistory &&
											errors?.employmentHistory[index]?.inProbation &&
											errors?.employmentHistory[index]?.inProbation?.message
									}
								/>

								<>
									<h4 className="text-primary text-[21.33px] font-normal">
										How long have you worked in the same industry?
									</h4>
									<div className="flex flex-col md:flex-row gap-4">
										<SelectInputSecondary
											value={
												employmentHistory.industryYears || {
													id: 0,
													value: "",
													label: "Years",
												}
											}
											label=""
											options={YearsOptions}
											onChangeHandler={(value) => {
												const updateEmploymentHistory = {
													...employmentHistory,
												};
												updateEmploymentHistory.industryYears = value;
												onChangeHandler(updateEmploymentHistory, index);
											}}
											error={
												errors?.employmentHistory &&
												errors?.employmentHistory[index]?.industryYears &&
												errors?.employmentHistory[index]?.industryYears?.message
											}
										/>
										<SelectInputSecondary
											value={
												employmentHistory.industryMonths || {
													id: 0,
													value: "",
													label: "Months",
												}
											}
											label=""
											options={MonthsOptions}
											onChangeHandler={(value) => {
												const updateEmploymentHistory = {
													...employmentHistory,
												};
												updateEmploymentHistory.industryMonths = value;
												onChangeHandler(updateEmploymentHistory, index);
											}}
											error={
												errors?.employmentHistory &&
												errors?.employmentHistory[index]?.industryMonths &&
												errors?.employmentHistory[index]?.industryMonths
													?.message
											}
										/>
									</div>
								</>
							</>
						)}
				</>
			)}
			{index > 0 && (
				<>
					<h1
						className="text-primary text-[37.9px] font-normal"
						aria-labelledby="Previous Employer"
					>
						Previous Employer
					</h1>
					<GenericInputTeriary
						type="text"
						label="Employer Name"
						placeholder="Employer Name"
						value={employmentHistory.employerName}
						onChangeHandler={(value) => {
							const updateEmploymentHistory = {
								...employmentHistory,
							};
							updateEmploymentHistory.employerName = value.toString();
							onChangeHandler(updateEmploymentHistory, index);
						}}
						error={
							errors?.employmentHistory &&
							errors?.employmentHistory[index]?.employerName &&
							errors?.employmentHistory[index]?.employerName?.message
						}
					/>
					<GenericInputTeriary
						type="text"
						label="Job Title"
						placeholder="Job Title"
						value={employmentHistory.jobTitle}
						onChangeHandler={(value) => {
							const updateEmploymentHistory = {
								...employmentHistory,
							};
							updateEmploymentHistory.jobTitle = value.toString();
							onChangeHandler(updateEmploymentHistory, index);
						}}
						error={
							errors?.employmentHistory &&
							errors?.employmentHistory[index]?.jobTitle &&
							errors?.employmentHistory[index]?.jobTitle?.message
						}
					/>
					<DatePickerInput
						name="jobStartDate"
						onChange={(value) => {
							const updateEmploymentHistory = {
								...employmentHistory,
							};
							updateEmploymentHistory.jobStartDate = value!;
							onChangeHandler(updateEmploymentHistory, index);
						}}
						value={employmentHistory.jobStartDate}
						label="When did you start this role?"
						error={
							errors?.employmentHistory &&
							errors?.employmentHistory[index]?.jobStartDate &&
							errors?.employmentHistory[index]?.jobStartDate?.message
						}
						maxDate={new Date()}
					/>
					<DatePickerInput
						name="jobEndDate"
						onChange={(value) => {
							const updateEmploymentHistory = {
								...employmentHistory,
							};
							updateEmploymentHistory.jobEndDate = value!;
							onChangeHandler(updateEmploymentHistory, index);
						}}
						value={employmentHistory.jobEndDate}
						label="When did you finish?"
						error={
							errors?.employmentHistory &&
							errors?.employmentHistory[index]?.jobEndDate &&
							errors?.employmentHistory[index]?.jobEndDate?.message
						}
						maxDate={new Date()}
					/>
				</>
			)}
			<div className="flex flex-col md:flex-row gap-4">
				{index !== 0 && (
					<Button
						text="Remove"
						variant="plain"
						type="button"
						iconSuffix={<i className="icon-delete" />}
						handleClick={() => {
							removeHandler(index);
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default FullTimeEmployeeHistoryDisplay;
