import { type FC, useMemo } from "react";
import HintCard from "../hint-card/HintCard";
import CircularProgressBar from "../circular-progress-bar/CircularProgressBar";
import { useAppSelector } from "../../../hooks/redux-hooks";
import {
	selectCurrentEmploymentType,
	selectTotalEmploymentHistory,
} from "../../../store/slices/form-slice";

interface EmploymentHistoryHintParameters {
	percentage?: number;
	totalMonths?: number;
}
export const EmploymentHistoryHint: FC<EmploymentHistoryHintParameters> = ({
	percentage: percentageParameter,
	totalMonths: totalMonthsParameter,
}) => {
	const totalMonthsEmploymentHistory = useAppSelector(
		selectTotalEmploymentHistory
	);
	const isContractEmployee =
		useAppSelector(selectCurrentEmploymentType)?.id === 3;

	const isSelfEmployed = useAppSelector(selectCurrentEmploymentType)?.id === 5;

	const percentageEmploymentHistory = (totalMonthsEmploymentHistory / 24) * 100;

	const percentage = useMemo(
		() =>
			percentageParameter === undefined
				? percentageEmploymentHistory
				: percentageParameter,
		[percentageEmploymentHistory, percentageParameter]
	);
	const totalMonths = useMemo(
		() =>
			totalMonthsParameter === undefined
				? totalMonthsEmploymentHistory
				: totalMonthsParameter,
		[totalMonthsEmploymentHistory, totalMonthsParameter]
	);

	return (
		<HintCard
			icon={
				!isSelfEmployed && (
					<CircularProgressBar
						percentage={percentage >= 100 ? 100 : percentage}
					/>
				)
			}
			text={
				<>
					{!isSelfEmployed && (
						<>
							<div>
								<span
									className={`${totalMonths >= 24 && "text-grass-hopper-900"}`}
								>
									{totalMonths}/
								</span>
								24 months
							</div>

							<span className="font-medium text-sm leading-6">
								We'll need your employment history for the past 24 months,
								including all places you have worked during that period.
							</span>
						</>
					)}
					{isContractEmployee && (
						<span className="font-medium text-sm leading-6">
							Contractors require a minimum of 3 months on their contract at the
							time of settlement
						</span>
					)}
					{isSelfEmployed && (
						<span className="font-medium text-sm leading-6">
							Self-Employed applicants must have had 2 years of prior trading.
						</span>
					)}
				</>
			}
		/>
	);
};
