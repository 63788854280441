/* eslint-disable camelcase */

import { z } from "zod";
import { createSelector } from "@reduxjs/toolkit";
import { store, type RootState } from "../../../../store";

import {
	MaritalStatus,
	ResidencyStatus,
	type ExitStrategy} from "../../../../services/apis/create-application.schema";
import { isLoanTermValid } from "../../../utils/helpers";
import { selectUseOrigination } from "../../../../store/slices/origination-slice";
const validVisaErrorMessage = "Please enter a valid visa subclass";

export const JointBorrowerAboutYouFormSchema = z
	.object({
		birthDate: z
			.string({
				invalid_type_error: "This field is required",
				required_error: "This field is required",
			})
			.min(1, "This field is required"),
		loanTermInYears: z.coerce.number().optional(),
		exitStrategy: z.string().array().optional(),
		exitStrategyOther: z.string().optional(),
		exitStrategyDetails: z.string().optional(),
		residencyStatus: z.object({
			id: z.number(),
			label: z.string(),
			value: z.nativeEnum(ResidencyStatus),
		}),
		maritalStatus: z.object({
			id: z.number(),
			label: z.string(),
			value: z.nativeEnum(MaritalStatus)
		}).optional(),
		visaSubclass: z.number().nullable().optional()
		
	})
	.superRefine(
		(
			{
				birthDate,
				exitStrategy,
				exitStrategyOther,
				exitStrategyDetails,
				loanTermInYears,
				residencyStatus,
				visaSubclass,
				maritalStatus,
			},
			context
		) => {
			if (!residencyStatus || residencyStatus.id === 0) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Please select your residency status`,
					path: ["residencyStatus"],
				});
			}
			const useOrigination = selectUseOrigination(store.getState());
			if (useOrigination && (maritalStatus?.id === 0 || !maritalStatus)) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: "Please select your marital status",
					path: ["maritalStatus"],
				});
			}

			if (
				residencyStatus &&
				[ResidencyStatus.OtherVisa, ResidencyStatus.PermanentResident].includes(
					residencyStatus.value as ResidencyStatus
				) &&
				!visaSubclass
			) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: validVisaErrorMessage,
					path: ["visaSubclass"],
				});
			}
			if (
				birthDate &&
				loanTermInYears &&
				!isLoanTermValid(birthDate, Number(loanTermInYears))
			) {
				if (!exitStrategy || exitStrategy.length === 0) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please select at least one exit strategy`,
						path: ["exitStrategy"],
					});
				}
				if (
					exitStrategy?.includes("Other") &&
					(!exitStrategyOther || exitStrategyOther.length === 0)
				) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please describe your exit strategy`,
						path: ["exitStrategyOther"],
					});
				}
				if (!exitStrategyDetails || exitStrategyDetails.length === 0) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please explain your exit strategy`,
						path: ["exitStrategyDetails"],
					});
				}
			}
		}
	);

export type JointBorrowerAboutYouFormType = z.infer<
	typeof JointBorrowerAboutYouFormSchema
>;

export type JointBorrowerAboutYouFormSlice = {
	birthDate: string;
	exitStrategy?: Array<ExitStrategy>;
	exitStrategyOther?: string;
	exitStrategyDetails?: string;
	residencyStatus: {
		id: number;
		label: string;
		value: ResidencyStatus;
	};
	visaSubclass?: number;
	loanTermInYears?: number;
	maritalStatus: {
		id: number;
		label: string;
		value: MaritalStatus;
	};
};

export const selectJointBorrowerAboutYou = createSelector(
	(state: RootState) => state.form,
	(form) => ({
		birthDate: form.birthDate,
		exitStrategy: form.exitStrategy,
		exitStrategyOther: form.exitStrategyOther,
		exitStrategyDetails: form.exitStrategyDetails,
		residencyStatus: form.residencyStatus,
		visaSubclass: form.visaSubclass,
		loanTermInYears: form.loanTermInYears,
		maritalStatus: form.maritalStatus,
	})
);
