import { useCallback } from "react";
import { captureException } from "../services/sentry";
import { useResetAuth } from "./use-reset-auth";

const API_ERROR_STATUS = new Set([400, 500, 502, 503]);
const API_NON_POSITIVE_STATUS = new Set([200, 201, 204]);



export const useOnResponseStatus = () => {
    const resetAuth = useResetAuth();

    return useCallback(async (response: Response, url: string, init: RequestInit | undefined) => {
        const { status } = response;
        const headers = new Headers(init?.headers);

        if (status === 401 && headers.has("Authorization") && !url?.includes("/user.userDetails")) {
            // Only reset state if we're providing authorization info to begin with
            // Else, we're trying to access something we don't have access to yet
            resetAuth()
        }

        if (!API_ERROR_STATUS.has(status) || API_NON_POSITIVE_STATUS.has(status)) {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data = await response.clone().json() as Record<string, any>;
        const error: Error & { data?: unknown, status?: number } = new Error(
          data?.['error']?.json?.message ?? API_ERROR_STATUS.has(status)
            ? `API error: ${status}` : `Non-positive API response: ${status}`);

        error.data = data;
        error.status = status;
        captureException(error, {extra: {data}});
        throw error;
    }, [resetAuth]);
}
