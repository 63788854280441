import { useForm } from "react-hook-form";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import {
	selectCouldBeEligibleForFHB,
	setDependantAges,
	setisRelationStatus,
	setMaritalStatus,
	setNumberOfDependants,
	setResidencyStatus,
	setVisaSubclass,
	setNonApplicantFinancialDetails,
	setExpectPRInThreeMonths,
} from "../../../../../store/slices/form-slice";
import {
	ResidencyStatusOptions,
	isRelationStatusToSecondaryBorrower,
} from "../../../../utils/BootList";
import GenericInput from "../../../generic-input/GenericInput";
import RadioGroup from "../../../radio-group/RadioGroup";
import SelectInput from "../../../select-input/SelectInput";
import DependentInfoForm from "../../../dependents/DependentInput";
import useScrollTopOnMount from "../../../../../hooks/use-scroll-top-on-mount";
import {
	SelectYouAndYourHouseholdForm,
	getYouAndYourHouseholdSchema,
	type YouAndYourHouseholdFormType,
} from "./YouAndYourHouseholdSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	ResidencyStatus,
	SubStep,
} from "../../../../../services/apis/create-application.schema";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import FormLayout from "../../../../ui/form/Form";
import { useMarkFormDirty } from "../../../../../hooks/use-mark-form-dirty";
import { maritalStatusOptions } from "./your-household.form-options";
import { useState, useEffect } from "react";
import Modal from "../../../../forms/modal/Modal";
import Button from "../../../../ui/button/Button";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import TextArea from "../../../text-area/TextArea";
import { isApprovedTemporaryVisa, isBridgingVisa } from "../../../../../lib/visa-helpers";
import { TransitionInput } from "../../../steps/your-finances/your-household-expenses/TransitionInput";

const DEFAULT_OPTION = {
	id: 0,
	value: "",
	label: "Select an option",
};

type RadioOption = {
	id: number;
	value: string;
	label: string;
	name: string;
	checked: boolean;
};

const prExpectationOptions: RadioOption[] = [
	{ id: 1, value: "true", label: "Yes", name: "expectPRInThreeMonths", checked: false },
	{ id: 2, value: "false", label: "No", name: "expectPRInThreeMonths", checked: false },
];

const YouAndYourHousehold = () => {
	useScrollTopOnMount();
	const dispatch = useAppDispatch();
	const formState = useAppSelector(SelectYouAndYourHouseholdForm);
	const [showInfoModal, setShowInfoModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);

	const isJoint = useAppSelector(
		(state) => state.form.applicationType === "Joint"
	);

	const formSchema = getYouAndYourHouseholdSchema(isJoint);

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors, isDirty },
	} = useForm<YouAndYourHouseholdFormType>({
		resolver: zodResolver(formSchema),
		defaultValues: formState,
	});

	useMarkFormDirty(isDirty);
	useTrackPageViewOnMount({
		page: "Your Details",
		subPage: "You & Your Household",
	});

	const couldBeEligigbleForFHB = useAppSelector((state) =>
		selectCouldBeEligibleForFHB(state, watch("residencyStatus")?.value as ResidencyStatus)
	);

	const maritalStatusFormValue = watch("maritalStatus");
	const maritalStatusValue = maritalStatusFormValue?.value;
	const isDefactoOrMarried =
		maritalStatusValue === "De-Facto" || maritalStatusValue === "Married";

	const residencyStatusFormValue = watch("residencyStatus");
	const visaSubclassValue = watch("visaSubclass");
	const nonApplicantFinancialDetails = watch("nonApplicantFinancialDetails");

	const shouldShowVisaSubclass = [
		ResidencyStatus.OtherVisa,
		ResidencyStatus.PermanentResident,
	].includes(residencyStatusFormValue?.value as ResidencyStatus);

	// Clear visa subclass and PR expectation when switching residency status
	useEffect(() => {
		if (residencyStatusFormValue?.value) {
			const status = residencyStatusFormValue.value;
			if (![ResidencyStatus.OtherVisa, ResidencyStatus.PermanentResident].includes(status as ResidencyStatus)) {
				dispatch(setVisaSubclass(null));
				dispatch(setExpectPRInThreeMonths(null));
				setValue('visaSubclass', null, { shouldValidate: true });
				setValue('expectPRInThreeMonths', null, { shouldValidate: true });
			}
		}
	}, [residencyStatusFormValue?.value, dispatch, setValue]);

	// Clear isRelationStatus when marital status changes
	useEffect(() => {
		if (maritalStatusValue) {
			dispatch(setisRelationStatus(""));
			setValue('isRelationStatus', "", { shouldValidate: true });
		}
	}, [maritalStatusValue, dispatch, setValue]);

	const shouldShowPRQuestion = isBridgingVisa(visaSubclassValue) || isApprovedTemporaryVisa(visaSubclassValue);

	const proceedToNextStep = () => {
		if (couldBeEligigbleForFHB) {
			dispatch(
				navigateToStep({
					step: "detailsStep",
					subStep: SubStep.FHBFederalGuarantee,
				})
			);
		} else {
			dispatch(
				navigateToStep({
					step: "loanStep",
					subStep: SubStep.CreateYourAccount,
				})
			);
		}
	};

	const handleModalConfirm = () => {
		if (!nonApplicantFinancialDetails?.trim()) {
			return;
		}
		
		dispatch(setNonApplicantFinancialDetails(nonApplicantFinancialDetails));
		setShowInfoModal(false);
		proceedToNextStep();
	};

	const saveData = (data: YouAndYourHouseholdFormType) => {
		if (isJoint && data.isRelationStatus === "false") {
			setShowErrorModal(true);
			return;
		}

		if (data.maritalStatus) {
			dispatch(setMaritalStatus(data.maritalStatus));
		}
		if (data.isRelationStatus) {
			dispatch(setisRelationStatus(data.isRelationStatus));
		}
		if (data.residencyStatus) {
			dispatch(setResidencyStatus({
				...data.residencyStatus,
				value: data.residencyStatus.value as ResidencyStatus
			}));
		}
		if (data.visaSubclass) {
			dispatch(setVisaSubclass(Number(data.visaSubclass)));
		}

		if (shouldShowPRQuestion) {
			if (!data.expectPRInThreeMonths) {
				return;
			}

			if (!isJoint && data.expectPRInThreeMonths === "false") {
				setShowErrorModal(true);
				return;
			}
		}

		if (!isJoint && data.maritalStatus && ['De-Facto', 'Married'].includes(data.maritalStatus.value)) {	
			setShowInfoModal(true);
			return;
		}

		data.expectPRInThreeMonths && dispatch(
			setExpectPRInThreeMonths(data.expectPRInThreeMonths)
		);

		proceedToNextStep();
	};

	return (
		<>
			{showErrorModal && (
				<Modal
					isOpen={showErrorModal}
					onClose={() => {
						setShowErrorModal(false);
					}}
					title={
						<div className="flex flex-col gap-4 md:gap-8 items-center text-[28.43px] font-medium leading-10 text-primary">
							<i className="icon-warning text-3xl md:text-[80px]" />
						</div>
					}
					content={
						<p className="text-sm text-primary text-center">
							{isJoint
								? "Currently, Sucasa only accepts joint applications where both borrowers are in a spousal relationship."
								: "Sucasa is only able to support home loans for permanent residents or those expecting to receive PR within 3 months."}
						</p>
					}
					actions={[
						{
							text: "Close",
							variant: "primary",
							handleClick: () => {
								setShowErrorModal(false);
							},
							type: "button",
							textAlign: "start",
							size: "fit",
						},
					]}
				/>
			)}

			{showInfoModal && (
				<Modal
					isOpen={showInfoModal}
					onClose={() => {
						setShowInfoModal(false);
					}}
					title={
						<div className="flex flex-col gap-4 md:gap-8 items-center text-[28.43px] font-medium leading-10 text-primary">
							<i className="icon-info text-3xl md:text-[80px]" />
						</div>
					}
					content={
						<div className="flex flex-col gap-4">
							<p className="text-sm text-primary text-center">
								Does your partner earn any income or have any outstanding
								liabilities?
							</p>
							<TextArea
								name="nonApplicantFinancialDetails"
								register={register}
								error={
									errors.nonApplicantFinancialDetails &&
									errors.nonApplicantFinancialDetails.message
								}
							/>
						</div>
					}
					actions={[
						{
							text: "Next",
							size: "full",
							variant: "primary",
							handleClick: handleModalConfirm,
							type: "button",
						},
					]}
				/>
			)}

			<FormLayout
				header={
					<h1 className="text-primary text-[37.9px] font-normal">
						You & Your Household
					</h1>
				}
				content={
					<div className="flex flex-col gap-9">
						<SelectInput
							name="maritalStatus"
							value={maritalStatusFormValue || DEFAULT_OPTION}
							label="Marital Status"
							options={maritalStatusOptions}
							register={register}
							error={errors.maritalStatus && errors.maritalStatus.message}
						/>
						{isJoint && isDefactoOrMarried && (
							<RadioGroup
								radioOptions={isRelationStatusToSecondaryBorrower}
								legend={`Are you ${maritalStatusValue === "De-Facto" ? "de-facto" : "married"} to the plus one on this application?`}
								register={register}
								error={
									errors.isRelationStatus && errors.isRelationStatus.message
								}
							/>
						)}
						<DependentInfoForm
							initialDependents={formState.dependantAges}
							onChange={(dependantAges) => {
								dispatch(setDependantAges(dependantAges));
								dispatch(setNumberOfDependants(dependantAges.length));
							}}
						/>
						<SelectInput
							name="residencyStatus"
							value={residencyStatusFormValue || DEFAULT_OPTION}
							label="What is your Australian residency status?"
							options={ResidencyStatusOptions}
							register={register}
							error={errors.residencyStatus && errors.residencyStatus.message}
						/>
						{shouldShowVisaSubclass && (
							<GenericInput
								name="visaSubclass"
								register={register}
								type="number"
								label="What is your visa subclass number?"
								placeholder="Visa type"
								error={errors.visaSubclass && errors.visaSubclass.message}
							/>
						)}
						<TransitionInput show={shouldShowPRQuestion}>
							<div className="flex flex-col gap-4">
								<RadioGroup<YouAndYourHouseholdFormType>
									name="expectPRInThreeMonths"
									radioOptions={prExpectationOptions}
									legend="Do you expect to get your PR within the next 3 months?"
									register={register}
									error={
										errors.expectPRInThreeMonths &&
										errors.expectPRInThreeMonths.message
									}
								/>
							</div>
						</TransitionInput>
					</div>
				}
				footer={
					<div
						className="flex items-center justify-between gap-4"
						aria-labelledby="Actions wrapper"
					>
						<Button
							text="Back"
							variant="accent"
							iconPrefix={<i className="icon-arrow rotate-180" />}
							handleClick={() => {
								dispatch(
									navigateToStep({
										step: "detailsStep",
										subStep: SubStep.TellUsAboutYou,
									})
								);
							}}
						/>
						<Button
							text="Next"
							variant="primary"
							iconSuffix={<i className="icon-arrow" />}
							handleClick={handleSubmit(saveData, (errors) => {
								console.log(errors);
							})}
						/>
					</div>
				}
			/>
		</>
	);
};

export default YouAndYourHousehold;
