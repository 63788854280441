import { useCallback, useState } from "react";
import Button from "../../../../../ui/button/Button";
import type { LiabilitiesFormSlice, LoanLiability } from "../YourLiabilitiesSchema";
import type { Control, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import GenericInputTeriary from "../../../../generic-input-teriary/GenericInputTeriary";
import CheckboxInputSecondary from "../../../../checkbox-input-secondary/CheckboxInputSecondary";
import SelectInputSecondary from "../../../../select-input-secondary/SelectInputSecondary";
import { YearsOptions } from "../../../../../utils/BootList";
import DollarInput from "../../../../dollar-input/DollarInput";

type LoanLiabilityErrorType = Merge<
	FieldError,
	Array<Merge<FieldError, FieldErrorsImpl<LoanLiability>> | undefined>
>;

type TSubFormName = "personalLoansLiabilities" | "vehicleLoansLiabilities";

const LoanLiabilityDisplay = ({
	index,
	loanLiability,
	loanAmountPlaceHolder = "Loan Amount",
	accountNamePlaceHolder = "Financial Instituion",
	subFormName,
	control,
	errors,
	onChangeHandler,
	removeHandler,
	showRemoveButton,
	showRemoveButtonTopRight,
	showDebtConsolidation,
}: {
	index: number;
	loanAmountPlaceHolder?: string;
	accountNamePlaceHolder?: string;
	loanLiability: LoanLiability;
	onChangeHandler: (value: LoanLiability) => void;
	removeHandler: (value: number) => void;
	showRemoveButton: boolean;
	showRemoveButtonTopRight?: boolean;
	subFormName: TSubFormName;
	control: Control<LiabilitiesFormSlice>;
	errors?: LoanLiabilityErrorType;
	showDebtConsolidation?: boolean;
}) => (
	<div className="w-full flex flex-col gap-4">
		{showRemoveButtonTopRight && showRemoveButton && (
		<div className="flex justify-between items-center">
			<h3 className="text-[21.33px] leading-8 font-medium">{`Loan ${index + 1}`}</h3>
			<Button
				text="Remove"
				variant="link"
				type="button"
				iconPrefix={<i className="icon-delete" />}
				handleClick={() => {
					removeHandler(index);
				}}
				/>
			</div>
		)}
		<GenericInputTeriary
			type="text"
			value={loanLiability.accountName}
			error={
				errors &&
				errors[index]?.accountName &&
				errors[index]?.accountName?.message
			}
			label=""
			placeholder={accountNamePlaceHolder}
			onChangeHandler={(value) => {
				const updateLoanLiability = {
					...loanLiability,
				};
				updateLoanLiability.accountName = value.toString();
				onChangeHandler(updateLoanLiability);
			}}
		/>
		<div className="flex flex-col md:flex-row gap-4">
			<DollarInput
				name={`${subFormName}.${index}.loanAmount`}
				control={control}
				type="numeric"
				value={loanLiability.loanAmount}
				error={
					errors &&
					errors[index]?.loanAmount &&
					errors[index]?.loanAmount?.message
				}
				label=""
				placeholder={loanAmountPlaceHolder}
				iconPrefix={<i className="icon-dollar" />}
				onValueChange={(value) => {
					const updateLoanLiability = {
						...loanLiability,
					};
					updateLoanLiability.loanAmount = value || 0;
					onChangeHandler(updateLoanLiability);
				}}
			/>
			<DollarInput
				name={`${subFormName}.${index}.interestRate`}
				control={control}
				type="decimal"
				value={loanLiability.interestRate}
				error={
					errors &&
					errors[index]?.interestRate &&
					errors[index]?.interestRate?.message
				}
				label=""
				placeholder="Interest Rate"
				iconSuffix={<i className="flex shrink-0">% p.a.</i>}
				onValueChange={(value) => {
					const updateLoanLiability = {
						...loanLiability,
					};
					updateLoanLiability.interestRate = value || 0;
					onChangeHandler(updateLoanLiability);
				}}
			/>
		</div>
		<div className="flex flex-col md:flex-row gap-4 items-end">
			<DollarInput
				name={`${subFormName}.${index}.monthlyRepayments`}
				control={control}
				type="numeric"
				value={loanLiability.monthlyRepayments}
				error={
					errors &&
					errors[index]?.monthlyRepayments &&
					errors[index]?.monthlyRepayments?.message
				}
				label=""
				placeholder="Monthly Repayment"
				iconPrefix={<i className="icon-dollar" />}
				onValueChange={(value) => {
					const updateLoanLiability = {
						...loanLiability,
					};
					updateLoanLiability.monthlyRepayments = value || 0;
					onChangeHandler(updateLoanLiability);
				}}
			/>
			<SelectInputSecondary
				value={
					loanLiability.remainingTermsInYears || {
						id: 0,
						value: "",
						label: "Select remaining term",
					}
				}
				label=""
				options={YearsOptions}
				onChangeHandler={(value) => {
					const updateLoanLiability = {
						...loanLiability,
					};
					updateLoanLiability.remainingTermsInYears = value;
					onChangeHandler(updateLoanLiability);
				}}
				error={
					errors &&
					errors[index]?.remainingTermsInYears &&
					errors[index]?.remainingTermsInYears?.message
				}
			/>
		</div>
		<CheckboxInputSecondary
			label="I’m open to closing this account to assist my borrowing capacity"
			name="openToClosing"
			value="openToClosing"
			size="small"
			checked={loanLiability.openToClosing!}
			onChange={(event) => {
				const updateLoanLiability = {
					...loanLiability,
				};
				updateLoanLiability.openToClosing = event.target.checked;
				onChangeHandler(updateLoanLiability);
			}}
		/>
		{showDebtConsolidation && (
			<CheckboxInputSecondary
				label="This debt is being consolidated."
				value="debtIsBeingConsolidated"
				name="debtIsBeingConsolidated"
				size="small"
				checked={loanLiability.debtIsBeingConsolidated!}
				onChange={(event) => {
					const updateLoanLiability = {
						...loanLiability,
					};
					updateLoanLiability.debtIsBeingConsolidated = event.target.checked;
					onChangeHandler(updateLoanLiability);
				}}
			/>
		)}
		{showRemoveButton && !showRemoveButtonTopRight && (
			<Button
				text="Remove"
				variant="plain"
				type="button"
				iconSuffix={<i className="icon-remove" />}
				handleClick={() => {
					removeHandler(index);
				}}
			/>
		)}
	</div>
);
const emptyLoanLiability = {} as LoanLiability;
const LoanLiabilitiesForm = ({
	initialLoanLiabilities,
	loanAmountPlaceHolder = "Loan Amount",
	accountNamePlaceHolder = "Financial Instituion",
	showRemoveButton = true,
	addButtonLabel,
	onChange,
	subFormName,
	control,
	errors,
	showDebtConsolidation,
	showRemoveButtonTopRight,
}: {
	initialLoanLiabilities?: Array<LoanLiability>;
	loanAmountPlaceHolder?: string;
	accountNamePlaceHolder?: string;
	showRemoveButton?: boolean;
	addButtonLabel?: string;
	subFormName: TSubFormName;
	control: Control<LiabilitiesFormSlice>;
	errors?: LoanLiabilityErrorType;
	onChange: (loanLiabilities: Array<LoanLiability>) => void;
	showDebtConsolidation?: boolean;
	showRemoveButtonTopRight?: boolean;
}) => {
	const [loanLiabilities, setLoanLiabilities] = useState<Array<LoanLiability>>(
		initialLoanLiabilities ?? []
	);

	const setLoanLiabilitiesHandler = useCallback((updateLoanLiabilities: LoanLiability[]) => {
		setLoanLiabilities(updateLoanLiabilities);
		onChange(updateLoanLiabilities);
	}, [onChange]);

	const handleChange = (index: number, loanLiability: LoanLiability) => {
		const updateLoanLiabilities = [...loanLiabilities];
		updateLoanLiabilities[index] = { ...loanLiability };
		setLoanLiabilitiesHandler(updateLoanLiabilities);
	};

	const removeHandler = (index: number) => {
		const updateLoanLiabilities = [...loanLiabilities];
		updateLoanLiabilities.splice(index, 1);
		setLoanLiabilitiesHandler(updateLoanLiabilities);
	};
	return (
		<section className="flex flex-col gap-4">
			{loanLiabilities.map((loanLiability, index) => (
				<LoanLiabilityDisplay
					loanAmountPlaceHolder={loanAmountPlaceHolder}
					accountNamePlaceHolder={accountNamePlaceHolder}
					key={index}
					index={index}
					subFormName={subFormName}
					control={control}
					errors={errors}
					loanLiability={loanLiability}
					onChangeHandler={(value: LoanLiability) => {
						handleChange(index, value);
					}}
					removeHandler={removeHandler}
					showRemoveButton={showRemoveButton}
					showRemoveButtonTopRight={showRemoveButtonTopRight}
					showDebtConsolidation={showDebtConsolidation}
				/>
			))}
			{Boolean(addButtonLabel) && <Button
				text={
					<span>
						<i className="icon-plus" />
						{addButtonLabel}
					</span>
				}
				type="button"
				size="fit"
				variant="accent"
				textAlign="center"
				handleClick={() => {
					const updateLoanLiabilities = [
						...loanLiabilities,
						{
							...emptyLoanLiability,
							openToClosing: false,
							debtIsBeingConsolidated: false,
						},
					];
					setLoanLiabilitiesHandler(updateLoanLiabilities);
				}}
			/>}
		</section>
	);
};

export default LoanLiabilitiesForm;
