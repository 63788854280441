import { type FC, type ReactNode, Fragment } from "react";
import { Transition } from "@headlessui/react";

type TransitionInputProps = {
	show: boolean;
	children: ReactNode;
};
export const TransitionInput: FC<TransitionInputProps> = ({
	show,
	children,
}) => {
	return (
		<Transition
			show={show}
			as={Fragment}
			enter="transition ease-in-out duration-300 transform delay-150"
			enterFrom="opacity-0 -translate-y-4"
			enterTo="opacity-100 translate-y-0"
			leave="transition ease-in-out duration-200 transform"
			leaveFrom="opacity-100 translate-y-0"
			leaveTo="opacity-0 -translate-y-4"
		>
			{children}
		</Transition>
	);
};
