import { useEffect, useState } from "react";
import Button from "../../../../../ui/button/Button";
import type { CreditCardLiability, LiabilitiesFormSlice } from "../YourLiabilitiesSchema";
import type { Control, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import GenericInputTeriary from "../../../../generic-input-teriary/GenericInputTeriary";
import CheckboxInputSecondary from "../../../../checkbox-input-secondary/CheckboxInputSecondary";
import DollarInput from "../../../../dollar-input/DollarInput";

type CreditCardLiabilityErrorType = Merge<
	FieldError,
	Array<Merge<FieldError, FieldErrorsImpl<CreditCardLiability>> | undefined>
>;

const CreditCardLiabilityDisplay = ({
	index,
	creditCardLiability,
	control,
	errors,
	onChangeHandler,
	removeHandler,
	showDebtConsolidation,
}: {
	index: number;
	creditCardLiability: CreditCardLiability;
	onChangeHandler: (value: CreditCardLiability) => void;
	removeHandler: (value: number) => void;
	control: Control<LiabilitiesFormSlice>;
	errors?: CreditCardLiabilityErrorType;
	showDebtConsolidation: boolean;
}) => (
	<div className="w-full flex flex-col gap-4">
		<GenericInputTeriary
			type="text"
			value={creditCardLiability.accountName}
			error={
				errors &&
				errors[index]?.accountName &&
				errors[index]?.accountName?.message
			}
			label=""
			placeholder="Financial Instituion"
			onChangeHandler={(value) => {
				const updateCreditCardLiability = {
					...creditCardLiability,
				};
				updateCreditCardLiability.accountName = value.toString();
				onChangeHandler(updateCreditCardLiability);
			}}
		/>
		<div className="flex flex-col md:flex-row gap-4">
			<DollarInput
				name={`creditCardLiabilities.${index}.cardLimit`}
				control={control}
				type="numeric"
				value={creditCardLiability.cardLimit}
				error={
					errors &&
					errors[index]?.cardLimit &&
					errors[index]?.cardLimit?.message
				}
				label=""
				placeholder="Card Limit"
				iconPrefix={<i className="icon-dollar" />}
				onValueChange={(value) => {
					const updateCreditCardLiability = {
						...creditCardLiability,
					};
					updateCreditCardLiability.cardLimit = value || 0;
					onChangeHandler(updateCreditCardLiability);
				}}
			/>
			<DollarInput
				name={`creditCardLiabilities.${index}.currentBalance`}
				control={control}
				type="numeric"
				value={creditCardLiability.currentBalance}
				error={
					errors &&
					errors[index]?.currentBalance &&
					errors[index]?.currentBalance?.message
				}
				label=""
				placeholder="Current Balance"
				iconPrefix={<i className="icon-dollar" />}
				onValueChange={(value) => {
					const updateCreditCardLiability = {
						...creditCardLiability,
					};
					updateCreditCardLiability.currentBalance = value || 0;
					onChangeHandler(updateCreditCardLiability);
				}}
			/>
		</div>
		<CheckboxInputSecondary
			label="I’m open to closing this account to assist my borrowing capacity"
			name="openToClosing"
			value="openToClosing"
			size="small"
			checked={creditCardLiability.openToClosing!}
			onChange={(event) => {
				const updateCreditCardLiability = {
					...creditCardLiability,
				};
				updateCreditCardLiability.openToClosing = event.target.checked;
				onChangeHandler(updateCreditCardLiability);
			}}
		/>
		{showDebtConsolidation && (
			<CheckboxInputSecondary
				label="This debt is being consolidated."
				value="debtIsBeingConsolidated"
				name="debtIsBeingConsolidated"
				size="small"
				checked={creditCardLiability.debtIsBeingConsolidated!}
				onChange={(event) => {
					const updateCreditCardLiability = {
						...creditCardLiability,
					};
					updateCreditCardLiability.debtIsBeingConsolidated = event.target.checked;
					onChangeHandler(updateCreditCardLiability);
				}}
			/>
		)}
		<Button
			text="Remove"
			variant="plain"
			type="button"
			iconSuffix={<i className="icon-remove" />}
			handleClick={() => {
				removeHandler(index);
			}}
		/>
	</div>
);
const emptyCreditCardLiability = {} as CreditCardLiability;
const CreditCardLiabilitiesForm = ({
	initialCreditCardLiabilities,
	onChange,
	control,
	errors,
	showDebtConsolidation,
}: {
	initialCreditCardLiabilities?: Array<CreditCardLiability>;
	control: Control<LiabilitiesFormSlice>;
	errors?: CreditCardLiabilityErrorType;
	onChange: (creditCardLiabilities: Array<CreditCardLiability>) => void;
	showDebtConsolidation: boolean;
}) => {
	const [creditCardLiabilities, setCreditCardLiabilities] = useState<
		Array<CreditCardLiability>
	>(initialCreditCardLiabilities ?? []);

	const handleChange = (
		index: number,
		creditCardLiability: CreditCardLiability
	) => {
		const updateCreditCardLiabilities = [...creditCardLiabilities];
		updateCreditCardLiabilities[index] = { ...creditCardLiability };
		setCreditCardLiabilities(updateCreditCardLiabilities);
	};

	useEffect(() => {
		onChange(creditCardLiabilities);
	}, [creditCardLiabilities, onChange]);

	const removeHandler = (index: number) => {
		const updateCreditCardLiabilities = [...creditCardLiabilities];
		updateCreditCardLiabilities.splice(index, 1);
		setCreditCardLiabilities(updateCreditCardLiabilities);
	};
	return (
		<section className="flex flex-col gap-4">
			{creditCardLiabilities.map((creditCardLiability, index) => (
				<CreditCardLiabilityDisplay
					key={index}
					index={index}
					control={control}
					errors={errors}
					creditCardLiability={creditCardLiability}
					onChangeHandler={(value: CreditCardLiability) => {
						handleChange(index, value);
					}}
					removeHandler={removeHandler}
					showDebtConsolidation={showDebtConsolidation}
				/>
			))}
			<Button
				text={
					<span>
						<i className="icon-plus" />
						Add card
					</span>
				}
				type="button"
				size="fit"
				variant="accent"
				textAlign="center"
				handleClick={() => {
					const updateCreditCardLiabilities = [
						...creditCardLiabilities,
						{
							...emptyCreditCardLiability,
							openToClosing: false,
							debtIsBeingConsolidated: false,
						},
					];
					setCreditCardLiabilities(updateCreditCardLiabilities);
				}}
			/>
		</section>
	);
};

export default CreditCardLiabilitiesForm;
