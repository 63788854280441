import {
    ApplicationBorrowerType,
    PropertyPurpose as TargetPropertyPurpose,
    LoanPurpose as TargetLoanPurpose,
    RequestedLoanFeatures,
    StageOfPurchase,
    LoanProductPreference,
    MaritalStatus as TargetMaritalStatus,
    CreditCheckPreference as TargetCreditCheckPreference,
    type SelectApplication,
    type ApplicationUser,
    LiabilityType,
    ExitStrategy as TargetExitStrategy,
    LivingSituation,
    IncomeType as TargetIncomeType,
    FrequencyType as TargetFrequencyType,
    EmploymentType as TargetEmploymentType,
    ExpenseType,
    ExpenseName,
    ApplicationStep,
    ApplicationSubStep,
    PropertyType as TargetPropertyType,
    PostSettlementPlan,
} from "@sucasa-finance/origination-trpc";
import {
    ApplicationType,
    PropertyPurpose,
    LoanPurpose,
    LoanFeature,
    BorrowerStage,
    LoanPreference,
    MaritalStatus,
    CreditCheckPreference,
    LoanOnPropertyType,
    ExitStrategy,
    Step,
    SubStep,
    PropertyType,
} from "../../create-application.schema";
import { IncomeType, FrequencyType, EmploymentType } from '../../update-income.schema';
import type { UpdateExpensesRequestKey } from '../../update-expenses.schema';

export interface CreateOriginationApplicationResponse {
    application: Omit<SelectApplication, "deletedAt"> & {
        applicants: Array<Omit<ApplicationUser, "deletedAt" | "exitStrategy"> & {
            exitStrategy: Array<string> | null
        }>;
    };
    access_token: string;
}

function reverseMapping<A extends string | number | symbol, B extends string | number | symbol>(mapping: Record<A, B | undefined>): Record<B, A> {
    return Object.fromEntries(Object.entries(mapping).filter(([, value]) => Boolean(value)).map(([key, value]) => [value, key] as const));
}


export const applicationTypeMapping: Record<ApplicationType, ApplicationBorrowerType> = {
    [ApplicationType.Single]: ApplicationBorrowerType.Individual,
    [ApplicationType.Joint]: ApplicationBorrowerType.Joint
}
export const reverseApplicationTypeMapping = reverseMapping(applicationTypeMapping);

export const applicationBorrowerTypeMapping: Record<ApplicationBorrowerType, ApplicationType> = {
    [ApplicationBorrowerType.Individual]: ApplicationType.Single,
    [ApplicationBorrowerType.Joint]: ApplicationType.Joint
}

export const loanPurposeMapping: Record<LoanPurpose, TargetLoanPurpose | undefined> = {
    [LoanPurpose.Empty]: undefined,
    [LoanPurpose.Purchase]: TargetLoanPurpose.Purchase,
    [LoanPurpose.Refinance]: TargetLoanPurpose.Refinance
}
export const reverseLoanPurposeMapping = reverseMapping(loanPurposeMapping);

export const propertyPurposeMapping: Record<PropertyPurpose, TargetPropertyPurpose> = {
    [PropertyPurpose.Investment]: TargetPropertyPurpose.Investment,
    [PropertyPurpose.LiveIn]: TargetPropertyPurpose.LiveIn
}
export const reversePropertyPurposeMapping = reverseMapping(propertyPurposeMapping);

export const featuresMapping: Record<LoanFeature, RequestedLoanFeatures> = {
    [LoanFeature.FixedRate]: RequestedLoanFeatures.FixedRate,
    [LoanFeature.GuarantorLoan]: RequestedLoanFeatures.GuarantorLoan,
    [LoanFeature.InterestOnly]: RequestedLoanFeatures.InterestOnly,
    [LoanFeature.OffsetAccount]: RequestedLoanFeatures.OffsetAccount,
    [LoanFeature.Other]: RequestedLoanFeatures.Other
}
export const reverseFeaturesMapping = reverseMapping(featuresMapping);

export const stageMapping: Record<BorrowerStage, StageOfPurchase> = {
    [BorrowerStage.AnyDayNow]: StageOfPurchase.AnyDayNow,
    [BorrowerStage.Signed]: StageOfPurchase.Signed,
    [BorrowerStage.Threemonths]: StageOfPurchase.Threemonths,
    [BorrowerStage.Under3months]: StageOfPurchase.Under3months
}
export const reverseStageMapping = reverseMapping(stageMapping);

export const loanProductPreferenceMapping: Record<LoanPreference, LoanProductPreference> = {
    [LoanPreference.LowerInterestRate]: LoanProductPreference.LowerInterestRate,
    [LoanPreference.LowerUpfrontFees]: LoanProductPreference.LowerUpfrontFees,
    [LoanPreference.NoPreference]: LoanProductPreference.NoPreference
}
export const reverseLoanProductPreferenceMapping = reverseMapping(loanProductPreferenceMapping);

export const maritalStatusMapping: Record<MaritalStatus, TargetMaritalStatus> = {
    [MaritalStatus.DeFacto]: TargetMaritalStatus.DeFacto,
    [MaritalStatus.Divorced]: TargetMaritalStatus.Divorced,
    [MaritalStatus.Married]: TargetMaritalStatus.Married,
    [MaritalStatus.Seperated]: TargetMaritalStatus.Separated,
    [MaritalStatus.Single]: TargetMaritalStatus.Single
}
export const reverseMaritalStatusMapping = reverseMapping(maritalStatusMapping);

export const creditCheckPreferenceMapping: Record<CreditCheckPreference, TargetCreditCheckPreference> = {
    [CreditCheckPreference.AtSubmission]: TargetCreditCheckPreference.AtSubmission,
    [CreditCheckPreference.CallMeFirst]: TargetCreditCheckPreference.CallMeFirst
};
export const reverseCreditCheckPreferenceMapping = reverseMapping(creditCheckPreferenceMapping);

export const loanOnPropertyTypeMapping: Record<LoanOnPropertyType, LiabilityType> = {
    [LoanOnPropertyType.Fixed]: LiabilityType.FixedLoan,
    [LoanOnPropertyType.Variable]: LiabilityType.VariableLoan
}
export const reverseLoanOnPropertyTypeMapping = reverseMapping(loanOnPropertyTypeMapping);

// Typed mapping ensures we have mapped all of our source enum to the target enum,
// see next common for why we don't export this directly
const exitStrategyTypedMapping: Record<ExitStrategy, TargetExitStrategy> = {
    [ExitStrategy.ByContinuingToWork]: TargetExitStrategy.ByContinuingToWork,
    [ExitStrategy.ByDownsizingMyHome]: TargetExitStrategy.ByDownsizingMyHome,
    [ExitStrategy.ByReducingMyExpenses]: TargetExitStrategy.ByReducingMyExpenses,
    [ExitStrategy.BySaleOfMyAssets]: TargetExitStrategy.BySaleOfMyAssets,
    [ExitStrategy.ByUsingMySavings]: ExitStrategy.ByUsingMySavings,
    [ExitStrategy.BySecuringAdditionalIncome]: ExitStrategy.BySecuringAdditionalIncome,
    [ExitStrategy.ByUtilisingMySuperannuation]: ExitStrategy.ByUtilisingMySuperannuation,
    [ExitStrategy.Other]: ExitStrategy.Other
}

// We then can redefine the record using Record<Enum | string, TargetEnum> so that
// we can use any string source, if it was not a known enum, it can be ignored
export const exitStrategyMapping: Record<ExitStrategy | string, TargetExitStrategy> = exitStrategyTypedMapping;
export const reverseExitStrategyMapping = reverseMapping(exitStrategyTypedMapping);

// In this case the UI provides only a string and does not have a source enum
// The UI now uses the origination enum as a source string, meaning this is one to one.
// We will still utilise this record, so lets map it directly
// and not worried about the intermediate typed record like above
export const livingSituationMapping: Record<string, LivingSituation> = Object.fromEntries(
  Object.values(LivingSituation).map(value => [value, value])
)
export const reverseLivingSituationMapping = livingSituationMapping;

export const postSettlementPlanMapping: Record<string, PostSettlementPlan> = Object.fromEntries(
  Object.values(PostSettlementPlan).map(value => [value, value])
)

export const incomeTypeMapping: Record<IncomeType, TargetIncomeType> = {
    [IncomeType.EmploymentAndSalary]: TargetIncomeType.EmploymentAndSalary,
    [IncomeType.SelfEmployedBusinessOwner]: TargetIncomeType.SelfEmployedBusinessOwner,
    [IncomeType.CarAllowance]: TargetIncomeType.CarAllowance,
    [IncomeType.Commission]: TargetIncomeType.Commission,
    [IncomeType.Bonus]: TargetIncomeType.Bonus,
    [IncomeType.OtherIncome]: TargetIncomeType.OtherIncome,
    [IncomeType.SuperannuationAnnuities]: TargetIncomeType.SuperannuationAnnuities,
    [IncomeType.RentalIncomeResidential]: TargetIncomeType.RentalIncomeResidential,
    [IncomeType.InvestmentIncome]: TargetIncomeType.InvestmentIncome,
    [IncomeType.InterestIncome]: TargetIncomeType.InterestIncome,
    [IncomeType.GovernmentPension]: TargetIncomeType.GovernmentPension,
    [IncomeType.ContractIncomeFixed]: TargetIncomeType.ContractIncomeFixed,
    [IncomeType.ContractIncomeVariable]: TargetIncomeType.ContractIncomeVariable,
    [IncomeType.ChildSupport]: TargetIncomeType.ChildSupport,
    [IncomeType.CasualIncome]: TargetIncomeType.CasualIncome,
    [IncomeType.MaternityLeave]: TargetIncomeType.MaternityLeave,
    [IncomeType.Overtime]: TargetIncomeType.Overtime,
    [IncomeType.ShiftAllowance]: TargetIncomeType.ShiftAllowance,
    [IncomeType.OvertimeShiftAllowance]: TargetIncomeType.OvertimeShiftAllowance
}
export const reverseIncomeTypeMapping = reverseMapping(incomeTypeMapping);

export const incomeFrequencyTypeMapping: Record<FrequencyType, TargetFrequencyType> = {
    [FrequencyType.Monthly]: TargetFrequencyType.Monthly,
    [FrequencyType.Annually]: TargetFrequencyType.Annually,
    [FrequencyType.Weekly]: TargetFrequencyType.Weekly,
    [FrequencyType.Fortnightly]: TargetFrequencyType.Fortnightly,
    [FrequencyType.Other]: TargetFrequencyType.Other,
    [FrequencyType.Irregular]: TargetFrequencyType.Irregular
}
export const reverseIncomeFrequencyTypeMapping = reverseMapping(incomeFrequencyTypeMapping);

const employmentTypeBaseMapping: Record<EmploymentType, TargetEmploymentType> = {
    [EmploymentType.Casual]: TargetEmploymentType.Casual,
    // [EmploymentType.Clerical]: TargetEmploymentType.Clerical,
    // [EmploymentType.Executive]: TargetEmploymentType.Executive,
    // [EmploymentType.Government]: TargetEmploymentType.Government,
    // [EmploymentType.HourlyWageEarner]: TargetEmploymentType.HourlyWageEarner,
    // [EmploymentType.Life]: TargetEmploymentType.Life,
    // [EmploymentType.Manager]: TargetEmploymentType.Manager,
    // [EmploymentType.MilitaryEnlisted]: TargetEmploymentType.MilitaryEnlisted,
    // [EmploymentType.MilitaryOfficer]: TargetEmploymentType.MilitaryOfficer,
    // [EmploymentType.Professional]: TargetEmploymentType.Professional,
    // [EmploymentType.Retired]: TargetEmploymentType.Retired,
    // [EmploymentType.Sales]: TargetEmploymentType.Sales,
    // [EmploymentType.SelfEmployed]: TargetEmploymentType.SelfEmployed,
    // [EmploymentType.Service]: TargetEmploymentType.Service,
    // [EmploymentType.Student]: TargetEmploymentType.Student,
    // [EmploymentType.UnemployedWithIncome]: TargetEmploymentType.UnemployedWithIncome,
    // [EmploymentType.UnemployedWithoutIncome]: TargetEmploymentType.UnemployedWithoutIncome,
    [EmploymentType.FullTimeEmploymentPAYG]: TargetEmploymentType.FullTimeEmploymentPAYG,
    [EmploymentType.PartTimeEmploymentPAYG]: TargetEmploymentType.PartTimeEmploymentPAYG,
    [EmploymentType.Contractor]: TargetEmploymentType.Contractor,
    [EmploymentType.NotEmployed]: TargetEmploymentType.NotEmployed,
    [EmploymentType.SelfEmployedBusinessOwner]: TargetEmploymentType.SelfEmployedBusinessOwner
}

// Matching comments related to exitStrategyMapping, we want to use a string to lookup here
export const employmentTypeMapping: Record<EmploymentType | string, TargetEmploymentType> = employmentTypeBaseMapping;
export const reverseEmploymentTypeMapping = reverseMapping(employmentTypeBaseMapping);

export const expenseCategoryMapping: Record<UpdateExpensesRequestKey, ExpenseType> = {
    alimony: ExpenseType.AlimonyOrMaintenance,
    childcare: ExpenseType.Childcare,
    chronicHealthExpenses: ExpenseType.ChronicHealth,
    clothingAndPersonal: ExpenseType.Hem,
    education: ExpenseType.Hem,
    foodAndGroceries: ExpenseType.Hem,
    generalInsurance: ExpenseType.Hem,
    internetTVPhone: ExpenseType.Hem,
    lifeOrAccidentInsurance: ExpenseType.LifeInsurance,
    medicalAndHealth: ExpenseType.Hem,
    otherExpenses: ExpenseType.Other,
    privateHealthInsurance: ExpenseType.HealthAndAccidentInsurance,
    privateSchoolFees: ExpenseType.School,
    propertyExpenses: ExpenseType.Hem,
    recreationAndEntertainment: ExpenseType.Hem,
    rent: ExpenseType.Hem,
    transport: ExpenseType.Hem,
    counselling: ExpenseType.CounsellingServices,
}
export const reverseExpenseCategoryMapping = reverseMapping(expenseCategoryMapping);
const updateExpensesRequestKeys = Object.keys(expenseCategoryMapping);

// The ExpenseName enum was based directly off the frontend values, inherently the values are the same
export const expenseNameMapping: Record<string, ExpenseName> = Object.fromEntries(
  Object.values(ExpenseName).map(value => [value, value] as const)
)

export function isUpdateExpensesRequestKey(value: string): value is UpdateExpensesRequestKey {
    return updateExpensesRequestKeys.includes(value);
}

const applicationStepBaseMapping: Record<Step, ApplicationStep> = {
    [Step.Confirmation]: ApplicationStep.Confirmation,
    [Step.Submitted]: ApplicationStep.Submitted,
    [Step.YourDetails]: ApplicationStep.Details,
    [Step.YourFinances]: ApplicationStep.Finances
}
export const applicationStepMapping: Record<Step | string, ApplicationStep> = applicationStepBaseMapping;
export const reverseApplicationStepMapping = reverseMapping(applicationStepBaseMapping);

const applicationSubStepBaseMapping: Record<SubStep, ApplicationSubStep | undefined> = {
    [SubStep.NotInRemit]: ApplicationSubStep.NotInRemit,
    [SubStep.TellUsAboutYou]: ApplicationSubStep.AboutYou,
    [SubStep.YourCircumstances]: ApplicationSubStep.Circumstances,
    [SubStep.FineDetailsAboutYou]: ApplicationSubStep.ApplicantBirthdate,
    [SubStep.TwoYearsAddressHistory]: ApplicationSubStep.Addresses,
    [SubStep.JointApplicantInvite]: ApplicationSubStep.JointApplicant,
    [SubStep.YourEmployment]: ApplicationSubStep.Employment,
    [SubStep.YourEmployeeIncome]: ApplicationSubStep.Income,
    [SubStep.YourBusinessIncome]: ApplicationSubStep.Income,
    [SubStep.YourJointBorrowerIncome]: ApplicationSubStep.JointBorrowerIncome,
    [SubStep.YourHouseholdExpenses]: ApplicationSubStep.HouseholdExpenses,
    [SubStep.YourAssets]: ApplicationSubStep.Assets,
    [SubStep.YourLiabilities]: ApplicationSubStep.Liabilities,
    [SubStep.JointBorrowerAboutYou]: ApplicationSubStep.Invited,
    [SubStep.YourHelp]: ApplicationSubStep.TailorSolutions,
    [SubStep.Submitted]: ApplicationSubStep.Submitted,
    [SubStep.Completed]: ApplicationSubStep.Confirmation,
    // TODO[matej]: how does Accepted subster map?
    // ['Accepted']: ApplicationSubStep.Accepted,

    // UI only steps, not stored in the API
    [SubStep.BeforeWeGetStarted]: undefined,
    [SubStep.LetsStart]: undefined,
    [SubStep.Welcome]: undefined,
    [SubStep.Empty]: undefined,
    [SubStep.BuyingWorkflow]: undefined,
    [SubStep.CurrentHomeLoan]: undefined,
    [SubStep.NewHomeLoan]: undefined,
    [SubStep.ApplicationType]: undefined,
    [SubStep.YouAndYourHousehold]: undefined,
    [SubStep.FHBFederalGuarantee]: undefined,
    [SubStep.CreateYourAccount]: undefined,
}
export const applicationSubStepMapping: Record<SubStep | string, ApplicationSubStep | undefined> = applicationSubStepBaseMapping;
export const reverseApplicationSubStepMapping = reverseMapping(applicationSubStepBaseMapping);

export const propertyTypeMapping: Record<PropertyType, TargetPropertyType> = {
    [PropertyType.VacantLand]: TargetPropertyType.VacantLand,
    [PropertyType.HouseAndLand]: TargetPropertyType.HouseAndLand,
}
