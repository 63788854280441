/* eslint-disable camelcase */
import { z } from "zod";
import type { RootState } from "../../../../../store";
import { createSelector } from "@reduxjs/toolkit";
import type {
	AddressParts,
	ApplicationType,
} from "../../../../../services/apis/create-application.schema";
import type { OptionsType } from "../../../../../common/types";
import type { Place } from "../../../address-search/AddressSearch";
import { LivingSituation, PostSettlementPlan } from "@sucasa-finance/origination-trpc";

export type AddressHistoryFormSlice = {
	livingAddress?: Place;
	livingAddressParts?: AddressParts;
	currentLivingSituation?: OptionsType;
	postSettlementPlan?: string;
	postSettlementEstimate?: number;
	postSettlementMonthlyCosts?: number;
	livingStartDate: string;
	livingEndDate?: string;
	applicationType?: string;
};
export const AddressHistorySchema = z
	.object({
		addressHistory: z
			.object({

				currentLivingSituation: z
					.object(
						{
							id: z.number(),
							value: z.string(),
							label: z.string(),
						},
						{ required_error: "Please select living situation" }
					)
					.optional(),
				postSettlementPlan: z.string({ required_error: "Please select what is your plan for the property post settlement" }).optional(),
				postSettlementEstimate: z.number({ required_error: "Please enter an estimate for your property" }).optional(),
				postSettlementMonthlyCosts: z.number({ required_error: "Please enter monthly costs for your property" }).optional(),
				livingStartDate: z
					.string({
						required_error: "Please select living start date",
						invalid_type_error: "Please select living start date",
					})
					.min(1, {
						message: "Please select living start date",
					}),
				livingEndDate: z.string().optional(),
			})
			.array(),
	})
	.superRefine(({ addressHistory }, context) => {
		addressHistory.forEach(
			(
				{
					currentLivingSituation,
					postSettlementPlan,
					postSettlementEstimate,
					postSettlementMonthlyCosts,
					livingEndDate
				},
				index
			) => {
				if (
					index === 0 &&
					(!currentLivingSituation || currentLivingSituation.id === 0)
				) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please select living situation`,
						path: ["addressHistory", index, "currentLivingSituation"],
					});
				}
				const ownWithMortgage = currentLivingSituation?.value === LivingSituation.OwnWithMortgage;
				if (
					index === 0
					&& ownWithMortgage
					&& !postSettlementPlan
				) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Please select what is your plan for the property post settlement",
						path: ["addressHistory", index, "postSettlementPlan"],
					});
				}
				if (
					index === 0
					&& ownWithMortgage
					&& [PostSettlementPlan.Rent, PostSettlementPlan.Sell].includes(postSettlementPlan as PostSettlementPlan)
					&& postSettlementEstimate === undefined
				) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Please enter an estimate for your property",
						path: ["addressHistory", index, "postSettlementEstimate"],
					});
				}
				if (
					index === 0
					&& ownWithMortgage
					&& [PostSettlementPlan.Rent, PostSettlementPlan.SecondaryResidence].includes(postSettlementPlan as PostSettlementPlan)
					&& postSettlementMonthlyCosts === undefined
				) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Please enter monthly costs for your property",
						path: ["addressHistory", index, "postSettlementMonthlyCosts"],
					});
				}
				if (index > 0 && (!livingEndDate || livingEndDate === "")) {
					context.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Please select provide an end date`,
						path: ["addressHistory", index, "livingEndDate"],
					});
				}
			}
		);
	});
export type TwoYearsAddressHistoryFormType = z.infer<
	typeof AddressHistorySchema
>;
export type TwoYearsAddressHistoryFormSlice = {
	addressHistory?: Array<AddressHistoryFormSlice>;
	applicationType?: string;
};
export const SelectTwoYearsAddressHistoryForm = createSelector(
	(state: RootState) => state.form,
	(form): TwoYearsAddressHistoryFormSlice => ({
		addressHistory: form.addressHistory,
		applicationType: form.applicationType as ApplicationType,
	})
);
