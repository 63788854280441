/* eslint-disable camelcase */
import { z } from "zod";
import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../../../../../store";
import type { OptionsType } from "../../../../../common/types";

export const LoanLiabilitiesSchema = z
	.object({
		accountName: z.string(),
		loanAmount: z.number({
			invalid_type_error: "This field is required",
		}),
		interestRate: z.number({
			invalid_type_error: "This field is required",
		}),
		monthlyRepayments: z.number({
			invalid_type_error: "This field is required",
		}),
		remainingTermsInYears: z.object({
			id: z.number(),
			label: z.string(),
			value: z.string(),
		}),
		openToClosing: z.boolean().optional(),
		debtIsBeingConsolidated: z.boolean().optional(),
	})
	.array()
	.optional();
export const LiabilitiesSchema = z.object({
	noLiabilities: z.boolean().optional(),
	creditCardLiabilities: z
		.object({
			accountName: z.string(),
			currentBalance: z.number(),
			cardLimit: z.number(),
			openToClosing: z.boolean().optional(),
			debtIsBeingConsolidated: z.boolean().optional(),
		})
		.array()
		.optional(),
	personalLoansLiabilities: LoanLiabilitiesSchema,
	vehicleLoansLiabilities: LoanLiabilitiesSchema,
	hECsHELPLiabilities: z
		.object({
			accountName: z.string(),
			amount: z.number(),
			debtIsBeingConsolidated: z.boolean().optional(),
			openToClosing: z.boolean().optional(),
		})
		.array()
		.optional(),
});
export type CreditCardLiability = {
	accountName: string;
	currentBalance: number;
	cardLimit: number;
	openToClosing?: boolean;
	debtIsBeingConsolidated?: boolean;
};
export type LoanLiability = {
	accountName: string;
	loanAmount: number;
	interestRate: number;
	monthlyRepayments: number;
	remainingTermsInYears: OptionsType;
	openToClosing?: boolean;
	debtIsBeingConsolidated?: boolean;
};
export type HECsHELPLiability = {
	accountName: string;
	amount: number;
	debtIsBeingConsolidated?: boolean;
	openToClosing?: boolean;
};
export type LiabilitiesSchemaFormType = z.infer<typeof LiabilitiesSchema>;

export type LiabilitiesFormSlice = {
	noLiabilities?: boolean;
	creditCardLiabilities?: Array<CreditCardLiability>;
	personalLoansLiabilities?: Array<LoanLiability>;
	vehicleLoansLiabilities?: Array<LoanLiability>;
	hECsHELPLiabilities?: Array<HECsHELPLiability>;
};
export const selectLiabilities = createSelector(
	(state: RootState) => state.form,
	(form): LiabilitiesFormSlice => ({
		noLiabilities: form.noLiabilities,
		creditCardLiabilities: form.creditCardLiabilities ?? [],
		personalLoansLiabilities: form.personalLoansLiabilities ?? [],
		vehicleLoansLiabilities: form.vehicleLoansLiabilities ?? [],
		hECsHELPLiabilities: form.hECsHELPLiabilities ?? [],
	})
);
