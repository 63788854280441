// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../index";
import {
	Step,
	type Application,
} from "../../services/apis/create-application.schema";

type OriginationState = {
	useOrigination: boolean;
	applicationType?: string;
	jointApplicantHasBeenInvited?: boolean;
	applicationStep?: string;
	applicationSubStep?: string;
	purposeOfApplication?: string;
	status?: string;
	workingTowardsStatus?: string;
	propertyAddress?: string;
};

const initialState: OriginationState = {
	useOrigination: true,
	applicationType: undefined,
	jointApplicantHasBeenInvited: undefined,
	applicationStep: undefined,
	applicationSubStep: undefined,
	purposeOfApplication: undefined,
	status: undefined,
	workingTowardsStatus: undefined,
	propertyAddress: undefined,
};
export const originationSlice = createSlice({
	name: "origination",
	initialState,
	reducers: {
		setUseOrigination: (state, { payload }: PayloadAction<boolean>) => {
			state.useOrigination = payload;
		},
		setFromApplication: (state, { payload }: PayloadAction<Application | Record<string, never>>) => {
			state.applicationType = payload.applicationType;
			state.jointApplicantHasBeenInvited = payload.jointApplicantHasBeenInvited;
			state.applicationStep = payload.applicationStep;
			state.applicationSubStep = payload.applicationSubStep;
			state.purposeOfApplication = payload.purposeOfApplication;
			state.workingTowardsStatus = payload.workingTowardsStatus;
			state.status = payload.status;
			state.propertyAddress = payload.loanDetails.propertyAddress;
		},
		resetOrigination: () => {
			return initialState;
		},
	},
});

export const {
	setUseOrigination,
	setFromApplication,
	resetOrigination
} = originationSlice.actions;

export default originationSlice.reducer;

export const selectUseOrigination = (state: RootState) =>
	state.origination.useOrigination;

export const selectApplicationType = (state: RootState) =>
	state.origination?.applicationType;

export const selectIsJointApplication = createSelector(
	selectApplicationType,
	(applicationType) => applicationType === "Joint"
);

export const selectApplicationStep = (state: RootState) =>
	state.origination?.applicationStep;

export const selectIsApplicationSubmitted = createSelector(
	selectApplicationStep,
	 
	(applicationStep) => applicationStep === Step.Submitted
);

export const selectApplicationSubStep = (state: RootState) =>
	state.origination?.applicationSubStep;

export const selectPurposeOfApplication = (state: RootState) =>
	state.origination?.purposeOfApplication;

export const selectWorkingTowardsStatus = (state: RootState) =>
	state.origination?.workingTowardsStatus;

export const selectApplicationStatus = (state: RootState) =>
	state.origination?.status;

export const selectPropertyAddress = (state: RootState) =>
	state.origination?.propertyAddress;
