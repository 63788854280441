import { useCallback, useState } from "react";
import Button from "../../../../../ui/button/Button";
import type { AssetsFormSlice, VehicleAsset } from "../YourAssetsSchema";
import type { Control, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import GenericInputTeriary from "../../../../generic-input-teriary/GenericInputTeriary";
import DollarInput from "../../../../dollar-input/DollarInput";
import CheckboxInputSecondary from "../../../../checkbox-input-secondary/CheckboxInputSecondary";
import type { LiabilitiesFormSlice, LoanLiability } from "../../your-liabilities/YourLiabilitiesSchema";
import LoanLiabilitiesForm from "../../your-liabilities/loan-liabilities-form/LoanLiabilitiesForm";
import { useAppSelector } from "../../../../../../hooks/redux-hooks";
import { selectIsRefinance } from "../../../../../../store/slices/form-slice";

type VehicleAssetErrorType = Merge<
	FieldError,
	Array<Merge<FieldError, FieldErrorsImpl<VehicleAsset>> | undefined>
>;

type TSubFormName = "carAssets" | "boatAssets";
const emptyLoanLiability = {} as LoanLiability;
const emptyVehicleAsset = {} as VehicleAsset;

const VehicleAssetsDisplay = ({
	index,
	vehicleAsset,
	subFormName,
	control,
	errors,
	onChangeVehicleAssetHandler,
	removeVehicleAssetHandler,
	label,
	isRefinance,
}: {
	index: number;
	vehicleAsset: VehicleAsset;
	onChangeVehicleAssetHandler: (value: VehicleAsset) => void;
	removeVehicleAssetHandler: (value: number) => void;
	subFormName: TSubFormName;
	control: Control<AssetsFormSlice>;
	label: string;
	isRefinance: boolean;
	errors?: VehicleAssetErrorType;
}) => (
	<div className="w-full flex flex-col gap-4">
		<div className="flex flex-col md:flex-row gap-4 md:gap-8">
			<GenericInputTeriary
				type="text"
				value={vehicleAsset.name}
				error={errors && errors[index]?.name && errors[index]?.name?.message}
				label=""
				placeholder="Asset Name"
				onChangeHandler={(value) => {
					const updateVehicleAsset = {
						...vehicleAsset,
					};
					updateVehicleAsset.name = value.toString();
					onChangeVehicleAssetHandler(updateVehicleAsset);
				}}
			/>
			<GenericInputTeriary
				type="number"
				value={vehicleAsset.year}
				error={errors && errors[index]?.year && errors[index]?.year?.message}
				label=""
				placeholder="Year"
				onChangeHandler={(value) => {
					const updateVehicleAsset = {
						...vehicleAsset,
					};
					updateVehicleAsset.year = Number(value);
					onChangeVehicleAssetHandler(updateVehicleAsset);
				}}
			/>
		</div>
		<div className="flex flex-col md:flex-row gap-4 md:gap-8">
			<GenericInputTeriary
				type="text"
				value={vehicleAsset.make}
				error={errors && errors[index]?.make && errors[index]?.make?.message}
				label=""
				placeholder="Make"
				onChangeHandler={(value) => {
					const updateVehicleAsset = {
						...vehicleAsset,
					};
					updateVehicleAsset.make = value.toString();
					onChangeVehicleAssetHandler(updateVehicleAsset);
				}}
			/>
			<GenericInputTeriary
				type="text"
				value={vehicleAsset.model}
				error={errors && errors[index]?.model && errors[index]?.model?.message}
				label=""
				placeholder="Model"
				onChangeHandler={(value) => {
					const updateVehicleAsset = {
						...vehicleAsset,
					};
					updateVehicleAsset.model = value.toString();
					onChangeVehicleAssetHandler(updateVehicleAsset);
				}}
			/>
		</div>
		<DollarInput
			name={`${subFormName}.${index}.value`}
			control={control}
			type="numeric"
			value={vehicleAsset.value}
			error={errors && errors[index]?.value && errors[index]?.value?.message}
			label=""
			placeholder="Enter Value"
			iconPrefix={<i className="icon-dollar" />}
			onValueChange={(value) => {
				const updateVehicleAsset = {
					...vehicleAsset,
				};
				updateVehicleAsset.value = value || 0;
				onChangeVehicleAssetHandler(updateVehicleAsset);
			}}
		/>
		<CheckboxInputSecondary
			label="I have a loan for this vehicle"
			name="haveLiabilities"
			value="haveLiabilities"
			size="small"
			checked={vehicleAsset.haveLiabilities === true}
			onChange={(event) => {
				const updateVehicleAsset = {
					...vehicleAsset,
				};
				updateVehicleAsset.haveLiabilities = event.target.checked;
				updateVehicleAsset.liabilities = event.target.checked
					? [{...emptyLoanLiability, accountName: updateVehicleAsset.name}]
					: [];
				onChangeVehicleAssetHandler(updateVehicleAsset);
			}}
			error={errors && errors[index]?.haveLiabilities?.message}
		/>
		{vehicleAsset.haveLiabilities && (
			<LoanLiabilitiesForm
				accountNamePlaceHolder="Car Loan Name"
				loanAmountPlaceHolder="Loan Amount"
				initialLoanLiabilities={vehicleAsset.liabilities}
				subFormName={`${subFormName}.${index}.liabilities` as "personalLoansLiabilities"}
				control={control as Control<LiabilitiesFormSlice>}
				errors={errors && errors[index]?.liabilities}
				onChange={(liabilities) => {
					const updateVehicleAsset = {
						...vehicleAsset,
					};
					updateVehicleAsset.liabilities = liabilities;
					onChangeVehicleAssetHandler(updateVehicleAsset);
				}}
				showDebtConsolidation={isRefinance}
				showRemoveButton={true}
				showRemoveButtonTopRight={true}
				addButtonLabel="Add another loan"
			/>
		)}
		<Button
			text={`Remove ${label}`}
			variant="plain"
			type="button"
			iconSuffix={<i className="icon-remove" />}
			handleClick={() => {
				removeVehicleAssetHandler(index);
			}}
		/>
	</div>
);

const VehicleAssetsForm = ({
	initialVehicleAssets,
	label,
	onChange,
	subFormName,
	control,
	errors,
}: {
	initialVehicleAssets?: Array<VehicleAsset>;
	subFormName: TSubFormName;
	control: Control<AssetsFormSlice>;
	errors?: VehicleAssetErrorType;
	label: string;
	onChange: (vehicleAssets: Array<VehicleAsset>) => void;
}) => {
	const isRefinance = useAppSelector(selectIsRefinance);
	const [vehicleAssets, setVehicleAssets] = useState<Array<VehicleAsset>>(
		initialVehicleAssets ?? []
	);

	const setVehicleAssetsHandler = useCallback((updatedVehicleAssets: VehicleAsset[]) => {
		setVehicleAssets(updatedVehicleAssets);
		onChange(updatedVehicleAssets);
	}, [onChange])

	const handleVehicleAssetChange = (
		index: number,
		vehicleAsset: VehicleAsset
	) => {
		const updatedVehicleAssets = [...vehicleAssets];
		updatedVehicleAssets[index] = { ...vehicleAsset };
		setVehicleAssetsHandler(updatedVehicleAssets);
	};

	const removeVehicleAssetHandler = (index: number) => {
		const updatedVehicleAssets = [...vehicleAssets];
		updatedVehicleAssets.splice(index, 1);
		setVehicleAssetsHandler(updatedVehicleAssets);
	};

	return (
		<section className="flex flex-col gap-4">
			<div className="flex flex-col md:flex-row gap-4 md:justify-between md:items-end">
				<h1 className="text-primary text-[21.33px] font-normal mt-4">
					<i className="icon-car" />
					{" Car"}
				</h1>
				{vehicleAssets.length > 0 && (
					<Button
						text={`Add ${vehicleAssets.length > 0 ? "another " : ""}${label}`}
						variant="plain"
						type="button"
						iconSuffix={<i className="icon-plus" />}
						handleClick={() => {
							const updatedVehicleAssets = [
								...vehicleAssets,
								{ ...emptyVehicleAsset },
							];
							setVehicleAssetsHandler(updatedVehicleAssets);
						}}
					/>
				)}
			</div>
			{vehicleAssets.map((vehicleAsset, index) => (
				<VehicleAssetsDisplay
					key={index}
					index={index}
					subFormName={subFormName}
					control={control}
					errors={errors}
					vehicleAsset={vehicleAsset}
					onChangeVehicleAssetHandler={(value: VehicleAsset) => {
						handleVehicleAssetChange(index, value);
					}}
					removeVehicleAssetHandler={removeVehicleAssetHandler}
					label={label}
					isRefinance={isRefinance}
				/>
			))}
			{vehicleAssets.length === 0 && (
				<Button
					text={
						<span>
							<i className="icon-plus" /> {`Add ${vehicleAssets.length > 0 ? "another " : ""}${label}`}
						</span>
					}
					type="button"
					size="fit"
					variant="accent"
					textAlign="center"
					handleClick={() => {
						const updatedVehicleAssets = [
							...vehicleAssets,
							{ ...emptyVehicleAsset },
						];
						setVehicleAssetsHandler(updatedVehicleAssets);
					}}
				/>
			)}
		</section>
	);
};

export default VehicleAssetsForm;
