/* eslint-disable camelcase */

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import type { OptionsType } from "../../../../../common/types";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import {
	type LoanFeature,
	SubStep,
} from "../../../../../services/apis/create-application.schema";
import {
	setLoanBorrowAmount,
	setLoanGiftedAmount,
	setLoanPreference,
	setLoanPurchasePrice,
	setLoanSavings,
	setLoanTerm,
	setLoanTermInYears,
	setMeetsNeedsAndObjectives,
	setRequestedLoanBuyingFeatures,
	setRequestedLoanBuyingFeaturesOther,
	setLoanGiftedBy,
} from "../../../../../store/slices/form-slice";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import Button from "../../../../ui/button/Button";
import LoanPreferenceFC from "./loan-preference/LoanPreference";
import LoanTermFC from "./loan-term/LoanTerm";
import TotalSavings from "./total-savings/TotalSavings";
import { Fragment, useEffect, useState } from "react";
import DollarInput from "../../../dollar-input/DollarInput";
import FormLayout from "../../../../ui/form/Form";
import { useMarkFormDirty } from "../../../../../hooks/use-mark-form-dirty";
import Modal from "../../../modal/Modal";
import { GenuineSavingsModal } from "./GenuineSavingsModal";
import { useTracking } from "../../../../../hooks/use-tracking";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import GenericInput from "../../../generic-input/GenericInput";
import { Transition } from "@headlessui/react";


export type BuyingWorkflowLoanFormType = {
	loanPurchasePrice?: number;
	loanBorrowAmount?: number;
	loanSavings?: number;
	loanGiftedAmount?: number;
	loanGiftedBy?: string;
	loanPreference: OptionsType;
	loanTerm?: string;
	loanTermInYears?: 30 | 25 | 20;
	meetsNeedsAndObjectives?: string;
	requestedLoanBuyingFeatures?: Array<LoanFeature>;
	requestedLoanBuyingFeaturesOther?: string;
};

const validationSchema = z
	.object({
		loanPurchasePrice: z
			.number({ required_error: "Purchase price must be greater than 0" })
			.min(300_000, { message: "Purchase price must be at least $300,000" }),
		loanBorrowAmount: z
			.number({ required_error: "Borrow amount must be greater than 0" })
			.min(1, { message: "Borrow amount must be greater than 0" }),
		loanSavings: z
			.number({
				required_error: "This field is required, please enter 0 if none",
				invalid_type_error: "This field is required, please enter 0 if none",
			})
			.min(0, { message: "This field is required, please enter 0 if none" }),
		loanGiftedAmount: z
			.number({
				required_error: "This field is required, please enter 0 if none",
				invalid_type_error: "This field is required, please enter 0 if none",
			})
			.min(0, { message: "This field is required, please enter 0 if none" }),
		loanGiftedBy: z.string().optional(),
		loanPreference: z.object({
			id: z.number(),
			label: z.string(),
			value: z.string(),
		}),
		loanTerm: z.string(),
		loanTermInYears: z.union([z.number(), z.string().transform(Number)]),
		meetsNeedsAndObjectives: z.string(),
		requestedLoanBuyingFeatures: z.array(z.string()).optional(),
		requestedLoanBuyingFeaturesOther: z.string().optional(),
	})
	.refine(
		(data) => {
			return data.loanBorrowAmount <= data.loanPurchasePrice * 0.98;
		},
		{
			message:
				"Unfortunately, we can't lend more than 98% of the property value",
			path: ["loanBorrowAmount"],
		}
	)
	.refine(
		(data) => {
			return data.loanBorrowAmount >= data.loanPurchasePrice * 0.85;
		},
		{
			message: "Your loan amount must be at least 85% of the property value",
			path: ["loanBorrowAmount"],
		}
	).refine(
		(data) => {
			if (data.loanGiftedAmount && data.loanGiftedAmount > 0) {
				return !!data.loanGiftedBy;
			}
			return true;
		},
		{
			message: "You must specify who has gifted you funds",
			path: ["loanGiftedBy"],
		}
	);

const BuyingWorkflowLoan = () => {
	const dispatch = useAppDispatch();
	const formState = useAppSelector((state) => state.form);

	const [showModal, setShowModal] = useState(false);
	const [showSavingsModal, setShowSavingsModal] = useState(false);

	const BuyingWorkflowLoanDefaultValues: Partial<BuyingWorkflowLoanFormType> = {
		loanPurchasePrice: formState.loanPurchasePrice,
		loanBorrowAmount: formState.loanBorrowAmount,
		loanSavings: formState.loanSavings,
		loanGiftedAmount: formState.loanGiftedAmount,
		loanGiftedBy: formState.loanGiftedBy,
		loanPreference: formState.loanPreference,
		loanTerm: formState.loanTerm,
		loanTermInYears: formState.loanTermInYears,
		meetsNeedsAndObjectives: formState.meetsNeedsAndObjectives,
		requestedLoanBuyingFeatures: formState.requestedLoanBuyingFeatures,
		requestedLoanBuyingFeaturesOther:
			formState.requestedLoanBuyingFeaturesOther,
	};

	const {
		register,
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors, isDirty },
	} = useForm({
		resolver: zodResolver(validationSchema),
		defaultValues: { ...BuyingWorkflowLoanDefaultValues },
	});
	const { trackEvent } = useTracking();
	useMarkFormDirty(isDirty);
	useTrackPageViewOnMount({
		page: "Your Loan",
		subPage: "Purchase - Loan Details",
	});

	const showQuestion = watch();
	const loanPurchasePrice = watch("loanPurchasePrice");
	const requiredGenuineSavings = (loanPurchasePrice ?? 0) * 0.05;

	const showSubmitButton =
		showQuestion.meetsNeedsAndObjectives === "true" ||
		showQuestion.meetsNeedsAndObjectives === "false";

	const totalSavings =
		(Number(watch("loanSavings")) || 0) +
		(Number(watch("loanGiftedAmount")) || 0);

	const savedDataAndNavigate = (data: Partial<BuyingWorkflowLoanFormType>) => {
		dispatch(setLoanPurchasePrice(data.loanPurchasePrice!));
		dispatch(setLoanBorrowAmount(data.loanBorrowAmount!));
		dispatch(setLoanSavings(data.loanSavings!));
		dispatch(setLoanGiftedAmount(data.loanGiftedAmount!));
		dispatch(setLoanGiftedBy(data.loanGiftedBy!));
		dispatch(setLoanPreference(data.loanPreference!));
		dispatch(setLoanTerm(data.loanTerm!));
		dispatch(setLoanTermInYears(data.loanTermInYears!));
		dispatch(setMeetsNeedsAndObjectives(data.meetsNeedsAndObjectives!));
		dispatch(setRequestedLoanBuyingFeatures(data.requestedLoanBuyingFeatures!));
		dispatch(
			setRequestedLoanBuyingFeaturesOther(
				data.requestedLoanBuyingFeaturesOther ?? ""
			)
		);
		dispatch(
			navigateToStep({ step: "detailsStep", subStep: SubStep.ApplicationType })
		);
	};
	const saveData = (data: Partial<BuyingWorkflowLoanFormType>) => {
		const purchasePrice = data.loanPurchasePrice || 0;
		const savings = data.loanSavings || 0;
		const requiredSavings = purchasePrice * 0.05; // 5% of the purchase price

		if (purchasePrice > 0 && savings < requiredSavings) {
			setShowSavingsModal(true);
			trackEvent("Genuine Savings Modal Shown", {
				savings: savings,
				requiredSavings: requiredSavings,
			});
			return; // Prevent further execution to handle the modal interaction first
		}
		savedDataAndNavigate(data);
	};

	useEffect(() => {
		const subscription = watch((value, { name }) => {
			if (name === "loanTerm" && value.loanTerm === "yes") {
				setValue("loanTermInYears", 30);
			}
			if (
				name === "meetsNeedsAndObjectives" &&
				value.meetsNeedsAndObjectives === "false"
			) {
				setShowModal(true);
				trackEvent("Does not meet needs and objectives Clicked");
				trackEvent("Not In Remit Modal Shown", {
					reason: "Does not meet needs and objectives",
				});
			} else if (
				name === "meetsNeedsAndObjectives" &&
				value.meetsNeedsAndObjectives === "true"
			) {
				trackEvent("Does meet needs and objectives Clicked");
			}
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [watch, setValue, trackEvent]);

	// Clear gifted by when gifted amount is 0
	useEffect(() => {
		const subscription = watch((value, { name }) => {
			if (name === "loanGiftedAmount" && (!value.loanGiftedAmount || value.loanGiftedAmount === 0)) {
				setValue("loanGiftedBy", "", { shouldValidate: true });
				dispatch(setLoanGiftedBy(""));
			}
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [watch, setValue, dispatch]);

	const onChangeLoanBuyingFeatures = (checked: boolean, value: string) => {
		const label = value as LoanFeature;
		let updatedLoanBuyingFeatures: Array<LoanFeature> = [];
		const requestedLoanBuyingFeatures = watch("requestedLoanBuyingFeatures");
		if (requestedLoanBuyingFeatures) {
			updatedLoanBuyingFeatures = [...requestedLoanBuyingFeatures];
		}
		const indexToCheck = updatedLoanBuyingFeatures.indexOf(label);
		if (checked && indexToCheck === -1) {
			updatedLoanBuyingFeatures.push(label);
		} else {
			updatedLoanBuyingFeatures.splice(indexToCheck, 1);
		}
		setValue("requestedLoanBuyingFeatures", updatedLoanBuyingFeatures);
	};

	const maximumLendAmount = formState.loanPurchasePrice
		? (formState.loanPurchasePrice * 0.98).toLocaleString(undefined, {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
		: undefined;

	const minimumLendAmount = formState.loanPurchasePrice
		? (formState.loanPurchasePrice * 0.85).toLocaleString(undefined, {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
		: undefined;

	const borrowAmountHelperMessage = `Sucasa lends between 85-98% LVR. For this purchase price, that is between $${minimumLendAmount} - $${maximumLendAmount}.`;

	return (
		<>
			<FormLayout
				header={
					<h1 className="text-primary text-[37.9px] font-normal">Your Loan</h1>
				}
				content={
					<div className="flex flex-col gap-9">
						<DollarInput
							name="loanPurchasePrice"
							control={control}
							onValueChange={(value) => {
								if (value) {
									setValue("loanPurchasePrice", value);
									dispatch(setLoanPurchasePrice(value));
								} else {
									setValue("loanPurchasePrice", undefined);
									dispatch(setLoanPurchasePrice(0));
								}
							}}
							type="numeric"
							iconPrefix={<i className="icon-dollar" />}
							label="What is your maximum purchase price?"
							helperMessage={
								errors.loanPurchasePrice && errors.loanPurchasePrice.message
									? undefined
									: maximumLendAmount &&
										minimumLendAmount &&
										borrowAmountHelperMessage
							}
							placeholder="Estimated amount"
							value={formState?.loanPurchasePrice}
							error={
								errors.loanPurchasePrice && errors.loanPurchasePrice.message
							}
						/>
						<DollarInput
							name="loanBorrowAmount"
							control={control}
							onValueChange={(value) => {
								if (value) {
									setValue("loanBorrowAmount", value);
									dispatch(setLoanBorrowAmount(value));
								} else {
									setValue("loanBorrowAmount", undefined);
									dispatch(setLoanBorrowAmount(0));
								}
							}}
							type="numeric"
							iconPrefix={<i className="icon-dollar" />}
							label="How much do you want to borrow?"
							placeholder="Amount to borrow"
							value={formState?.loanBorrowAmount}
							error={errors.loanBorrowAmount && errors.loanBorrowAmount.message}
						/>
						<DollarInput
							name="loanSavings"
							control={control}
							onValueChange={(value) => {
								if (value === undefined) {
									setValue("loanSavings", undefined);
									dispatch(setLoanSavings(0));
								} else {
									setValue("loanSavings", value);
									dispatch(setLoanSavings(value));
								}
							}}
							type="numeric"
							iconPrefix={<i className="icon-dollar" />}
							label="How much have you saved for your home purchase?"
							placeholder="Savings"
							value={formState?.loanSavings}
							error={errors.loanSavings && errors.loanSavings.message}
						/>
						<DollarInput
							name="loanGiftedAmount"
							control={control}
							onValueChange={(value) => {
								if (value === undefined) {
									setValue("loanGiftedAmount", undefined);
									dispatch(setLoanGiftedAmount(0));
								} else {
									setValue("loanGiftedAmount", value);
									dispatch(setLoanGiftedAmount(value));
								}
							}}
							type="numeric"
							iconPrefix={<i className="icon-dollar" />}
							label="Have you been gifted any additional money?"
							placeholder="Gifted amount"
							value={formState?.loanGiftedAmount}
							error={errors.loanGiftedAmount && errors.loanGiftedAmount.message}
						/>
						<Transition
							show={(watch("loanGiftedAmount") ?? 0) > 0}
							as={Fragment}
							enter="transition ease-in-out duration-300 transform delay-150"
							enterFrom="opacity-0 -translate-y-4"
							enterTo="opacity-100 translate-y-0"
							leave="transition ease-in-out duration-200 transform"
							leaveFrom="opacity-100 translate-y-0"
							leaveTo="opacity-0 -translate-y-4"
						>
							<div className="origin-top mt-4">
								<GenericInput
									name="loanGiftedBy"
									register={register}
									type="text"
									label="What is your relationship with the person gifting these funds?"
									placeholder="Enter your relationship"
									error={errors.loanGiftedBy?.message}
								/>
							</div>
						</Transition>
						<TotalSavings
							label="Total savings and gifted funds"
							value={totalSavings}
						/>
						<LoanPreferenceFC register={register} showQuestion={showQuestion} />

						{watch("loanPreference.id") !== 0 && (
							<LoanTermFC
								register={register}
								showQuestion={showQuestion}
								onChangeLoanBuyingFeatures={onChangeLoanBuyingFeatures}
								selectedLoanBuyingFeatures={
									watch("requestedLoanBuyingFeatures") || []
								}
								errors={errors}
							/>
						)}
					</div>
				}
				footer={
					<div
						className="flex items-center justify-between gap-4"
						aria-labelledby="Actions wrapper"
					>
						<Button
							text="Back"
							type="button"
							variant="accent"
							iconPrefix={<i className="icon-arrow rotate-180" />}
							handleClick={() => {
								dispatch(
									navigateToStep({
										step: "homeStep",
										subStep: SubStep.LetsStart,
									})
								);
							}}
						/>
						{showSubmitButton && (
							<Button
								text="Next"
								variant="primary"
								iconSuffix={<i className="icon-arrow" />}
							/>
						)}
					</div>
				}
				onSubmit={handleSubmit(saveData)}
			/>
			<Modal
				isOpen={showModal}
				onClose={() => {
					setShowModal(false);
				}}
				title="Attention Needed"
				content={
					<div>
						<p className="text-center">
							Unfortunately, we cannot proceed as your current selections do not
							meet our lending criteria.
						</p>
						<p className="text-center">
							Please review your choices or contact our support for further
							assistance.
						</p>
					</div>
				}
				actions={[]}
			/>
			<Modal
				isOpen={showModal}
				actions={[]}
				title={
					<div className="flex flex-col gap-4 md:gap-8 items-center text-center text-[28.43px] font-medium leading-10 text-primary">
						<i className="icon-document-success text-3xl md:text-[80px]" />
						You've indicated the features our loans have don't meet your needs.
					</div>
				}
				onClose={() => {
					setShowModal(false);
				}}
				content={
					<div className="flex flex-col gap-4">
						<p className="text-sm text-primary text-center">
							We're working hard to bring you more features that meet your
							needs. Feel free to submit your application and we'll be in touch
							when this changes.
						</p>
						<p className="text-sm text-primary text-center">
							Otherwise, please select another option.
						</p>
					</div>
				}
			/>
			<GenuineSavingsModal
				purchasePrice={loanPurchasePrice ?? 0}
				onClose={() => {
					setShowSavingsModal(false);
				}}
				isOpen={showSavingsModal}
				requiredSavings={requiredGenuineSavings}
				onYesClicked={() => {
					savedDataAndNavigate(watch());
				}}
			/>
		</>
	);
};

export default BuyingWorkflowLoan;
