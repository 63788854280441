 
import { useEffect, useState } from "react";
import type { OptionsType, OtherIncome } from "../../../common/types";
import { FrequencyTypeOptions, IncomeTypeOptions } from "../../utils/BootList";
import Button from "../../ui/button/Button";
import SelectInputSecondary from "../select-input-secondary/SelectInputSecondary";
import type {
	Merge,
	FieldError,
	FieldErrorsImpl,
	Control,
} from "react-hook-form";
import DollarInput from "../dollar-input/DollarInput";
import type { YourEmploymentFormType } from "../steps/your-finances/your-employment/YourEmployment";

type OtherIncomeErrorType =
	| Merge<
			FieldError,
			Array<
				| Merge<
						FieldError,
						FieldErrorsImpl<{
							incomeType: {
								id: number;
								label: string;
								value: string;
							};
							frequency: {
								id: number;
								label: string;
								value: string;
							};
							estimatedAmount: number;
						}>
				  >
				| undefined
			>
	  >
	| undefined;

const OtherIncomeDisplay = ({
	label,
	index,
	incomeType,
	frequencyType,
	estimatedAmount,
	onChangeIncomeTypeHandler,
	onChangeFrequencyTypeHandler,
	onChangeEstimatedAmountHandler,
	removeOtherIncomeHandler,
	control,
	errors,
}: {
	label: string;
	index: number;
	incomeType: OptionsType;
	frequencyType: OptionsType;
	estimatedAmount: number | undefined;
	onChangeIncomeTypeHandler: (value: OptionsType) => void;
	onChangeFrequencyTypeHandler: (value: OptionsType) => void;
	onChangeEstimatedAmountHandler: (value?: number) => void;
	removeOtherIncomeHandler: (value: number) => void;
	control: Control<YourEmploymentFormType>;
	errors?: OtherIncomeErrorType;
}) => (
	<div className="w-full flex flex-col gap-2" aria-labelledby={label}>
		<header className="flex justify-between items-center">
			<h3 className="text-[21.33px] leading-8 font-medium">{label}</h3>
			{index > 0 && (
				<Button
					text="Remove"
					variant="link"
					type="button"
					iconPrefix={<i className="icon-delete" />}
					handleClick={() => {
						removeOtherIncomeHandler(index);
					}}
				/>
			)}
		</header>
		<SelectInputSecondary
			value={
				incomeType || {
					id: 0,
					value: "",
					label: "Select income type",
				}
			}
			label=""
			options={IncomeTypeOptions}
			onChangeHandler={onChangeIncomeTypeHandler}
			error={errors?.[index]?.incomeType?.message}
		/>
		<SelectInputSecondary
			value={
				frequencyType || {
					id: 0,
					value: "",
					label: "Select frequency",
				}
			}
			label=""
			options={FrequencyTypeOptions}
			onChangeHandler={onChangeFrequencyTypeHandler}
			error={errors?.[index]?.frequency?.message}
		/>
		<DollarInput
			name={`otherIncomes.${index}.estimatedAmount`}
			control={control}
			type="numeric"
			value={estimatedAmount}
			error={errors?.[index]?.estimatedAmount?.message}
			label=""
			iconPrefix={<i className="icon-dollar" />}
			placeholder="Estimated amount"
			onValueChange={onChangeEstimatedAmountHandler}
		/>
	</div>
);
const emptyOtherIncome: OtherIncome = {} as OtherIncome;
const OtherIncomesForm = ({
	initialOtherIncomes,
	onChange,
	control,
	errors,
}: {
	initialOtherIncomes?: Array<OtherIncome>;
	onChange: (otherIncomes: Array<OtherIncome>) => void;
	control: Control<YourEmploymentFormType>;
	errors?: OtherIncomeErrorType;
}) => {
	const [otherIncomes, setOtherIncomes] = useState<Array<OtherIncome>>(
		initialOtherIncomes ?? [
			{
				...emptyOtherIncome,
			},
		]
	);

	const handleOtherIncomeEstimatedAmountChange = (
		index: number,
		estimatedAmount?: number
	) => {
		const updatedOherIncomes = [...otherIncomes];
		const currentOtherIncome = { ...updatedOherIncomes[index] };
		if (currentOtherIncome) {
			currentOtherIncome.estimatedAmount = estimatedAmount;
			updatedOherIncomes[index] = currentOtherIncome as OtherIncome;
		}
		setOtherIncomes(updatedOherIncomes);
	};
	const handleOtherIncomeFrequencyChange = (
		index: number,
		frequency: OptionsType
	) => {
		const updatedOherIncomes = [...otherIncomes];
		const currentOtherIncome = { ...updatedOherIncomes[index] };
		if (currentOtherIncome) {
			currentOtherIncome.frequency = frequency;
			updatedOherIncomes[index] = currentOtherIncome as OtherIncome;
		}
		setOtherIncomes(updatedOherIncomes);
	};
	const handleOtherIncomeTypeChange = (
		index: number,
		incomeType: OptionsType
	) => {
		const updatedOherIncomes = [...otherIncomes];
		const currentOtherIncome = { ...updatedOherIncomes[index] };
		if (currentOtherIncome) {
			currentOtherIncome.incomeType = incomeType;
			updatedOherIncomes[index] = currentOtherIncome as OtherIncome;
		}
		setOtherIncomes(updatedOherIncomes);
	};
	useEffect(() => {
		onChange(otherIncomes);
	}, [otherIncomes, onChange]);
	const removeOtherIncomeHandler = (index: number) => {
		if (otherIncomes.length > 1) {
			const updatedOtherIncomes = [...otherIncomes];
			updatedOtherIncomes.splice(index, 1);
			setOtherIncomes(updatedOtherIncomes);
		}
	};
	return (
		<section className="flex flex-col gap-4">
			{otherIncomes.map((otherIncome, index) => (
				<OtherIncomeDisplay
					key={index}
					index={index}
					label={`Other Income ${index + 1}`}
					estimatedAmount={otherIncome.estimatedAmount}
					frequencyType={otherIncome.frequency}
					incomeType={otherIncome.incomeType}
					onChangeEstimatedAmountHandler={(value) => {
						handleOtherIncomeEstimatedAmountChange(index, value);
					}}
					onChangeFrequencyTypeHandler={(value) => {
						handleOtherIncomeFrequencyChange(index, value);
					}}
					onChangeIncomeTypeHandler={(value) => {
						handleOtherIncomeTypeChange(index, value);
					}}
					removeOtherIncomeHandler={removeOtherIncomeHandler}
					control={control}
					errors={errors}
				/>
			))}
			<Button
				text={
					<span>
						<i className="icon-plus" /> Add income
					</span>
				}
				type="button"
				variant="accent"
				textAlign="center"
				handleClick={() => {
					const updatedOtherIncomes = [
						...otherIncomes,
						{ ...emptyOtherIncome },
					];
					setOtherIncomes(updatedOtherIncomes);
				}}
			/>
		</section>
	);
};

export default OtherIncomesForm;
