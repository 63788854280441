import Button from "../../../../../../ui/button/Button";
import { DatePickerInput } from "../../../../../date-picker-input/DatePickerInput";

import type { FieldErrors } from "react-hook-form";
import type { ContractEmployeeFormSlice, ContractEmployeeHistoryFormType } from "../ContractEmployee.Schema";
import GenericInputTertiary from "../../../../../generic-input-teriary/GenericInputTeriary";
import RadioGroupSecondary from "../../../../../radio-group-secondary/RadioGroupSecondary";
import SelectInputSecondary from "../../../../../select-input-secondary/SelectInputSecondary";
import { MonthsOptions, YearsOptions } from "../../../../../../utils/BootList";

interface ContractEmployeeHistoryDisplayProps {
	index: number;
	employmentHistory: ContractEmployeeFormSlice;
	isContractMoreThanThreeMonths: boolean;
	onChangeHandler: (
		value: ContractEmployeeFormSlice,
		index: number
	) => void;
	removeHandler: (index: number) => void;
	errors?: FieldErrors<ContractEmployeeHistoryFormType>;
}

const ContractEmployeeHistoryDisplay = ({
  index,
  employmentHistory,
  isContractMoreThanThreeMonths,
  onChangeHandler,
  removeHandler,
  errors,
}: ContractEmployeeHistoryDisplayProps) => {
    console.log('errors',errors)
  return (
		<div className="flex flex-col gap-9">
			{index === 0 ? (
				// Current employment
				<>
					<GenericInputTertiary
						type="text"
						label="Employer Name"
						placeholder="Employer Name"
						value={employmentHistory.employerName}
						onChangeHandler={(value: string | number) => {
							const updatedHistory: ContractEmployeeFormSlice = {
								...employmentHistory,
								employerName: value as string,
							};
							onChangeHandler(updatedHistory, index);
						}}
						error={errors?.employmentHistory?.[index]?.employerName?.message}
					/>

					<GenericInputTertiary
						type="text"
						label="Job Title"
						placeholder="Job Title"
						value={employmentHistory.jobTitle}
						onChangeHandler={(value: string | number) => {
							const updatedHistory: ContractEmployeeFormSlice = {
								...employmentHistory,
								jobTitle: value as string,
							};
							onChangeHandler(updatedHistory, index);
						}}
						error={errors?.employmentHistory?.[index]?.jobTitle?.message}
					/>

					<DatePickerInput
						name="jobStartDate"
						onChange={(value) => {
							if (value) {
								const updatedHistory: ContractEmployeeFormSlice = {
									...employmentHistory,
									jobStartDate: value,
								};
								onChangeHandler(updatedHistory, index);
							}
						}}
						value={employmentHistory.jobStartDate}
						label="When did you start this role?"
						error={errors?.employmentHistory?.[index]?.jobStartDate?.message}
						maxDate={new Date()}
					/>

					<DatePickerInput
						name="jobEndDate"
						onChange={(value) => {
							const updatedHistory = {
								...employmentHistory,
								jobEndDate: value,
							};
							onChangeHandler(updatedHistory, index);
						}}
						value={employmentHistory.jobEndDate}
						label="When does your contract end?"
						error={errors?.employmentHistory?.[index]?.jobEndDate?.message}
						minDate={new Date()}
					/>

					{!isContractMoreThanThreeMonths && (
						<>
							<div className="flex flex-col">
								<h4 className="text-primary text-[21.33px] font-normal">
									How long have you worked in the same industry?
								</h4>
								<div className="flex gap-4 mt-2">
									<SelectInputSecondary
										value={
											YearsOptions.find(
												(option) =>
													option.value ===
													employmentHistory.industryExperience?.years?.toString()
											) || {
												id: 0,
												value: "",
												label: "Years",
											}
										}
										label=""
										options={YearsOptions}
										onChangeHandler={(value) => {
											const updatedHistory: ContractEmployeeFormSlice = {
												...employmentHistory,
												industryExperience: {
													years: Number(value.value),
													months: employmentHistory.industryExperience?.months ?? 0,
												},
											};
											onChangeHandler(updatedHistory, index);
										}}
										error={
											errors?.employmentHistory?.[index]?.industryExperience
												?.years?.message
										}
									/>
									<SelectInputSecondary
										value={
											MonthsOptions.find(
												(option) =>
													option.value ===
													employmentHistory.industryExperience?.months?.toString()
											) || {
												id: 0,
												value: "",
												label: "Months",
											}
										}
										label=""
										options={MonthsOptions}
										onChangeHandler={(value) => {
											const updatedHistory: ContractEmployeeFormSlice = {
												...employmentHistory,
												industryExperience: {
													months: Number(value.value),
													years: employmentHistory.industryExperience?.years ?? 0,
												},
											};
											onChangeHandler(updatedHistory, index);
										}}
										error={
											errors?.employmentHistory?.[index]?.industryExperience
												?.months?.message
										}
									/>
								</div>
							</div>
							<RadioGroupSecondary
								legend="Are you expecting an extension?"
								selectedValue={
									employmentHistory.expectingExtension ? "yes" : "no"
								}
								radioOptions={[
									{
										label: "Yes",
										value: "yes",
										checked: employmentHistory.expectingExtension,
									},
									{
										label: "No",
										value: "no",
										checked: !employmentHistory.expectingExtension,
									},
								]}
								onChange={(value) => {
									const updatedHistory = {
										...employmentHistory,
										expectingExtension: value === "yes",
									};
									onChangeHandler(updatedHistory, index);
								}}
								error={
									errors?.employmentHistory?.[index]?.expectingExtension
										?.message
								}
							/>

							{employmentHistory.expectingExtension && (
								<div className="flex flex-col">
									<h4 className="text-primary text-[21.33px] font-normal">
										How long is the extension?
									</h4>
									<div className="flex gap-4 mt-2">
										<SelectInputSecondary
											value={
												YearsOptions.find(
													(option) =>
														option.value ===
														employmentHistory.extensionDuration?.years?.toString()
												) || {
													id: 0,
													value: "",
													label: "Years",
												}
											}
											label=""
											options={YearsOptions}
											onChangeHandler={(value) => {
												const updatedHistory = {
													...employmentHistory,
													extensionDuration: {
														...employmentHistory.extensionDuration,
														years: Number(value.value),
													},
												} as ContractEmployeeFormSlice;
												onChangeHandler(updatedHistory, index);
											}}
											error={
												errors?.employmentHistory?.[index]?.extensionDuration
													?.message ??
												errors?.employmentHistory?.[index]?.extensionDuration
													?.years?.message
											}
										/>
										<SelectInputSecondary
											value={
												MonthsOptions.find(
													(option) =>
														option.value ===
														employmentHistory.extensionDuration?.months?.toString()
												) || {
													id: 0,
													value: "",
													label: "Months",
												}
											}
											label=""
											options={MonthsOptions}
											onChangeHandler={(value) => {
												const updatedHistory = {
													...employmentHistory,
													extensionDuration: {
														...employmentHistory.extensionDuration,
														months: Number(value.value),
													},
												} as ContractEmployeeFormSlice;
												onChangeHandler(updatedHistory, index);
											}}
											error={
												errors?.employmentHistory?.[index]?.extensionDuration
													?.message ??
												errors?.employmentHistory?.[index]?.extensionDuration
													?.months?.message
											}
										/>
									</div>
								</div>
							)}
						</>
					)}
				</>
			) : (
				// Previous employment
				<>
					<h1 className="text-primary text-[37.9px] font-normal">
						Previous Employer
					</h1>

					<GenericInputTertiary
						type="text"
						label="Employer Name"
						placeholder="Employer Name"
						value={employmentHistory.employerName}
						onChangeHandler={(value) => {
							const updatedHistory = {
								...employmentHistory,
								employerName: value as string,
							};
							onChangeHandler(updatedHistory, index);
						}}
						error={errors?.employmentHistory?.[index]?.employerName?.message}
					/>

					<GenericInputTertiary
						type="text"
						label="Job Title"
						placeholder="Job Title"
						value={employmentHistory.jobTitle}
						onChangeHandler={(value) => {
							const updatedHistory = { ...employmentHistory, jobTitle: value as string };
							onChangeHandler(updatedHistory, index);
						}}
						error={errors?.employmentHistory?.[index]?.jobTitle?.message}
					/>

					<DatePickerInput
						name="jobStartDate"
						onChange={(value) => {
							const updatedHistory = {
								...employmentHistory,
								jobStartDate: value,
							} as ContractEmployeeFormSlice;
							onChangeHandler(updatedHistory, index);
						}}
						value={employmentHistory.jobStartDate}
						label="When did you start this role?"
						error={errors?.employmentHistory?.[index]?.jobStartDate?.message}
						maxDate={new Date()}
					/>

					<DatePickerInput
						name="jobEndDate"
						onChange={(value) => {
							const updatedHistory = {
								...employmentHistory,
								jobEndDate: value,
							};
							console.log("updatedHistory", updatedHistory);
							onChangeHandler(updatedHistory, index);
						}}
						value={employmentHistory.jobEndDate}
						label="When did you finish?"
						error={errors?.employmentHistory?.[index]?.jobEndDate?.message}
						maxDate={new Date()}
					/>
				</>
			)}

			{index !== 0 && (
				<div className="flex flex-col md:flex-row gap-4">
					<Button
						text="Remove"
						variant="plain"
						type="button"
						iconSuffix={<i className="icon-delete" />}
						handleClick={() => {
							removeHandler(index);
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default ContractEmployeeHistoryDisplay;