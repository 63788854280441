 
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../../hooks/redux-hooks";
import { navigateToStep } from "../../../../../../store/slices/stepper-slice";
import Button from "../../../../../ui/button/Button";
import {
	setBusinessABN,
	setBusinessIncorporationDate,
	setBusinessName,
	setBusinessStartDate,
	setCurrentEmploymentType,
	setPreviousFinanceActiveForm,
} from "../../../../../../store/slices/form-slice";
import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import GenericInput from "../../../../generic-input/GenericInput";
import { SelfEmployeeSchema } from "./SelfEmployee.Schema";
import { navigateToJointStep } from "../../../../../../store/slices/joint-stepper-slice";
import { DatePickerInput } from "../../../../date-picker-input/DatePickerInput";
import { SubStep } from "../../../../../../services/apis/create-application.schema";
import InputWithMask from "../../../../input-with-mask/InputWithMask";
import type { OptionsType } from "../../../../../../common/types";
import FormLayout from "../../../../../ui/form/Form";
import { addDynamicHint, removeDynamicHint, setDynamicHints } from "../../../../../../store/slices/ui-slice";
import { useEffect } from "react";

export type SelfEmploymentFormType = {
	businessName: string;
	businessABN: number | undefined;
	businessIncorporationDate: string;
	businessStartDate: string;
};

const SelfEmployment = ({
	isJoint,
	employemntOption,
}: {
	isJoint?: boolean;
	employemntOption: OptionsType;
}) => {
	const dispatch = useAppDispatch();
	const formState = useAppSelector((state) => state.form);
	const SelfEmploymentDefaultValues: Partial<SelfEmploymentFormType> = {
		businessName: formState.businessName,
		businessABN: formState.businessABN,
		businessIncorporationDate: formState.businessIncorporationDate,
		businessStartDate: formState.businessStartDate,
	};
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(SelfEmployeeSchema),
		defaultValues: { ...SelfEmploymentDefaultValues },
	});

	useEffect(() => {
		dispatch(setDynamicHints([]));
		dispatch(addDynamicHint("EmploymentHistoryHint"));
		return () => {
			dispatch(removeDynamicHint("EmploymentHistoryHint"));
		};
	}, [dispatch]);

	const saveData = (data: Partial<SelfEmploymentFormType>) => {
		dispatch(setCurrentEmploymentType(employemntOption));
		dispatch(setPreviousFinanceActiveForm(SubStep.YourEmployment));
		dispatch(setBusinessName(data.businessName!));
		dispatch(setBusinessABN(data.businessABN));
		dispatch(setBusinessIncorporationDate(data.businessIncorporationDate!));
		dispatch(setBusinessStartDate(data.businessStartDate!));
		if (isJoint) {
			dispatch(
				navigateToJointStep({
					step: "financeStep",
					subStep: SubStep.YourBusinessIncome,
				})
			);
		} else {
			dispatch(
				navigateToStep({
					step: "financeStep",
					subStep: SubStep.YourBusinessIncome,
				})
			);
		}
	};
	const header = <></>;
	const content = (
		<div className="flex flex-col gap-9">
			<GenericInput
				name="businessName"
				register={register}
				type="text"
				label="What's the name of your business?"
				placeholder="Business name"
				error={errors.businessName && errors.businessName.message}
			/>
			<InputWithMask
				name="businessABN"
				register={register}
				type="number" // Use text type for masked input
				label="ABN"
				placeholder="XXX XXX XXX XX"
				mask="___ ___ ___ __" // ABN format mask
				error={errors.businessABN && errors.businessABN.message}
			/>
			<DatePickerInput
				name="businessIncorporationDate"
				onChange={(value) => {
					setValue("businessIncorporationDate", value);
				}}
				value={watch("businessIncorporationDate")}
				label="When was your business incorporated?"
				error={errors.businessIncorporationDate?.message}
				maxDate={new Date()}
			/>
			<DatePickerInput
				name="businessStartDate"
				onChange={(value) => {
					setValue("businessStartDate", value);
				}}
				value={watch("businessStartDate")}
				label="When did you start working in the business?"
				error={errors.businessStartDate?.message}
				maxDate={new Date()}
			/>
		</div>
	);
	const footer = (
		<div
			className="flex items-center justify-end gap-4"
			aria-labelledby="Actions wrapper"
		>
			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				type="submit"
			/>
		</div>
	);
	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default SelfEmployment;
