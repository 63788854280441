import { z } from "zod";
import type { OptionsType } from "../../../../../common/types";

export const JointApplicantInviteSchema = z.object({
	firstName: z.string().min(1, "First name is required"),
	middleName: z.string().optional(),
	surname: z.string().min(1, "Surname is required"),
	title: z.object({
		id: z.number().min(1, "Please select an title"),
		label: z.string(),
		value: z.string(),
	}),
	noMiddleName: z.boolean(),
	email: z.string().email({ message: "Invalid email address" }),
	mobile: z
		.string()
		.regex(/^\+61\d{9}$/, { message: "A valid mobile number is required" }),
});

export type JointApplicantInviteFormType = z.infer<
	typeof JointApplicantInviteSchema
>;
export type JointApplicantInviteFormSlice = {
	title: OptionsType;
	firstName: string;
	surname: string;
	middleName?: string;
	email: string;
	mobile?: string;
	noMiddleName?: boolean;
};
