import { useEffect, useMemo } from "react";
import { skipToken } from "@tanstack/react-query";
import { origination } from "../../services/origination";
import { setHomeLoanSpecialist } from "../../store/slices/home-loan-specialist-slice";
import { setApplicationId, setApplicationType, setDependantAges, setJointBorrowerFirstName, setLoanTermInYears } from "../../store/slices/form-slice";
import { setIsJointApplicant, setNotInRemit } from "../../store/slices/auth-slice";
import { ApplicationType, Step, SubStep } from "../../services/apis/create-application.schema";
import { navigateToStep } from "../../store/slices/stepper-slice";
import { navigateToJointStep } from "../../store/slices/joint-stepper-slice";
import { mapApplicationStepToStepper } from "../use-auth-success";
import { useAppDispatch } from "../redux-hooks";
import { mapGetApplicationByIdResponse } from "../../services/apis/origination/mapping/application";
import { setFromApplication, setUseOrigination } from "../../store/slices/origination-slice";
import type { GetApplicationByIdResponse } from "../../services/apis/origination/types";
import { useLocationApplicationId } from "./use-location-application-id";

const useGetApplicationByIdEffect = (data?: GetApplicationByIdResponse) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!data) {
			return;
		}

		// for origination applications, use origination service
		dispatch(setUseOrigination(true));
		const response = mapGetApplicationByIdResponse(data);
		// set the applicationId to differ from middleware
		if (response?.application?.applicationId) {
			dispatch(setApplicationId(response.application.applicationId));
		}
		if (response?.notInRemit) {
			dispatch(setNotInRemit(response.notInRemit));
		}
		if (response?.homeLoanSpecialist) {
			dispatch(setHomeLoanSpecialist(response.homeLoanSpecialist));
		}
		dispatch(setDependantAges(response.application.dependents ?? []));
		
		if (response?.application) {
			dispatch(setFromApplication(response.application));
		}
		if (response?.application?.loanDetails?.loanTermInYears) {
			dispatch(setLoanTermInYears(response.application.loanDetails.loanTermInYears as 30 | 25 | 20));
		}
		if (response?.application?.applicationType) {
			dispatch(setApplicationType(response.application.applicationType));
		}
		if (response?.jointBorrowerFirstName) {
			dispatch(setJointBorrowerFirstName(response.jointBorrowerFirstName));
		}
		dispatch(setIsJointApplicant(!response?.isPrimaryApplicant));

		const isPrimaryApplicant = response?.isPrimaryApplicant;
		const applicationStep = response?.application?.applicationStep;
		const applicationSubStep = response?.application?.applicationSubStep as SubStep;
		const applicationType = response?.application?.applicationType as ApplicationType;
		const jointApplicantHasBeenInvited = response?.application?.jointApplicantHasBeenInvited;

		if (response?.notInRemit) {
			dispatch(
				navigateToStep({
					step: mapApplicationStepToStepper(Step.YourDetails, true),
					subStep: SubStep.NotInRemit,
				})
			);
		} else if (isPrimaryApplicant && response?.application?.applicationStep && applicationSubStep) {
			if (
				applicationType === ApplicationType.Joint &&
				!jointApplicantHasBeenInvited &&
				applicationSubStep === SubStep.YourEmployment
			) {
				dispatch(
					navigateToStep({
						step: mapApplicationStepToStepper(Step.YourDetails, true),
						subStep: SubStep.JointApplicantInvite,
					})
				);
			} else {
				dispatch(
					navigateToStep({
						step: mapApplicationStepToStepper(applicationStep, true),
						subStep: applicationSubStep as SubStep,
					})
				);
			}
		} else if (
			!isPrimaryApplicant &&
			applicationStep &&
			applicationSubStep
		) {
			dispatch(
				navigateToJointStep({
					step: mapApplicationStepToStepper(applicationStep, true),
					subStep: applicationSubStep as SubStep,
				})
			);
		} else if (isPrimaryApplicant) {
			dispatch(
				navigateToStep({
					step: "detailsStep",
					subStep: SubStep.FineDetailsAboutYou,
				})
			);
		} else {
			dispatch(
				navigateToJointStep({
					step: "detailsStep",
					subStep: SubStep.JointBorrowerAboutYou,
				})
			);
		}
	}, [data, dispatch]);
}


export const useGetApplicationByIdSuspense = () => {
	const applicationId = useLocationApplicationId();
	if (!applicationId) {
		throw new Error("Application ID is required");
	}
	const [application] = origination.application.getApplicationById.useSuspenseQuery({applicationId});

	useGetApplicationByIdEffect(application);

	return {
		applicationId,
		isLoading: false
	}
}

export const useGetApplicationById = () => {
	const applicationId = useLocationApplicationId();
	const { isPending, data } = origination.application.getApplicationById.useQuery(applicationId ? {applicationId} : skipToken);

	const isLoading = useMemo(() => isPending && Boolean(applicationId),
		[isPending, applicationId]);

	useGetApplicationByIdEffect(data);

	return {
		applicationId,
		isLoading
	}
};
