import type { FunctionComponent } from "../../../../../common/types";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../hooks/redux-hooks";
import Button from "../../../../ui/button/Button";
import {
	setBoatAssets,
	setCarAssets,
	setCashOrSavingAssets,
	setInvestmentPropertyAssets,
	setOtherAssets,
	setSharesEquitiesInvestmentsAssets,
	setSuperannuationAssets,
} from "../../../../../store/slices/form-slice";
import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import {
	type AssetsFormSlice,
	AssetsSchema,
	selectAssets,
	type AssetsSchemaFormType,
} from "./YourAssetsSchema";
import AssetsForm from "./assets-form/AssetsForm";
import ShareEquitiesInvestmentsForm from "./share-equities-investment-form/ShareEquitiesInvestmentForm";
import VehicleAssetsForm from "./vehicle-assets-form/VehicleAssetsForm";
import InvestmentPropertyAssetsForm from "./investment-property-form/InvestmentPropertyForm";
import { navigateToJointStep } from "../../../../../store/slices/joint-stepper-slice";
import { navigateToStep } from "../../../../../store/slices/stepper-slice";
import {
	selectUpdateAssetsPayload,
	setShares,
	setInvestments, setVehicleAssets,
} from "../../../../../store/selectors/update-assets-payload";
import { setFormLoading } from "../../../../../store/slices/loader-slice"; // Added for form loading
import { SubStep } from "../../../../../services/apis/create-application.schema";
import { useEffect } from "react"; // Added for useEffect
import { setToast } from "../../../../../store/slices/toast-slice";
import FormLayout from "../../../../ui/form/Form";
import useTrackPageViewOnMount from "../../../../../hooks/use-track-on-mount";
import { useUpdateAssets } from "../../../../../services/apis/origination/assets";
import { captureException } from "../../../../../services/sentry";

const YourAssets = ({ isJoint }: { isJoint?: boolean }): FunctionComponent => {
	const dispatch = useAppDispatch();
	const formState = useAppSelector(selectAssets);
	const [updateAssets, { isLoading }] = useUpdateAssets();
	const updateAssetsPayload = useAppSelector(selectUpdateAssetsPayload);
	const {
		control,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm({
		resolver: zodResolver(AssetsSchema),
		defaultValues: formState,
	});

	useTrackPageViewOnMount({
		page: "Your Finances",
		subPage: "Your Assets",
	});

	// Added useEffect for form loading
	useEffect(() => {
		if (isLoading) {
			dispatch(setFormLoading(true));
		} else {
			dispatch(setFormLoading(false));
		}

		return () => {
			dispatch(setFormLoading(false));
		};
	}, [isLoading, dispatch]);

	const saveData = async (rawData: AssetsFormSlice) => {
		// Assuming validation ensures all values are defined, we can assert the type here - this is a hack because the zod inference with the form schema is not working
		const data: AssetsSchemaFormType = rawData as AssetsSchemaFormType;
		dispatch(setCashOrSavingAssets(data.cashOrSavingAssets!));
		dispatch(setSuperannuationAssets(data.superannuationAssets!));
		dispatch(
			setSharesEquitiesInvestmentsAssets(data.sharesEquitiesInvestmentsAssets!)
		);
		dispatch(setCarAssets(data.carAssets!));
		dispatch(setBoatAssets(data.boatAssets!));
		dispatch(setInvestmentPropertyAssets(data.investmentPropertyAssets!));
		dispatch(setOtherAssets(data.otherAssets!));
		try {
			await updateAssets({
				...updateAssetsPayload,
				car: setVehicleAssets(data.carAssets),
				otherAssets: data.otherAssets!,
				investmentProperty: setInvestments(data.investmentPropertyAssets!),
				shares: setShares(data.sharesEquitiesInvestmentsAssets!),
				superannuation: data.superannuationAssets!,
				savings: data.cashOrSavingAssets!,
				boat: [],
			}).unwrap();

			if (isJoint) {
				dispatch(
					navigateToJointStep({
						step: "financeStep",
						subStep: SubStep.YourLiabilities,
					})
				);
			} else {
				dispatch(
					navigateToStep({
						step: "financeStep",
						subStep: SubStep.YourLiabilities,
					})
				);
			}
		} catch (error) {
			captureException(new Error("Error saving assets"), {data: {error}});
			dispatch(
				setToast({
					open: true,
					type: "error",
					title: "Error",
					description: "An error occurred while saving your assets.",
				})
			);
		}
	};
	const header = (
		<h1
			className="text-primary text-[37.9px] font-normal"
			aria-labelledby="Your Assets"
		>
			Your Assets
		</h1>
	);
	const content = (
		<>
			<div className="flex flex-col gap-4">
				<h1 className="text-primary text-[21.33px] font-normal mt-4">
					<i className="icon-graph-up" />
					{" Superannuation"}
				</h1>
				<AssetsForm
					addButtonLabel="superannuation"
					accountNamePlaceholder="Financial Instituion"
					initialAssets={formState.superannuationAssets}
					subFormName="superannuationAssets"
					control={control}
					errors={errors.superannuationAssets}
					onChange={(assets) => {
						setValue("superannuationAssets", assets);
					}}
				/>
			</div>
			<div className="flex flex-col gap-4">
				<h1 className="text-primary text-[21.33px] font-normal mt-4">
					<i className="icon-dollar" />
					{" Cash or Savings"}
				</h1>
				<AssetsForm
					addButtonLabel="cash asset"
					accountNamePlaceholder="Financial Instituion"
					initialAssets={formState.cashOrSavingAssets}
					subFormName="cashOrSavingAssets"
					control={control}
					errors={errors.cashOrSavingAssets}
					onChange={(assets) => {
						setValue("cashOrSavingAssets", assets);
					}}
				/>
			</div>
			<div className="flex flex-col gap-4">
				<h1 className="text-primary text-[21.33px] font-normal mt-4">
					<i className="icon-bar-chart" />
					{" Shares/Equities/Investments"}
				</h1>
				<ShareEquitiesInvestmentsForm
					label="share/equity/investment"
					initialShareEquitiesInvestments={
						formState.sharesEquitiesInvestmentsAssets
					}
					control={control}
					errors={errors.sharesEquitiesInvestmentsAssets}
					onChange={(sharesEquitiesInvestmentsAssets) => {
						setValue(
							"sharesEquitiesInvestmentsAssets",
							sharesEquitiesInvestmentsAssets
						);
					}}
				/>
			</div>
			<VehicleAssetsForm
				label="car"
				initialVehicleAssets={formState.carAssets}
				subFormName={"carAssets"}
				control={control}
				errors={errors.carAssets}
				onChange={(vehicleAssets) => {
					setValue("carAssets", vehicleAssets);
				}}
			/>
			<InvestmentPropertyAssetsForm
				initialInvestmentPropertyAssets={formState.investmentPropertyAssets}
				control={control}
				errors={errors.investmentPropertyAssets}
				onChange={(investmentPropertyAssets) => {
					setValue("investmentPropertyAssets", investmentPropertyAssets);
				}}
			/>
			<div className="flex flex-col gap-4">
				<h1 className="text-primary text-[21.33px] font-normal mt-4">
					<i className="icon-other" />
					{" Other Assets"}
				</h1>
				<AssetsForm
					addButtonLabel="asset"
					accountNamePlaceholder="Asset Name"
					initialAssets={formState.otherAssets}
					subFormName="otherAssets"
					control={control}
					errors={errors.otherAssets}
					onChange={(assets) => {
						setValue("otherAssets", assets);
					}}
				/>
			</div>
		</>
	);
	const footer = (
		<div className="flex items-center justify-end gap-4">
			<Button
				text="Next"
				variant="primary"
				iconSuffix={<i className="icon-arrow" />}
				isDisabled={isLoading}
			/>
		</div>
	);
	return (
		<FormLayout
			header={header}
			content={content}
			footer={footer}
			onSubmit={handleSubmit(saveData)}
		/>
	);
};

export default YourAssets;
